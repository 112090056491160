import { forwardRef, useCallback } from 'react';
import { ActivateEmployeeFormProps } from './model/activate-employee-form.types';
import { useActivateUserMutation, useGetUserRolesMappingQuery, UserActivateRequest } from '@/shared/state/api/swagger';
import { isFetchBaseQueryError } from '@/shared/state/api';
import { Stack, useToast } from '@chakra-ui-kraud/react';
import {
	ACTIVATE_USER_CACHE_KEY,
	CustomInput,
	CustomSelect,
	selectRolesOptionsForAdminCreate,
	showErrorToast,
	showSuccessToast,
} from '@/shared';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { activateEmployeeSchema } from '@/features/employee-form/index';
import { UserFormPassword, UserFormRequirements } from '@/features/user-forms';
import styles from './activate-employee.module.scss';

export const ActivateEmployeeForm = forwardRef<HTMLFormElement, ActivateEmployeeFormProps>(
	({ user, onResult }, ref) => {
		const { id } = user;
		const [activate] = useActivateUserMutation({ fixedCacheKey: ACTIVATE_USER_CACHE_KEY });
		const toast = useToast();

		const handleActivate = useCallback(
			(data: Omit<UserActivateRequest, 'id'>) => {
				activate({ userActivateRequest: { ...data, user_id: id } })
					.unwrap()
					.then(() => {
						showSuccessToast(toast, { description: 'Cотрудник активирован' });
						onResult();
					})
					.catch((err) => {
						if (isFetchBaseQueryError(err)) {
							if (err.status === 466) {
								showErrorToast(toast, {
									title: 'Почта занята',
									description: 'Придумайте другую почту для этого сотрудника',
								});
								return;
							}
						}
						showErrorToast(toast, {
							description: 'При создании пользователя произошла ошибка',
						});
					});
			},
			[id, onResult],
		);

		const formProps = useForm<
			UserActivateRequest & {
				confirm_password: string;
			}
		>({ resolver: yupResolver(activateEmployeeSchema) });

		const {
			control,
			handleSubmit,
			setValue,
			formState: { errors },
		} = formProps;

		const { rolesOptions, isFetching: isRolesFetching } = useGetUserRolesMappingQuery(undefined, {
			selectFromResult: (result) => ({
				...result,
				rolesOptions: selectRolesOptionsForAdminCreate(result.data),
			}),
		});

		const { password } = useWatch({ control });

		return (
			<FormProvider {...formProps}>
				<form onSubmit={handleSubmit(handleActivate)} ref={ref}>
					<Stack gap={3} className={styles['form']}>
						<Controller
							control={control}
							name="role"
							render={({ field, fieldState: { error } }) => (
								<CustomSelect
									showTooltip={!!errors.role?.message}
									tooltipContent={errors.role?.message}
									label="Роль"
									// @ts-expect-error в тип необходимо добавить undefined
									onClear={() => setValue('role', undefined)}
									options={rolesOptions}
									isLoading={isRolesFetching}
									isInvalid={!!error}
									showSearch={false}
									{...field}
								/>
							)}
						/>
						<Controller
							control={control}
							name="email"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									type="email"
									size="md"
									{...field}
									isInvalid={!!error}
									label="Почта"
									showTooltip={!!errors.email?.message}
									tooltipContent={errors.email?.message}
								/>
							)}
						/>
						<UserFormPassword selectedUser={user} errors={errors} />{' '}
						<UserFormRequirements password={password} />
					</Stack>
				</form>
			</FormProvider>
		);
	},
);
