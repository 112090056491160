import { CommentProps } from './comment.types';
import { FC } from 'react';
import { Flex, Stack } from '@chakra-ui-kraud/react';

export const Comment: FC<CommentProps> = ({ text, datetime, author, ...divProps }) => {
	return (
		<Stack p={4} overflowWrap="break-word" {...divProps}>
			{text}
			<Flex gap={3}>
				{author}
				{datetime}
			</Flex>
		</Stack>
	);
};
