import { FC, useCallback, useMemo, useState } from 'react';

import { ColumnsType, FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { NumCatalogTableProps } from './num-catalog-table.types';
import { INUM } from '@/shared/core';
import { Actions } from '@/shared/components/actions';
import { useDeleteNumMutation } from '@/shared/state/api';

import { CommonTable, DeleteModal, Loader, showErrorToast, showSuccessToast } from '@/shared';
import { SortingDirection, useToast } from '@chakra-ui-kraud/react';
import { CellTableParagraph } from '@/shared/components/cell-table-paragraph/cell-table-paragraph';
import dayjs from 'dayjs';
import styles from './num-catalog.module.scss';
import { NumCatalogSortingFields } from '@/shared/state/api/swagger';

export const NumCatalogTable: FC<NumCatalogTableProps> = ({
	data,
	onPageChange,
	page,
	pageSize,
	total,
	setQueryParams,
	isLoading,
	handleClick,
}) => {
	const toast = useToast();

	const [numDeleteModalisOpen, setNumDeleteModalisOpen] = useState(false);
	const [selectedNum, setSelectedNum] = useState<INUM>({});

	const [deleteNum] = useDeleteNumMutation();

	const handleDeleteNum = useCallback((id: number, num: number) => {
		deleteNum(id)
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `НУМ ${num} удален` });
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении НУМ произошла ошибка' });
			});
		setNumDeleteModalisOpen(false);
	}, []);

	const NUM_CATALOG_COLUMNS: ColumnsType<INUM> = useMemo(() => {
		const onHeaderCell: ColumnsType<INUM>[number]['onHeaderCell'] = (column) => ({
			style: {
				width: '100%',
				whiteSpace: 'normal',
			},
		});

		return [
			{
				key: 'num',
				title: 'НУM',
				align: 'left',
				dataIndex: 'num',
				className: styles['num'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => <CellTableParagraph value={value} />,
				onHeaderCell,
				fixed: 'left',
			},
			{
				key: 'symbol',
				title: 'Материал',
				dataIndex: 'symbol',
				className: styles['symbol'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'gost',
				title: 'Стандарт материала',
				dataIndex: 'gost',
				className: styles['gost-and-sortament'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'sortament',
				title: 'Сортамент',
				dataIndex: 'sortament',
				className: styles['gost-and-sortament'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'gost_na_sortament',
				title: 'Стандарт сортамента',
				dataIndex: 'gost_na_sortament',
				className: styles['gost-and-sortament'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'certificate',
				title: 'Сертификат',
				dataIndex: 'certificate',
				className: styles['certificate'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'batch_number',
				title: 'Номер партии',
				dataIndex: 'batch_number',
				className: styles['batch_number'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'number_of_melt',
				title: 'Номер плавки',
				dataIndex: 'number_of_melt',
				className: styles['number_of_melt'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'divisions',
				title: 'Мех. свойства',
				dataIndex: 'divisions',
				className: styles['divisions'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'n_b',
				title: 'НВ',
				align: 'left',
				dataIndex: 'n_b',
				className: styles['n_b'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'o_v',
				title: 'σв',
				align: 'left',
				dataIndex: 'o_v',
				className: styles['n-sigma'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: number) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'date_of_manufacture',
				title: 'Дата изготовления',
				align: 'left',
				dataIndex: 'nv',
				className: styles['date-of-manufacture'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				render: (_: number, row: INUM) => (
					<CellTableParagraph
						textAlign="right"
						value={
							row.date_of_manufacture ? dayjs(row.date_of_manufacture).format('DD.MM.YYYY') : undefined
						}
					/>
				),
				onHeaderCell,
			},
			{
				key: 'valid_until',
				title: 'Годен до',
				dataIndex: 'valid_until',
				className: styles['valid_until'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				render: (_: number, row: INUM) => (
					<CellTableParagraph
						textAlign="right"
						value={row.valid_until ? dayjs(row.valid_until).format('DD.MM.YYYY') : undefined}
					/>
				),
				onHeaderCell,
			},
			{
				key: 'validity',
				title: 'Годность',
				dataIndex: 'validity',
				className: styles['validity'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				render: (value: string, row: INUM) => <CellTableParagraph textAlign="left" value={value} />,
				onHeaderCell,
			},
			{
				key: 'comment',
				title: 'Комментарий',
				dataIndex: 'comment',
				className: styles['comment'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				render: (value: string) => <CellTableParagraph value={value} />,
				onHeaderCell,
			},
			{
				key: 'actions',
				title: 'Действия',
				align: 'left',
				dataIndex: 'actions',
				render: (_value: any, row: any) => {
					return (
						<Actions
							width={120}
							onDelete={() => {
								setNumDeleteModalisOpen(true), setSelectedNum(row);
							}}
							onEdit={() => handleClick(row, 'edit')}
							withTemplate
							onCreate={() => handleClick(row, 'create')}
							tooltip={{
								duplicate: 'Создать похожий материал',
								edit: 'Редактировать материал',
								del: 'Удалить материал',
							}}
						/>
					);
				},
				onHeaderCell,
				fixed: 'right',
			},
		];
	}, []);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Partial<INUM>> | SorterResult<Partial<INUM>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				order_by: sortedBy as NumCatalogSortingFields | undefined,
				order_dir: preparedDir as SortingDirection | undefined,
			}));
		},
		[],
	);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<CommonTable
					columns={NUM_CATALOG_COLUMNS}
					data={data}
					onChange={handleTableChange}
					pageSize={pageSize}
					total={total}
					page={page}
					setQueryParams={setQueryParams}
				/>
			)}
			{numDeleteModalisOpen && (
				<DeleteModal
					isOpen={numDeleteModalisOpen}
					onClose={() => setNumDeleteModalisOpen(false)}
					onDelete={() => handleDeleteNum(selectedNum?.id || 0, selectedNum?.num || 0)}
					text={`Вы уверены, что хотите удалить материал ${selectedNum?.symbol || ''}?`}
					title={'Удаление материала'}
				/>
			)}
		</>
	);
};
