import { IssueComments } from '@/entities/issue';
import { Button, Textarea, useToast } from '@chakra-ui-kraud/react';
import styles from './issue.module.scss';
import { FC, useState } from 'react';
import { useCreateCommentForIssueMutation } from '@/shared/state/api/swagger';
import { showErrorToast } from '@/shared';
import { IssueFormCommentsProps } from '../model/issue-form.types';

export const IssueFormComments: FC<IssueFormCommentsProps> = ({ issueId, comments }) => {
	const toast = useToast();

	const [text, setText] = useState('');
	const [comment, { isLoading: isCommentLoading }] = useCreateCommentForIssueMutation();
	const isCommentsDisabled = text === '';
	const handleComment = () => {
		comment({ issueId, issueCommentCreateRequest: { text } })
			.then(() => setText(''))
			.catch(() => {
				showErrorToast(toast, { description: 'Не удалось отправить комментарий' });
			});
	};

	return (
		<>
			<IssueComments comments={comments || []} />
			<Textarea
				placeholder="Комментарий..."
				value={text}
				onChange={(e) => setText(e.target.value)}
				resize="none"
				mt={4}
				mb={2.5}
				fontSize="xs"
			/>
			<Button
				size="md"
				variant="solid"
				colorScheme="primary"
				isDisabled={isCommentsDisabled}
				isLoading={isCommentLoading}
				onClick={handleComment}
				className={styles['comments__submit-button']}
			>
				Добавить комментарий
			</Button>
		</>
	);
};
