import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { ILogin, TECH_CARDS_PATH } from '@/shared/core';
import { useLazyGetUserProfileQuery, useLoginMutation } from '@/shared/state/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { CustomInput } from '@/shared/components/custom-input';
import { Button } from '@chakra-ui-kraud/react';

import { Logo } from '@/shared/assets/icons';
import { MadIcon } from 'madsoft-icons';
import styles from './login.module.scss';
import { REDIRECT_PARAM } from '@/shared';
import background from '@/shared/assets/icons/background.png';
import helicopter from '@/shared/assets/icons/helicopter.png';

export const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPassword, setShowPassword] = useState(false);
	const scheme = yup.object({
		username: yup.string().required('Заполните поле'),
		password: yup.string().required('Заполните поле'),
	});
	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<ILogin>({ resolver: yupResolver(scheme) });

	const [login, { isError: isLoginError, isSuccess: isLoginSuccess, isLoading: isLoginLoading, reset }] =
		useLoginMutation();

	const [getProfile, { isError: isGetProfileError, isLoading: isGetProfileLoading }] = useLazyGetUserProfileQuery();

	const onSubmit: SubmitHandler<ILogin> = (data: ILogin) => {
		login(data);
	};

	useEffect(() => {
		if (isLoginSuccess) {
			getProfile()
				.then(() => {
					const queryParams = new URLSearchParams(location.search);
					const redirectURL = queryParams.get(REDIRECT_PARAM);
					navigate(redirectURL ?? TECH_CARDS_PATH);
				})
				.catch((error) => console.error(error));
		}
	}, [isLoginSuccess, isLoginError]);

	return (
		<div className={styles.login}>
			<div className={styles['login__background']}>
				<img src={background} alt="background" />
				<img src={helicopter} alt="helicopter" className={styles['helicopter']} />
			</div>
			<div className={styles['login__right-block']}>
				<form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
					<Logo width="121px" height="87px" style={{ marginBottom: '20px' }} />
					<Controller
						name="username"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<CustomInput
								labelonTop={true}
								label="E-mail"
								id="email"
								isInvalid={!!error || isLoginError}
								onChange={(e) => {
									onChange(e);
									reset();
								}}
								value={value}
								name="username"
								tooltipContent={error?.message}
								showTooltip={!!error}
							/>
						)}
					/>
					<Controller
						name="password"
						control={control}
						defaultValue=""
						rules={{ required: 'Заполните поле' }}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<CustomInput
								labelonTop={true}
								label="Пароль"
								id="password"
								rightIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name={showPassword ? 'eye-slash' : 'eye'}
										onClick={() => setShowPassword((prev) => !prev)}
									/>
								}
								isInvalid={!!error || isLoginError}
								onChange={(e) => {
									onChange(e);
									reset();
								}}
								value={value}
								type={showPassword ? 'text' : 'password'}
								name="password"
								showTooltip={!!error || isLoginError}
								tooltipContent={'Введенные данные неверны'}
							/>
						)}
					/>
					<Button
						id="submit-button"
						isDisabled={!isValid}
						type="submit"
						isLoading={isGetProfileLoading || isLoginLoading}
						className={styles['login-button']}
						style={{ width: '100%', margin: '12px 0 0' }}
						size="md"
						variant="solid"
						colorScheme="primary"
					>
						Войти
					</Button>
				</form>
			</div>
		</div>
	);
};
