import { FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';
import {
	CancelOperationType,
	RenewalOperationType,
	SideModalLinkOperationStateType,
	TechCardTableProps,
} from '../model/operations.types';

import { Flex, IconButton } from '@chakra-ui-kraud/react';
import { SideModalLinkOperation } from '../side-modal-link-operation';

import { IOperation } from '@/shared';
import { MadIcon } from 'madsoft-icons';
import { ModalOperation } from '../modal-operation';

import { CustomOperationsBlock } from '../custom-operations';
import { DefaultOperationsBlock } from '../default-operations';
import styles from './operations.module.scss';

export const OperationsBlock: FC<TechCardTableProps> = ({
	isEditable,
	focusedBlock,
	setFocusedBlock,
	employee,
	historedOperations,
}) => {
	const { setValue, getValues } = useFormContext();

	const [cancelOperationInfo, setCancelOperationInfo] = useState<CancelOperationType>({
		operation: undefined,
		operationType: undefined,
		closingEmployeeFio: '',
		closingDate: '',
		isModalOpen: false,
	});

	const [renewalOperation, setRenewalOperation] = useState<RenewalOperationType>({
		operation: undefined,
		operationType: undefined,
		isModalOpen: false,
	});

	const { operations: fields } = getValues() as { operations?: IOperation[] };
	const { default_operations: default_fields } = getValues() as {
		default_operations?: IOperation[];
	};

	const [sideModalLinkOperation, setSideModalLinkOperation] = useState<SideModalLinkOperationStateType>({
		isOpen: false,
		title: '',
		operationId: null,
	});

	const renderCancelButton = (
		operation: IOperation | undefined,
		operationType: 'operation' | 'default_operation',
	) => {
		return (
			<IconButton
				aria-label=""
				size="sm"
				variant="ghost"
				colorScheme="gray"
				onClick={() =>
					setCancelOperationInfo((prev) => {
						return {
							...prev,
							operation: operation,
							operationType,
							isModalOpen: true,
						};
					})
				}
				icon={<MadIcon module="basic" size="default" type="outline" mode="default" name="x-circle" />}
			/>
		);
	};

	const renderRenewOperationButton = (
		operation: IOperation | undefined,
		operationType: 'operation' | 'default_operation',
	) => {
		return (
			<IconButton
				aria-label=""
				size="sm"
				variant="ghost"
				colorScheme="gray"
				onClick={() => setRenewalOperation({ operation: operation, operationType, isModalOpen: true })}
				icon={<MadIcon module="basic" size="default" type="outline" mode="default" name="history" />}
			/>
		);
	};

	// отмена операции
	const handleCancelOperation = useCallback(() => {
		const checkIndex = () => {
			// поиск id операции в массиве дефолтных и обычных операций
			if (cancelOperationInfo.operationType === 'operation') {
				return fields?.findIndex((operation) => operation.id === cancelOperationInfo.operation?.id);
			} else {
				return default_fields?.findIndex((operation) => operation.id === cancelOperationInfo.operation?.id);
			}
		};

		// следующая неотмененная операция (обычная)
		const nextNotCanceledOperation = fields?.find(
			(operation) =>
				cancelOperationInfo?.operation &&
				operation.step_id > cancelOperationInfo?.operation?.step_id &&
				!operation.is_canceled,
		);

		// индекс следующей неотмененной операции (обычной)
		const nextNotCanceledOperationIndex = fields?.findIndex(
			(operation) =>
				cancelOperationInfo?.operation &&
				operation.step_id > cancelOperationInfo?.operation?.step_id &&
				!operation.is_canceled,
		);

		// следующая неотмененная операция (ДЕФОЛТНАЯ)
		const nextNotCanceledDefaultOperation = default_fields?.find(
			(operation) =>
				cancelOperationInfo?.operation &&
				operation.step_id > cancelOperationInfo?.operation?.step_id &&
				!operation.is_canceled,
		);

		// индекс следующей неотмененной ДЕФОЛТНОЙ операции
		const nextNotCanceledDefaultOperationIndex = default_fields?.findIndex(
			(operation) =>
				cancelOperationInfo?.operation &&
				operation.step_id > cancelOperationInfo?.operation?.step_id &&
				!operation.is_canceled,
		);

		// индекс текущей отменяемой операции
		const index = checkIndex();

		if (Number(index) > -1) {
			// если отменяем обычную операцию
			if (cancelOperationInfo.operationType === 'operation') {
				// отменяем операцию (меняем стейт)
				setValue(`operations.${index}.is_canceled`, true);
				// устанавливаем ФИО ответственного за отмену операции
				setValue(`operations.${index}.fio`, cancelOperationInfo.closingEmployeeFio);
				// устанавливаем дату отмены операции (либо выбранную в модалке, либо текущю дату)
				setValue(
					`operations.${index}.date`,
					cancelOperationInfo.closingDate ? cancelOperationInfo.closingDate : dayjs(),
				);

				// если в отмененной операции были значения (грамм, штук) - переносим все значения в следующую неотмененную операцию
				if (
					index &&
					(cancelOperationInfo.operation?.count_in_gram ||
						cancelOperationInfo.operation?.count_in_number ||
						cancelOperationInfo.operation?.count_out_gram ||
						cancelOperationInfo.operation?.count_out_number ||
						cancelOperationInfo.operation?.wasted)
				) {
					// если это последняя обычная операция ИЛИ остальные (обычные) операции отменены (остались только дефолтные)
					if ((fields && fields.length - 1 === index) || nextNotCanceledOperationIndex === -1) {
						setValue(
							`default_operations.${nextNotCanceledDefaultOperationIndex}.count_in_gram`,
							cancelOperationInfo.operation?.count_in_gram ?? '',
						);
						setValue(
							`default_operations.${nextNotCanceledDefaultOperationIndex}.count_in_number`,
							cancelOperationInfo.operation?.count_in_number ?? '',
						);
						setValue(
							`default_operations.${nextNotCanceledDefaultOperationIndex}.count_out_gram`,
							cancelOperationInfo.operation?.count_out_gram ?? '',
						);
						setValue(
							`default_operations.${nextNotCanceledDefaultOperationIndex}.count_out_number`,
							cancelOperationInfo.operation?.count_out_number ?? '',
						);
						setValue(
							`default_operations.${nextNotCanceledDefaultOperationIndex}.wasted`,
							cancelOperationInfo.operation?.wasted ?? '',
						);
					} else {
						if (cancelOperationInfo.operation) {
							// если есть следующая неотмененная (обычныя) операция
							if (nextNotCanceledOperation && nextNotCanceledOperationIndex !== -1) {
								// если у следующей неотмененной (обычной) операции нет значения (далее проверяем поля)
								!nextNotCanceledOperation.count_in_gram
									? setValue(
											`operations.${nextNotCanceledOperationIndex}.count_in_gram`,
											cancelOperationInfo.operation?.count_in_gram ?? '',
									  )
									: null;

								!nextNotCanceledOperation.count_in_number
									? setValue(
											`operations.${nextNotCanceledOperationIndex}.count_in_number`,
											cancelOperationInfo.operation?.count_in_number ?? '',
									  )
									: null;

								!nextNotCanceledOperation.count_out_gram
									? setValue(
											`operations.${nextNotCanceledOperationIndex}.count_out_gram`,
											cancelOperationInfo.operation?.count_out_gram ?? '',
									  )
									: null;

								!nextNotCanceledOperation.count_out_number
									? setValue(
											`operations.${nextNotCanceledOperationIndex}.count_out_number`,
											cancelOperationInfo.operation?.count_out_number ?? '',
									  )
									: null;

								!nextNotCanceledOperation.count_out_number
									? setValue(
											`operations.${nextNotCanceledOperationIndex}.wasted`,
											cancelOperationInfo.operation?.wasted ?? '',
									  )
									: null;
							}
						}
					}
				}

				// очищаем значения в текущей (отмененной) обычной операции
				setValue(`operations.${index}.count_in_gram`, '');
				setValue(`operations.${index}.count_in_number`, '');
				setValue(`operations.${index}.count_out_gram`, '');
				setValue(`operations.${index}.count_out_number`, '');
				setValue(`operations.${index}.wasted`, '');
			} else {
				// если отменяем ДЕФОЛТНУЮ операцию
				setValue(`default_operations.${index}.is_canceled`, true);
				setValue(`default_operations.${index}.fio`, cancelOperationInfo.closingEmployeeFio);
				setValue(
					`default_operations.${index}.date`,
					cancelOperationInfo.closingDate ? cancelOperationInfo.closingDate : dayjs(),
				);
				if (cancelOperationInfo.operation) {
					// если есть следующая неотмененная (ДЕФОЛТНАЯ) операция
					if (nextNotCanceledDefaultOperation && nextNotCanceledDefaultOperationIndex !== -1) {
						// если у следующей неотмененной (ДЕФОЛТНОЙ) операции нет значения (далее проверяем поля)
						!nextNotCanceledDefaultOperation.count_in_gram
							? setValue(
									`default_operations.${nextNotCanceledDefaultOperationIndex}.count_in_gram`,
									cancelOperationInfo.operation?.count_in_gram ?? '',
							  )
							: null;

						!nextNotCanceledDefaultOperation.count_in_number
							? setValue(
									`default_operations.${nextNotCanceledDefaultOperationIndex}.count_in_number`,
									cancelOperationInfo.operation?.count_in_number ?? '',
							  )
							: null;

						!nextNotCanceledDefaultOperation.count_out_gram
							? setValue(
									`default_operations.${nextNotCanceledDefaultOperationIndex}.count_out_gram`,
									cancelOperationInfo.operation?.count_out_gram ?? '',
							  )
							: null;

						!nextNotCanceledDefaultOperation.count_out_number
							? setValue(
									`default_operations.${nextNotCanceledDefaultOperationIndex}.count_out_number`,
									cancelOperationInfo.operation?.count_out_number ?? '',
							  )
							: null;

						!nextNotCanceledDefaultOperation.count_out_number
							? setValue(
									`default_operations.${nextNotCanceledDefaultOperationIndex}.wasted`,
									cancelOperationInfo.operation?.wasted ?? '',
							  )
							: null;
					}
				}

				// очищаем значения в текущей (отмененной) ДЕФОЛТНОЙ операции
				setValue(`default_operations.${index}.count_in_gram`, '');
				setValue(`default_operations.${index}.count_in_number`, '');
				setValue(`default_operations.${index}.count_out_gram`, '');
				setValue(`default_operations.${index}.count_out_number`, '');
				setValue(`default_operations.${index}.wasted`, '');
			}
		}

		// очищаем стейт отмененной операции
		setCancelOperationInfo({
			closingEmployeeFio: '',
			operation: undefined,
			operationType: undefined,
			isModalOpen: false,
			closingDate: '',
		});
	}, [cancelOperationInfo, fields, default_fields]);

	// возобновление операции
	const handleRenewalOperation = useCallback(() => {
		// поиск id операции в массиве дефолтных и обычных операций
		const checkIndex = () => {
			if (renewalOperation.operationType === 'operation') {
				return fields?.findIndex((operation) => operation.id === renewalOperation.operation?.id);
			} else {
				return default_fields?.findIndex((operation) => operation.id === renewalOperation.operation?.id);
			}
		};

		const index = checkIndex();

		if (Number(index) > -1) {
			if (renewalOperation.operationType === 'operation') {
				setValue(`operations.${index}.is_canceled`, false);
				setValue(`operations.${index}.fio`, '');
			} else {
				setValue(`default_operations.${index}.is_canceled`, false);
				setValue(`default_operations.${index}.fio`, '');
			}
		}

		// очищаем стейт возобновленной операции
		setRenewalOperation({
			operation: undefined,
			operationType: undefined,
			isModalOpen: false,
		});
	}, [renewalOperation, fields, default_fields]);

	return (
		<>
			{sideModalLinkOperation.operationId && (
				<SideModalLinkOperation
					isOpen={sideModalLinkOperation.isOpen}
					onClose={() => setSideModalLinkOperation({ isOpen: false, title: '', operationId: null })}
					title={sideModalLinkOperation.title}
					operationId={sideModalLinkOperation.operationId}
				/>
			)}
			{cancelOperationInfo.isModalOpen && (
				<ModalOperation
					data={cancelOperationInfo}
					onSubmit={handleCancelOperation}
					variant="cancel"
					employee={employee}
					setData={setCancelOperationInfo}
				/>
			)}
			{renewalOperation.isModalOpen && (
				<ModalOperation
					data={renewalOperation}
					onSubmit={handleRenewalOperation}
					variant="renewal"
					setData={setRenewalOperation}
				/>
			)}

			<Flex className={styles['operations']} direction="column">
				<CustomOperationsBlock
					setSideModalLinkOperation={setSideModalLinkOperation}
					renderCancelButton={renderCancelButton}
					renderRenewOperationButton={renderRenewOperationButton}
					employee={employee}
					isEditable={isEditable}
					focusedBlock={focusedBlock}
					setFocusedBlock={setFocusedBlock}
					historedOperations={historedOperations}
				/>

				<DefaultOperationsBlock
					setSideModalLinkOperation={setSideModalLinkOperation}
					renderCancelButton={renderCancelButton}
					renderRenewOperationButton={renderRenewOperationButton}
					employee={employee}
					isEditable={isEditable}
					focusedBlock={focusedBlock}
					setFocusedBlock={setFocusedBlock}
					historedOperations={historedOperations}
				/>
			</Flex>
		</>
	);
};
