import { defineStyle, defineStyleConfig } from '@chakra-ui-kraud/react';
import { modalTheme } from './components/modal-theme';

const xl = defineStyle({
	borderRadius: '6px',
	lineHeight: 4,
	fontWeight: 600,
	padding: '4px 8px 4px 8px',
});

const badgeTheme = defineStyleConfig({
	sizes: { xl },
});

const md = defineStyle({
	fontSize: '18px',
	fontWeight: 600,
	lineHeight: '28px',
});

const textHeaderTheme = defineStyleConfig({
	sizes: { md },
});

export const components = {
	Modal: modalTheme,
	Badge: badgeTheme,
	TextHeader: textHeaderTheme,
	Input: {
		sizes: {
			lg: {
				field: {
					borderRadius: '4px',
				},
			},
			sm: {
				field: {
					borderRadius: '4px',
				},
			},
			md: {
				field: {
					borderRadius: '4px',
				},
			},
		},
	},
};
