import { GetUserRolesMappingApiResponse } from '@/shared/state/api/swagger';
import { createSelector } from '@reduxjs/toolkit';

export const selectUserRolesOptions = createSelector(
	(roles?: GetUserRolesMappingApiResponse) => roles,
	(roles) =>
		Object.entries(roles ?? {}).map(([key, value]) => ({
			value: key,
			label: value,
		})),
);

export const selectRolesOptionsForAdminCreate = createSelector(selectUserRolesOptions, (roles) =>
	roles.filter((item) => !['admin', 'executor'].includes(item.value)),
);
