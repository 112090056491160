import { LoadingOutlined } from '@ant-design/icons';
import { Text } from '@chakra-ui-kraud/react';
import style from './not-found-content-dropdown.module.scss';

export const NotFoundContentDropdown = ({
	isFetchingNaming,
	alertName,
	noData,
}: {
	noData?: boolean;
	isFetchingNaming: boolean;
	alertName: string;
}) => (
	<div className={style['dropdown-filter-wraper']}>
		{isFetchingNaming ? (
			<LoadingOutlined />
		) : noData ? (
			<>Результатов нет</>
		) : (
			<Text size="md" lineHeight="6" fontWeight="400">
				{alertName}
			</Text>
		)}
	</div>
);
