import { api } from './api';
import { IHistoryOperation, IOperation, IOperationDefaultResponse } from '@/shared/core';

export const OperationApi = api.injectEndpoints({
	endpoints: (builder) => ({
		updateOperation: builder.query<any, any>({
			query(body) {
				return {
					url: '/operation',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['Operation'],
		}),
		cancelOperation: builder.mutation<void, { operation_id: number; closing_employee_fio: string }>({
			query(body) {
				return {
					url: '/operation/cancel',
					method: 'POST',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			invalidatesTags: ['Operation'],
		}),
		renewalOperation: builder.mutation<void, { operation_id: number }>({
			query(body) {
				return {
					url: '/operation/renewal',
					method: 'POST',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			invalidatesTags: ['Operation'],
		}),
		createOperation: builder.query<IOperation, IOperation>({
			query(body) {
				return {
					url: '/operation',
					method: 'POST',
					body,
					credentials: 'include',
				};
			},
			providesTags: ['Operation'],
		}),
		updateOperationOperator: builder.query<
			{ operations_payload: IOperation[]; default_operations_payload: Partial<IOperation[]> },
			any
		>({
			query(body) {
				return {
					url: '/operation/operator',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['Operation'],
		}),
		getDefaultOperations: builder.query<IOperationDefaultResponse[], void>({
			query() {
				return {
					url: '/operation/default',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Operation'],
		}),
		getOperationLinkHistoryById: builder.query<IHistoryOperation[], { operation_id: number }>({
			query(params) {
				return {
					url: `/operation/link_history/${params.operation_id}`,
					method: 'GET',
					credentials: 'include',
				};
			},
		}),
		getCurrentOperations: builder.query<string[], void>({
			query() {
				return {
					url: '/operation/current_list',
					method: 'GET',
					credentials: 'include',
				};
			},
		}),
	}),
});

export const {
	useUpdateOperationQuery,
	useLazyUpdateOperationQuery,
	useCreateOperationQuery,
	useLazyCreateOperationQuery,
	useLazyUpdateOperationOperatorQuery,
	useUpdateOperationOperatorQuery,
	useCancelOperationMutation,
	useRenewalOperationMutation,
	useGetDefaultOperationsQuery,
	useGetOperationLinkHistoryByIdQuery,
	useLazyGetOperationLinkHistoryByIdQuery,
	useGetCurrentOperationsQuery,
} = OperationApi;
