import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const differenceInDays = (date: Date) => {
	const currentDate = dayjs();
	const stuckDate = dayjs(date);

	if (!stuckDate.isValid()) {
		return undefined;
	}

	const diffInDays = currentDate.diff(stuckDate, 'day');

	return diffInDays;
};
