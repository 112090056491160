import clsx from 'clsx';
import { useEffect, useState } from 'react';
import styles from './operation-layout.module.scss';
import { OperationLayoutProps } from './operation-layout.types';

export const OperationLayout = ({ header, children, footer }: OperationLayoutProps) => {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isScrollEnd, setIsScrollEnd] = useState(true);

	useEffect(() => {
		const handleScroll = () => {
			if (!document?.scrollingElement) return;

			const { scrollTop, scrollHeight, clientHeight } = document.scrollingElement;

			if (scrollTop > 10) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}

			if (scrollTop + clientHeight >= scrollHeight - 10) {
				setIsScrollEnd(true);
			} else {
				setIsScrollEnd(false);
			}
		};

		document?.addEventListener('scroll', handleScroll);

		return () => {
			document?.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<form className={styles['layout']}>
			<div className={clsx(styles['header'], { [styles['scrolled']]: isScrolled })}>
				<div className={clsx(styles['header-content'])}>{header}</div>
			</div>
			<div className={styles['children']}>
				<div className={styles['children-content']}>{children}</div>
			</div>
			<div className={clsx(styles['footer'], { [styles['scroll-end']]: !isScrollEnd })}>
				<div className={styles['footer-content']}>{footer}</div>
			</div>
		</form>
	);
};
