import { api } from './api';
import queryString from 'query-string';
import { ProductCatalogSymbolListResponse } from './swagger';

export const ProductCatalogyFilterApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getNameFilter: builder.query<
			{ page: number; pages: number; payload: any[]; total: number; page_size: 332 },
			{ string_to_search?: string }
		>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/product_catalog/filter/name${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Products'],
		}),
		getSymbolFilter: builder.query<
			{
				page: number;
				pages: number;
				payload: ProductCatalogSymbolListResponse['payload'];
				total: number;
				page_size: 332;
			},
			{ string_to_search?: string }
		>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/product_catalog/filter/symbol${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Products'],
		}),
	}),
});

export const { useLazyGetNameFilterQuery, useLazyGetSymbolFilterQuery } = ProductCatalogyFilterApi;
