import { Text } from '@chakra-ui-kraud/react';
import styles from './stub.module.scss';
import { StubProps } from './stub.types';

export const Stub = ({ icon, label, header, footer }: StubProps) => {
	return (
		<div className={styles['background']}>
			<div className={styles['container']}>
				{icon}
				<div className={styles['text-wrapper']}>
					<Text color="gray.800" m="0" textAlign="center" fontSize="24px" fontWeight="700" lineHeight="32px">
						{header}
					</Text>
					<Text color="gray.800" m="0" textAlign="center" fontWeight="400" lineHeight="24px">
						{label}
					</Text>
				</div>
				<div className={styles['footer']}>{footer}</div>
			</div>
		</div>
	);
};
