import { TOAST_DURATION } from '@/shared/constants';
import type { ToastProps } from './toasts.types';

export const showSuccessToast: ToastProps = (toast, options) =>
	toast({
		status: 'success',
		colorScheme: 'success',
		title: 'Успех',
		isClosable: true,
		variant: 'left-accent',
		duration: TOAST_DURATION,
		position: 'top-left',
		...options,
	});
