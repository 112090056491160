import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
} from '@chakra-ui-kraud/react';
import { RestoreUserModalProps } from '../model/restore-user-modal.types';
import { useRestoreUserMutation } from '@/shared/state/api/swagger';
import { abbreviateUser, showErrorToast, showSuccessToast } from '@/shared';
import { FC, useCallback } from 'react';

export const RestoreUserModal: FC<RestoreUserModalProps> = ({ isOpen, onClose, user }) => {
	const { id, role, first_name, middle_name, last_name } = user;
	const fio = abbreviateUser(first_name, last_name, middle_name);
	const [restore, { isLoading }] = useRestoreUserMutation();

	const toast = useToast();
	const handleRestore = useCallback(() => {
		restore({ userRestoreRequest: { user_id: id } })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, {
					description: `${role === 'executor' ? 'Сотрудник' : 'Пользователь'} восстановлен`,
				});
			})
			.catch(() => {
				showErrorToast(toast, {
					description: 'При восстановлении сотрудника произошла ошибка',
				});
			})
			.finally(onClose);
	}, [onClose, user]);

	return (
		<Modal isOpen={isOpen} isCentered onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize="lgo">
					Восстановление {role === 'executor' ? 'сотрудника' : 'пользователя'}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Вы уверены, что хотите восстановить {role === 'executor' ? 'сотрудника' : 'пользователя'} «{fio}»?
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Оставить
					</Button>
					<Button mr={3} onClick={handleRestore} isLoading={isLoading}>
						Восстановить
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
