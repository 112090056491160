import { FC } from 'react';
import { CanceledStatusModalProps } from './canceled-status-modal.types';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui-kraud/react';

export const CanceledStatusModal: FC<CanceledStatusModalProps> = ({ isOpen, onClose, onCancelTechMap }) => {
	return (
		<Modal isOpen={isOpen} isCentered onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Аннулирование МК</ModalHeader>
				<ModalCloseButton />
				<ModalBody>Это действие нельзя отменить. Вы уверены, что хотите аннулировать МК? </ModalBody>
				<ModalFooter>
					<Button variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						variant={'solid'}
						mr={3}
						onClick={() => {
							onCancelTechMap();
							onClose();
						}}
					>
						Аннулировать
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
