import styles from './loader.module.scss';
import Lottie from 'lottie-react';
import animationData from '@/shared/constants/lotties/loader.json';

export const Loader = () => {
	return (
		<div className={styles['loader-wrapper']}>
			<Lottie animationData={animationData} style={{ width: '477px', maxWidth: '100vw' }} />
		</div>
	);
};
