import { Tab, TabList, Tabs } from '@chakra-ui-kraud/react';
import {
	resetRoleFilter,
	setExecutorFilter,
	setOnlyUsersFilter,
	UserFiltersDispatch,
} from '../model/user-filters-reducer';
import { UserTabsProps } from '../model/users-tab.types';

export const UserTabs = ({ handleResetTableFilters, dispatch }: UserTabsProps) => {
	const handleTab = (action: (dispatch: UserFiltersDispatch) => void) => {
		action(dispatch);
		handleResetTableFilters();
	};

	return (
		<Tabs isLazy padding={0}>
			<TabList whiteSpace="nowrap" padding="0">
				<Tab onClick={() => handleTab(setExecutorFilter)}>Сотрудники</Tab>
				<Tab onClick={() => handleTab(setOnlyUsersFilter)}>Пользователи системы</Tab>
				<Tab onClick={() => handleTab(resetRoleFilter)}>Все</Tab>
			</TabList>
		</Tabs>
	);
};
