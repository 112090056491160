import { combineReducers } from '@reduxjs/toolkit';
import { api } from '../api';
import { authReducer } from '../slices';
import { generatedApi } from '@/shared/state/api/swagger/generated-api';

export const rootReducer = combineReducers({
	[api.reducerPath]: api.reducer,
	[generatedApi.reducerPath]: generatedApi.reducer,
	auth: authReducer,
});
