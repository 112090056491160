import { api } from './api';
import { ISGDOut } from '@/shared/core';

export const SGDOutApi = api.injectEndpoints({
	endpoints: (builder) => ({
		createSGDOut: builder.query<ISGDOut[], ISGDOut[]>({
			query(body) {
				return {
					url: '/sgd_out',
					method: 'POST',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['SGDOut'],
		}),
		updateSGDOut: builder.query<ISGDOut[], ISGDOut[]>({
			query(body) {
				return {
					url: '/sgd_out',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['SGDOut'],
		}),
		updateSGDOutOperator: builder.query<
			{ update_payload: ISGDOut[]; create_payload: ISGDOut[] },
			{ update_payload: ISGDOut[]; create_payload: ISGDOut[] }
		>({
			query(body) {
				return {
					url: '/sgd_out/operator',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['SGDOut'],
		}),
	}),
});

export const {
	useUpdateSGDOutQuery,
	useLazyUpdateSGDOutQuery,
	useCreateSGDOutQuery,
	useLazyCreateSGDOutQuery,
	useLazyUpdateSGDOutOperatorQuery,
	useUpdateSGDOutOperatorQuery,
} = SGDOutApi;
