import styles from '@/shared/components/custom-select/custom-select.module.scss';
import { Button, Flex, Text } from '@chakra-ui-kraud/react';
import { Select } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FiltersProps } from '../model/filters-types';

interface UsersProps extends FiltersProps {
	label: string;
	name: 'executorUserId' | 'responsibleUserId';
	mappedEmployees: {
		label: string;
		value: number;
	}[];
}

export const Users = ({ control, label, mappedEmployees, name }: UsersProps) => {
	const [groupVisibility, setGroupVisibility] = useState(false);

	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<Flex flexDirection="column">
						<Flex flexDirection="row" justifyContent="space-between">
							<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
								{label}
							</Text>
							{!!value && (
								<Button size="xs" variant="ghost" onClick={() => onChange(null)}>
									Очистить
								</Button>
							)}
						</Flex>
						<Select
							onFocus={() => setGroupVisibility(true)}
							onBlur={() => setGroupVisibility(false)}
							showSearch
							allowClear={false}
							options={mappedEmployees}
							value={value}
							onChange={(e) => onChange(e)}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							optionFilterProp="children"
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							className={clsx(
								styles['select'],
								styles['select-large'],
								groupVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					</Flex>
				);
			}}
		/>
	);
};
