import { abbreviateUser, FIRE_USER_CACHE_KEY } from '@/shared';
import { DeleteUserFormModalProps } from '../model/delete-user-form.types';
import React, { FC, useRef } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
} from '@chakra-ui-kraud/react';
import { DeleteUserForm } from './delete-user-form';
import { useFireUserMutation } from '@/shared/state/api/swagger';

export const DeleteUserModal: FC<DeleteUserFormModalProps> = ({ onClose, isOpen, user }) => {
	const { first_name, last_name, middle_name } = user;
	const fio = abbreviateUser(first_name, last_name, middle_name);
	const formRef = useRef<HTMLFormElement>(null);
	const [, { isLoading }] = useFireUserMutation({ fixedCacheKey: FIRE_USER_CACHE_KEY });

	return (
		<Modal isOpen={isOpen} isCentered onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize="lg">Увольнение сотрудника</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Stack gap={4}>
						<p>Вы уверены, что хотите уволить «{fio}»?</p>
						<DeleteUserForm user={user} onResult={onClose} ref={formRef} />
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Оставить
					</Button>
					<Button
						variant={'solid'}
						mr={3}
						colorScheme="danger"
						onClick={() => formRef.current?.requestSubmit()}
						isLoading={isLoading}
					>
						Уволить
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
