import { FC, useRef, useState } from 'react';

import { isFetchBaseQueryError, useCreateUserMutation, useUpdateUserMutation } from '@/shared/state/api';
import { UserModalProps } from './user-modal.types';

import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import { Button, ButtonGroup, Flex, IconButton, TextHeader, useToast } from '@chakra-ui-kraud/react';
import { SideModal } from '@/shared/components';
import { UserForm } from '@/features/user-forms';
import { MadIcon } from 'madsoft-icons';

export const UserModal: FC<UserModalProps> = ({ isOpen, onClose, onBlur, selectedUser, title, variant }) => {
	const formRef = useRef<HTMLFormElement>();
	const toast = useToast();

	const [createUser, { isLoading: isCreateUserLoading }] = useCreateUserMutation();
	const [updateUser, { isLoading: isUpdateUserLoading }] = useUpdateUserMutation();

	const handleSubmit = (data: any) => {
		if (variant === 'create') {
			createUser({ ...data, username: data.email })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Пользователь создан' });
					onClose();
				})
				.catch((err) => {
					if (isFetchBaseQueryError(err)) {
						if (err.status === 469) {
							showErrorToast(toast, {
								title: 'Почта занята',
								description: 'Придумайте другую почту для этого сотрудника',
							});
							return;
						}
					}
					showErrorToast(toast, {
						description: 'При создании пользователя произошла ошибка',
					});
				});
		}
		if (variant === 'edit') {
			updateUser({ ...data, username: data.email })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Пользователь отредактирован' });
					onClose();
				})
				.catch((err) => {
					if (isFetchBaseQueryError(err)) {
						if (err.status === 469) {
							showErrorToast(toast, {
								title: 'Почта занята',
								description: 'Придумайте другую почту для этого сотрудника',
							});
							return;
						}
					}
					showErrorToast(toast, {
						description: 'При редактировании пользователя произошла ошибка',
					});
				});
		}
	};

	const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
	const [formWasEdit, setFormWasEdit] = useState(false);

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			variant={variant}
			isChangePasswordModalOpen={isChangePasswordModalOpen}
			header={
				<Flex alignItems="center" gap={2}>
					<IconButton
						aria-label=""
						icon={<MadIcon module="basic" mode="default" size="default" type="outline" name="arrow-left" />}
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						backgroundColor="transparent"
						padding="0 12px"
						onClick={() => {
							setChangePasswordModalOpen(false);
						}}
					/>
					<TextHeader fontWeight={'semibold'} variant="h5">
						Изменение пароля
					</TextHeader>
				</Flex>
			}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							if (variant === 'create') {
								onClose();
								return;
							}
							if (isChangePasswordModalOpen) {
								setChangePasswordModalOpen(false);
								return;
							}
							onClose();
						}}
					>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.submitForm();
						}}
						isDisabled={isCreateUserLoading || isUpdateUserLoading}
					>
						{(() => {
							if (variant === 'create') {
								return <>Создать пользователя</>;
							}
							if (isChangePasswordModalOpen) {
								return <>Изменить пароль</>;
							}
							return <>Сохранить</>;
						})()}
					</Button>
				</ButtonGroup>
			}
			onBlur={onBlur ?? onClose}
		>
			<UserForm
				reference={formRef}
				onSubmit={handleSubmit}
				selectedUser={selectedUser}
				setFormWasEdit={setFormWasEdit}
				setChangePasswordModalOpen={setChangePasswordModalOpen}
				isChangePasswordModalOpen={isChangePasswordModalOpen}
				variant={variant}
			/>
		</SideModal>
	);
};
