import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (date: string) => dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

export { formatDate };
