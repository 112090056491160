import { IEmployee, IEmployeeInitials } from '@/shared/core';
import { api } from './api';

export const EmployeeApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEmployee: builder.query<IEmployee[], void>({
			query() {
				return {
					url: '/employee_catalog',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Employee'],
		}),
		createEmployee: builder.mutation<IEmployee, IEmployee>({
			query() {
				return {
					url: '/employee_catalog',
					method: 'POST',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Employee'],
		}),
		updateEmployee: builder.mutation<IEmployee, IEmployee>({
			query() {
				return {
					url: '/employee_catalog',
					method: 'PUT',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Employee'],
		}),
		deleteEmployee: builder.mutation<boolean, IEmployee['id']>({
			query() {
				return {
					url: '/employee_catalog',
					method: 'DELETE',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Employee'],
		}),
		getEmployeeInitials: builder.query<{ id: number; initials: string }[], void>({
			query() {
				return {
					url: '/employee_catalog/initials',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Employee'],
		}),
	}),
});

export const {
	useGetEmployeeQuery,
	useCreateEmployeeMutation,
	useUpdateEmployeeMutation,
	useDeleteEmployeeMutation,
	useGetEmployeeInitialsQuery,
} = EmployeeApi;
