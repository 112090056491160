import { EngineerValidateQuestionDocument, Stub } from '@/shared';

export const RoutemapRequestError = () => {
	return (
		<Stub
			icon={<EngineerValidateQuestionDocument />}
			header="Что-то сломалось"
			label={<p>Пожалуйста, обратитесь в тех. поддержку</p>}
		/>
	);
};
