import { Button, Flex, Radio, Text } from '@chakra-ui-kraud/react';
import { Controller } from 'react-hook-form';
import { FiltersProps } from '../model/filters-types';

export const Statuses = ({ control }: FiltersProps) => {
	return (
		<Controller
			control={control}
			name="status"
			render={({ field: { value, onChange } }) => (
				<Flex flexDirection="column">
					<Flex flexDirection="row" justifyContent="space-between">
						<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
							Статус
						</Text>
						{!!value && (
							<Button size="xs" variant="ghost" onClick={() => onChange(null)}>
								Очистить
							</Button>
						)}
					</Flex>
					<Flex flexDirection="column" gap="4px">
						<Radio name="closed" isChecked={value === 'closed'} onChange={() => onChange('closed')}>
							Решена
						</Radio>
						<Radio name="open" isChecked={value === 'open'} onChange={() => onChange('open')}>
							К исполнению
						</Radio>
						<Radio name="progress" isChecked={value === 'progress'} onChange={() => onChange('progress')}>
							В работе
						</Radio>
					</Flex>
				</Flex>
			)}
		/>
	);
};
