import { Table, Tr, Th, Td, Flex, Input, Text } from '@chakra-ui-kraud/react';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { PlanBlockTypes } from './plan.types';
import defaultStyles from '../../tech-card.module.scss';
import { useAppSelector } from '@/shared/state';
import { calculateSummary, UserRoles } from '@/shared/core';
import { Select } from 'antd';
import styles from './plan.module.scss';
import clsx from 'clsx';

export const PlanBlock: FC<PlanBlockTypes> = ({
	isEditable,
	isCreationMode,
	cardInfo,
	focusedBlock,
	setFocusedBlock,
	employee,
	isPrinting,
}) => {
	const { control, trigger, setValue } = useFormContext();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [releases, return_count] = useWatch({
		control,
		name: ['plan.releases', 'plan.return_count'],
	});
	const [planCount, planNRKg, planNRMeters] = useWatch({
		control,
		name: ['plan.count', 'plan.NR_kg', 'plan.NR_meters'],
	});

	const isRoleCorrect = useMemo(
		() => [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles),
		[userRole],
	);

	useEffect(() => {
		if (isCreationMode) trigger();
	}, []);

	useEffect(() => {
		setValue('plan.summary', calculateSummary({ releases, return_count }));
	}, [releases, return_count]);

	useEffect(() => {
		let count = Number(String(planCount).replace(',', '.'));
		count = Number.isNaN(count) ? 0 : count;

		let result = 0;

		const kg = Number(String(planNRKg).replace(',', '.'));
		const meters = Number(String(planNRMeters).replace(',', '.'));

		// приоритет расчета "К выдаче" в метрах
		if (!!planNRMeters && !Number.isNaN(meters)) {
			result = count * meters;
		} else if (!!planNRKg && !Number.isNaN(kg)) {
			result = count * kg;
		}

		if (result) {
			setValue('plan.issurance_count', result.toFixed(2).replace('.', ','));
		} else {
			setValue('plan.issurance_count', '');
		}
	}, [planCount, planNRKg, planNRMeters]);

	const emptyRows = (length: number) => {
		const rows = new Array(length).fill({});
		return rows.map((_, idx) => (
			<Tr
				key={idx}
				className={clsx(
					isCreationMode ? styles['create_plan_middle_block_td'] : styles['plan_middle_block_td'],
				)}
			>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
			</Tr>
		));
	};

	return (
		<>
			<Flex direction="column" marginTop={'-1px'} className={styles['plan-block']}>
				<Flex h="100%">
					<Table
						h="100%"
						flex={1}
						className={clsx(
							focusedBlock?.block === 'plan' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
						)}
						onFocus={() => setFocusedBlock && setFocusedBlock('plan')}
					>
						<Tr className={styles['plan_top_block_th']}>
							<Th w="176px">План шт.</Th>
							<Th w="112px">НР на кг</Th>
							<Th w="104px">НР на м</Th>
							<Th w="148px">К выдаче, м</Th>
							<Th w="90px">Подпись</Th>
							<Th w="192px">ФИО ПДС</Th>
						</Tr>
						<Tr className={styles['plan_top_block_td']}>
							<Td>
								{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
									<Controller
										name="plan.count"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={value}
												name="plan.count"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.plan?.count}</Text>
								)}
							</Td>
							<Td>
								{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
									<Controller
										name="plan.NR_kg"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												defaultValue={''}
												value={value && value.length ? String(value).replace('.', ',') : ''}
												name="plan.NR_kg"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{cardInfo?.plan?.NR_kg ? String(cardInfo?.plan?.NR_kg).replace('.', ',') : '-'}
									</Text>
								)}
							</Td>
							<Td>
								{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
									<Controller
										name="plan.NR_meters"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												defaultValue={''}
												value={value && value.length ? String(value).replace('.', ',') : ''}
												name="plan.NR_meters"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{cardInfo?.plan?.NR_meters
											? String(cardInfo?.plan?.NR_meters).replace('.', ',')
											: '-'}
									</Text>
								)}
							</Td>
							<Td>
								{isCreationMode || (isEditable && userRole === UserRoles.admin && !isPrinting) ? (
									<Controller
										name="plan.issurance_count"
										control={control}
										render={({ field: { value, onChange, name }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												name={name}
												onChange={onChange}
												value={value ? String(value).replace('.', ',') : ''}
												tabIndex={5}
												isInvalid={!!error}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{String(cardInfo?.plan?.issurance_count).replace('.', ',')}
									</Text>
								)}
							</Td>
							<Td></Td>
							<Td>
								{(isCreationMode || (isEditable && isRoleCorrect)) && !isPrinting ? (
									<Controller
										name="plan.fio"
										control={control}
										render={({ field: { value, onChange }, fieldState: { error } }) => (
											<Select
												status={error?.message?.length ? 'error' : ''}
												tabIndex={5}
												showSearch
												placeholder="Выберите ФИО"
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													setValue('plan.fio', employee.find((el) => el.value === e)?.label, {
														shouldValidate: true,
													});
												}}
												value={employee.find((el) => el.label === value)?.value}
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												options={employee}
												style={{
													width: '100%',
													whiteSpace: 'nowrap',
													boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
												}}
												className={styles['custom-select']}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.plan.fio}</Text>
								)}
							</Td>
						</Tr>
					</Table>
				</Flex>
				<Flex>
					<Table
						flex={1}
						onFocus={() => setFocusedBlock && setFocusedBlock('plan_in_out')}
						className={clsx(
							focusedBlock?.block === 'plan_in_out' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
						)}
					>
						<Tr
							className={
								isCreationMode ? styles['create_plan_middle_block_th'] : styles['plan_middle_block_th']
							}
						>
							<Th w="220px">Выдано, м/(мхм) х шт.</Th>
							<Th>Выдано, кг</Th>
							<Th>Партия (№МК)</Th>
							<Th w="80px">Подпись</Th>
							<Th w="200px">ФИО выдавшего материал</Th>
						</Tr>

						{!isEditable && cardInfo && (
							<>
								{cardInfo?.plan?.releases?.map((item) => (
									<Tr
										key={item.id}
										className={clsx(
											isCreationMode
												? styles['create_plan_middle_block_td']
												: styles['plan_middle_block_td'],
										)}
									>
										<Td>
											<Text className={styles['print_bold_font']}>{item?.count}</Text>
										</Td>
										<Td>
											<Text className={styles['print_bold_font']}>{item.count_kg}</Text>
										</Td>
										<Td>{item?.number}</Td>
										<Td></Td>
										<Td>
											<Text className={styles['print_bold_font']}>{item?.fio}</Text>
										</Td>
									</Tr>
								))}
							</>
						)}
						{!cardInfo && <>{emptyRows(3)}</>}
						{isEditable && cardInfo && (
							<>
								{cardInfo?.plan?.releases?.map((field, index) => (
									<Tr
										key={field.id}
										className={
											isCreationMode
												? styles['create_plan_middle_block_td']
												: styles['plan_middle_block_td']
										}
									>
										<Td>
											<Controller
												name={`plan.releases.${index}.count`}
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														backgroundColor="transparent"
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={
															value
																? String(value.replace(/[^0-9,.хx]/g, '')).replace(
																		/\./g,
																		',',
																  )
																: ''
														}
														name={`plan.releases.${index}.count`}
													/>
												)}
											/>
										</Td>
										<Td>
											<Controller
												name={`plan.releases.${index}.count_kg`}
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={
															value
																? String(value.replace(/[^0-9,.хx]/g, '')).replace(
																		/\./g,
																		',',
																  )
																: ''
														}
														name={`plan.releases.${index}.count_kg`}
													/>
												)}
											/>
										</Td>
										<Td>
											<Controller
												name={`plan.releases.${index}.number`}
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														backgroundColor="transparent"
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={
															value
																? String(value.replace(/[^0-9,.хx]/g, '')).replace(
																		/\./g,
																		',',
																  )
																: ''
														}
														name={`plan.releases.${index}.number`}
													/>
												)}
											/>
										</Td>
										<Td></Td>
										<Td>
											<Controller
												name={`plan.releases.${index}.fio`}
												control={control}
												render={({ field: { value, onChange }, fieldState: { error } }) => (
													<Select
														status={error?.message ? 'error' : ''}
														allowClear
														tabIndex={5}
														filterOption={(input, option) =>
															(option?.label ?? '')
																.toLowerCase()
																.includes(input.toLowerCase())
														}
														showSearch
														placeholder="Выберите ФИО"
														optionFilterProp="children"
														onChange={(e) => {
															onChange(e);
															setValue(
																`plan.releases.${index}.fio`,
																e ? employee.find((el) => el.value === e)?.label : '',
															);
														}}
														value={value}
														options={employee}
														style={{ width: '100%', whiteSpace: 'nowrap' }}
														className={styles['custom-select']}
													/>
												)}
											/>
										</Td>
									</Tr>
								))}
							</>
						)}
						<Tr
							className={
								isCreationMode ? styles['create_plan_middle_block_th'] : styles['plan_middle_block_th']
							}
						>
							<Th>Возврат, м/(мхм) х шт.</Th>
							<Th>Возврат, кг</Th>
							<Th>Партия (№МК)</Th>
							<Th width={'80px'}>Подпись</Th>
							<Th whiteSpace={'nowrap'} width={'200px'}>
								ФИО получившего материал
							</Th>
						</Tr>
						<Tr
							className={
								isCreationMode ? styles['create_plan_middle_block_td'] : styles['plan_middle_block_td']
							}
						>
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="plan.return_count"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={value ? String(value.replace(/[^0-9,.хx]/g, '')) : ''}
												name="plan.return_count"
												backgroundColor="transparent"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.plan?.return_count}</Text>
								)}
							</Td>
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="plan.return_count_kg"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={value ? String(value.replace(/[^0-9,.хx]/g, '')) : ''}
												name="plan.return_count_kg"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.plan?.return_count_kg}</Text>
								)}
							</Td>
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="plan.return_number"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={value ? String(value.replace(/[^0-9,.хx]/g, '')) : ''}
												name="plan.return_number"
												backgroundColor="transparent"
											/>
										)}
									/>
								) : (
									<>{cardInfo?.plan?.return_number}</>
								)}
							</Td>
							<Td></Td>
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="plan.return_fio"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Select
												allowClear
												tabIndex={5}
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												showSearch
												placeholder="Выберите ФИО"
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													setValue(
														'plan.return_fio',
														e ? employee.find((el) => el.value === e)?.label : '',
													);
												}}
												value={value}
												options={employee}
												style={{ width: '100%', whiteSpace: 'nowrap' }}
												className={styles['custom-select']}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.plan?.return_fio}</Text>
								)}
							</Td>
						</Tr>
					</Table>
				</Flex>
			</Flex>
		</>
	);
};
