import { FC, forwardRef } from 'react';
import { FormTextareaProps } from './form-textarea.types';
import { FieldControl } from '@/shared';
import { Textarea } from '@chakra-ui-kraud/react';

export const FormTextarea: FC<FormTextareaProps> = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
	({ name, label, hideLabel, showTooltip, showErrorMessage, help, ...textareaProps }, ref) => {
		return (
			<FieldControl
				name={name}
				label={label}
				hideLabel={hideLabel}
				help={help}
				showTooltip={showTooltip}
				showErrorMessage={showErrorMessage}
			>
				<Textarea name={name} {...textareaProps} ref={ref} />
			</FieldControl>
		);
	},
);
