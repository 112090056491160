import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC } from 'react';
import { ActionsProps } from './actions.types';

export const Actions: FC<ActionsProps> = ({ onDelete, onEdit, withTemplate, onCreate, tooltip, width }) => {
	const prepareWidth = width ? `${width - 24}px` : 'none';

	return (
		<ButtonGroup style={{ width: prepareWidth }}>
			{withTemplate && (
				<Tooltip label={tooltip?.duplicate} hasArrow placement="bottom">
					<IconButton
						aria-label=""
						icon={
							<MadIcon
								module="basic"
								mode="default"
								size="default"
								type="outline"
								name="document-duplicate"
							/>
						}
						size="xs"
						variant="ghost"
						colorScheme="tertiary"
						onClick={onCreate}
					/>
				</Tooltip>
			)}

			<Tooltip label={tooltip?.edit} hasArrow placement="bottom">
				<IconButton
					aria-label=""
					icon={<MadIcon module="basic" mode="default" size="default" type="outline" name="pencil-square" />}
					size="xs"
					variant="ghost"
					colorScheme="tertiary"
					onClick={onEdit}
				/>
			</Tooltip>
			<Tooltip label={tooltip?.del} hasArrow placement="bottom">
				<IconButton
					aria-label=""
					icon={<MadIcon module="basic" mode="default" size="default" type="outline" name="trashcan" />}
					size="xs"
					variant="ghost"
					colorScheme="danger"
					onClick={onDelete}
				/>
			</Tooltip>
		</ButtonGroup>
	);
};
