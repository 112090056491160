import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel, Tooltip } from '@chakra-ui-kraud/react';
import { useFormContext } from 'react-hook-form';
import { getError } from './helpers';
import { FieldControlProps } from './field-control.types';
import { FC } from 'react';

export const FieldControl: FC<FieldControlProps> = ({
	hideLabel,
	name,
	label,
	help,
	showTooltip = false,
	showErrorMessage = false,
	children,
}) => {
	const { formState } = useFormContext();

	const error = getError(name, formState);

	return (
		<FormControl isInvalid={!!error}>
			{label && !hideLabel ? <FormLabel>{label}</FormLabel> : null}
			<Tooltip
				label={error?.message}
				placement="bottom-start"
				hasArrow={false}
				marginTop={'-6px'}
				bg="danger.300"
				isOpen={showTooltip && error}
			>
				<Box>
					{children}
					{help && !error ? <FormHelperText>{help}</FormHelperText> : null}
					{error?.message && showErrorMessage && <FormErrorMessage>{error?.message}</FormErrorMessage>}
				</Box>
			</Tooltip>
		</FormControl>
	);
};
