import { FC, useRef } from 'react';

import { INUM } from '@/shared/core';
import { useCreateNumMutation, useUpdateNumMutation } from '@/shared/state/api';
import { NumModalProps } from './num-modal.types';

import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { SideModal } from '@/shared/components';
import { NumCatalogForm } from '@/entities';

export const NumModal: FC<NumModalProps> = ({ isOpen, onClose, onBlur, title, selectedNum, variant }) => {
	const formRef = useRef<HTMLFormElement>();
	const [createNum, { isLoading: isCreateNumLoading }] = useCreateNumMutation();
	const [updateNum, { isLoading: isUpdateNumLoading }] = useUpdateNumMutation();
	const toast = useToast();

	const handleSubmit = (data: INUM) => {
		if (variant === 'create') {
			data = { ...data };
			createNum({ ...data, date_of_manufacture: data?.date_of_manufacture || null })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'НУМ создан' });
					onClose();
				})
				.catch((e) => {
					if (e.status === 469) {
						showErrorToast(toast, {
							description: 'НУМ с таким наименованием и обозначением уже существует',
						});
					} else {
						showErrorToast(toast, { description: 'При создании НУМ произошла ошибка' });
					}
				});
		}
		if (variant === 'edit') {
			updateNum({
				...data,
				date_of_manufacture: data?.date_of_manufacture,
				num: data.num ? data.num : 0,
				id: selectedNum?.id,
			})
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'НУМ отредактирован' });
					onClose();
				})
				.catch(() => {
					showErrorToast(toast, {
						description: 'При редактировании НУМ произошла ошибка',
					});
				});
		}
	};

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.submitForm();
						}}
						isDisabled={isCreateNumLoading || isUpdateNumLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить материал'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onBlur ?? onClose}
		>
			<NumCatalogForm onSubmit={handleSubmit} reference={formRef} selectedNum={selectedNum} />
		</SideModal>
	);
};
