import { TechMapResponse } from '@/shared/state/api/swagger';
import { ISGDOut, ITechCard } from '../interfaces';

export const formatCurrentOperation = (
	stepId: string | number | undefined | null,
	stepName: string | number | undefined | null,
): string => {
	if (!stepId && !stepName) return '';
	return `${stepId ? String(stepId).padStart(2, '0') : '\u2014'} — ${stepName ? stepName : '\u2014'}`;
};

export const anyFieldHaveValue = (fields: Record<string, boolean>): boolean => {
	return Object.values(fields).every((value) => !value);
};

export const selectFontColor = (
	isDisabled: boolean,
	isFocus: boolean,
	isInvalid: boolean,
	changeLabelColor: boolean,
) => {
	if (isDisabled) {
		return 'gray.300';
	}

	if (isFocus && !isInvalid) {
		return 'gray.900';
	}

	if (isInvalid && changeLabelColor) {
		return 'danger.400';
	}

	return 'gray.400';
};

export const selectArrowStyle = (propName: string | undefined): string => {
	if (!propName) return 'tooltip-arrow__left';
	const correlations: { [key: string]: string } = {
		'material.gost_na_sortament': 'tooltip-arrow__right',
		standard_consumption_meters: 'tooltip-arrow__middle',
	};
	return correlations[propName] || 'tooltip-arrow__left';
};

export const selectTooltipStyle = (propName: string | undefined): string => {
	if (!propName) return '';
	const correlations: { [key: string]: string } = {
		standard_consumption_meters: 'wide-tooltip__centered',
		symbol: 'wide-tooltip',
		name: 'wide-tooltip',
		approval_card: 'wide-tooltip',
		'material.symbol': 'wide-tooltip',
		'material.sortament': 'wide-tooltip',
		'material.gost': 'wide-tooltip',
		'material.gost_na_sortament': 'wide-tooltip',
	};
	return correlations[propName] || '';
};

export const getTechCardName = (card: ITechCard | TechMapResponse | undefined): string => {
	if (!card) return '';
	return card.symbol ? card.symbol + '-' + String(card.number) : String(card.number);
};

export const calculateAdditionalFieldsToPrint = (header: DOMRect | undefined): number => {
	if (!header) return 3;

	const pageHeight = 876;
	const fieldHieght = 20;
	const listMargin = 70;

	const { height, y } = header;

	const fullListsAmount = Math.floor(y / pageHeight);

	const listsMargin = fullListsAmount * listMargin - listMargin;

	const availableHeight = (1 + fullListsAmount) * pageHeight - listsMargin - height - y;

	const requiredFields = Math.round(availableHeight / fieldHieght);

	return requiredFields || 3;
};

export const addEmptyObjectsToArray = (arr: ISGDOut[] | undefined, num: number): void => {
	arr = arr ? arr : [];
	const additionalObjects = num - arr.length;
	if (additionalObjects > 0) {
		arr.push(...new Array(additionalObjects).fill({}));
	}
};
