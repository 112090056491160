import { FC } from 'react';
import { IconButton, IconButtonProps, useDisclosure, chakra } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { useGetCountOfUnreadQuery } from '@/shared/state/api/swagger';
import { NotificationModal } from '@/features/notification-sidebar';

type NotificationIconProps = Omit<IconButtonProps, 'icon' | 'aria-label'>;

const POLLING_INTERVAL = 1000 * 60 * 15;

export const NotificationIcon: FC<NotificationIconProps> = ({ ...props }) => {
	const { isOpen: isNotificationsOpen, onClose: onNotificationsClose, onOpen: onNotificationsOpen } = useDisclosure();
	const { data: unreadCount } = useGetCountOfUnreadQuery(undefined, { pollingInterval: POLLING_INTERVAL });

	return (
		<chakra.div position="relative">
			<IconButton
				aria-label=""
				icon={<MadIcon module="basic" size="default" mode="default" type="outline" name="bell" />}
				size="md"
				variant="ghost"
				colorScheme="tertiary"
				backgroundColor="transparent"
				padding="0 12px"
				onClick={onNotificationsOpen}
				{...props}
			></IconButton>
			{unreadCount ? (
				<chakra.div position="absolute" top={0} right={1} color="danger.400" fontSize="xs">
					{unreadCount}
				</chakra.div>
			) : null}

			{isNotificationsOpen && <NotificationModal isOpen={isNotificationsOpen} onClose={onNotificationsClose} />}
		</chakra.div>
	);
};
