import { GetIssuesApiArg, IssueReportSortingFields } from '@/shared/state/api/swagger';
import { TablePaginationConfig } from 'antd';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useCallback, useState } from 'react';

export const useIssuesTableParams = ({ pageSize = 32, currentPage = 1, ...props }: GetIssuesApiArg) => {
	const [queryParams, setQueryParams] = useState<GetIssuesApiArg>({
		pageSize,
		currentPage,
		...props,
	});

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Partial<IssueReportSortingFields>> | SorterResult<Partial<IssueReportSortingFields>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				orderBy: sortedBy as IssueReportSortingFields,
				orderDir: preparedSortDir,
			}));
		},
		[],
	);

	const handlePageChange = useCallback((page: number, pageSize: number) => {
		setQueryParams((prev) => ({
			...prev,
			currentPage: page,
			pageSize: pageSize,
		}));
	}, []);

	const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((prev) => ({
			...prev,
			stringToSearch: e.target.value.trim() || undefined,
			currentPage: 1,
		}));
	}, []);

	const handleResetTableFilters = useCallback(() => {
		setQueryParams((prev) => ({ ...prev, currentPage: 1 }));
	}, []);

	return {
		handleResetTableFilters,
		handleTableChange,
		handlePageChange,
		handleSearch,
		setQueryParams,
		queryParams,
	};
};
