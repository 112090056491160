import { EngineerValidateSuccess, Stub } from '@/shared';
import { Button, chakra } from '@chakra-ui-kraud/react';
import { OperationAlertProps } from './operation-alert.types';

interface TransferToWarehouseProps extends OperationAlertProps {
	handleTransferToWarehouse: () => void;
}

export const TransferToWarehouse = ({ handleTransferToWarehouse }: TransferToWarehouseProps) => {
	return (
		<Stub
			icon={<EngineerValidateSuccess />}
			header="Производственные работы завершены"
			label={<p>Самое время передать маршрутную карту и детали на склад</p>}
			footer={
				<chakra.div width="100%">
					<Button variant="solid" width="100%" onClick={handleTransferToWarehouse}>
						Передать на склад
					</Button>
				</chakra.div>
			}
		/>
	);
};
