import { FC } from 'react';
import { IssueColumnIdProps, IssueStatusColumnProps } from '@/widgets/issue-table/model/column.types';
import { useGetIssueStatusMappingQuery, useGetIssueTypeMappingQuery } from '@/shared/state/api/swagger';
import { Flex } from '@chakra-ui-kraud/react';
import styles from './issues-table.module.scss';
import { CellTableParagraph } from '@/shared';
import { IssueBadge } from '@/entities/issue';
import { useIssueModalHook } from '@/features/issue-form';

export const IssueColumnId: FC<IssueColumnIdProps> = (props) => {
	const { id, type } = props;
	const { data = {} } = useGetIssueTypeMappingQuery();

	const { onOpen } = useIssueModalHook();

	return (
		<>
			<Flex gap={3} className={styles['link']} as="a" onClick={() => onOpen(id, type)}>
				<CellTableParagraph value={id} />
				{/* TODO remove any after fix from backend */}
				<CellTableParagraph value={(data as any)[type]} />
			</Flex>
		</>
	);
};

export const IssueStatusColumn: FC<IssueStatusColumnProps> = ({ status }) => {
	const { data = {} } = useGetIssueStatusMappingQuery();

	return (
		<IssueBadge value={status}>
			{/* TODO remove any after fix from backend */}
			<CellTableParagraph value={(data as any)[status]} />
		</IssueBadge>
	);
};
