import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex, MutexInterface } from 'async-mutex';
import { RootStateType } from '@/shared/state/store';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { resetAction } from '@/shared/state/actions';
import { ILoginResponse } from '@/shared/core';
import { API_URL } from '@/shared/constants';
import { setAccessToken, setRefreshToken } from '../slices';

const BASE_URL = API_URL;

export const tagList = [
	'Auth',
	'TechCards',
	'Materials',
	'Products',
	'Nums',
	'Plan',
	'Operation',
	'SGDIn',
	'SGDOut',
	'Users',
	'Employee',
	'TechMapSymbolCatalogy',
];

const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		headers.set('X-Requested-With', 'XMLHttpRequest');

		const token = (getState() as RootStateType).auth.accessToken;
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	},
});

const mutex: MutexInterface = new Mutex();

export const authCheckQueryWithCustomError: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	api,
	extraOptions,
) => {
	await mutex.waitForUnlock();
	let result = await baseQuery(args, api, extraOptions);
	if (result.error?.status === 401) {
		if (!mutex.isLocked()) {
			const release = await mutex.acquire();
			try {
				if ((api.getState() as RootStateType).auth.refreshToken) {
					const refreshResult = await baseQuery(
						{
							method: 'POST',
							credentials: 'include',
							url: '/refresh-token',
							body: {
								refresh_token: (api.getState() as RootStateType).auth.refreshToken,
							},
						},
						api,
						extraOptions,
					);

					if (refreshResult.data) {
						api.dispatch(setAccessToken((refreshResult.data as ILoginResponse).access_token));
						api.dispatch(setRefreshToken((refreshResult.data as ILoginResponse).refresh_token));
						result = await baseQuery(args, api, extraOptions);
					} else {
						api.dispatch(resetAction());
					}
				}
			} finally {
				release();
			}
		} else {
			await mutex.waitForUnlock();
			result = await baseQuery(args, api, extraOptions);
		}
	}
	return result;
};

export const api = createApi({
	baseQuery: authCheckQueryWithCustomError,
	tagTypes: tagList,
	endpoints: () => ({}),
});
