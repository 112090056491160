import { IssueReportStatusEnum } from '@/shared/state/api/swagger';
import { colors } from '@/shared';
import { Badge } from '@chakra-ui-kraud/react';
import { IssueBadgeProps } from '../model/badges.types';
import { FC } from 'react';

const IssueStatusColor: Record<IssueReportStatusEnum, string> = {
	open: colors.special.tag.orange,
	progress: colors.special.tag.blue,
	closed: colors.special.tag.green,
};

export const IssueBadge: FC<IssueBadgeProps> = ({ value, children, ...props }) => {
	return (
		<Badge
			variant={'solid'}
			background={value && IssueStatusColor[value]}
			textTransform="initial"
			fontFamily="Raleway"
			size="xl"
			{...props}
		>
			{children}
		</Badge>
	);
};
