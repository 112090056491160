import { TOAST_DURATION } from '@/shared/constants';
import type { ToastProps } from './toasts.types';

export const showErrorToast: ToastProps = (toast, options) =>
	toast({
		status: 'error',
		colorScheme: 'danger',
		title: 'Ошибка',
		isClosable: true,
		variant: 'left-accent',
		duration: TOAST_DURATION,
		position: 'top-left',
		...options,
	});
