import { type ObjectSchema } from 'yup';
import * as yup from 'yup';
import { IssueReportUpdateStatusToClosedRequest } from '@/shared/state/api/swagger';

const defaultMsg = 'Заполните это поле, оно обязательное';

export const solveIssueFormSchema: ObjectSchema<IssueReportUpdateStatusToClosedRequest> = yup.object({
	responsible_user_id: yup.number().required(defaultMsg),
	comment: yup.string().required(defaultMsg),
	problem_operation_id: yup.number().required(defaultMsg),
});
