import { FC } from 'react';
import { CellTableParagraph } from '@/shared';
import { UserColumnProps } from '@/widgets/users-table';
import { useGetUserRolesMappingQuery } from '@/shared/state/api/swagger';

export const UserRoleColumn: FC<UserColumnProps> = ({ role }) => {
	const { data } = useGetUserRolesMappingQuery();

	return <CellTableParagraph value={data ? data[role] : ''} minWidth={99} maxWidth={window.innerWidth / 3} />;
};
