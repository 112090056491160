import type { FC } from 'react';
import type { HeaderProps } from './header.types';

import styles from './header.module.scss';
import clsx from 'clsx';

export const Header: FC<HeaderProps> = ({
	backArrow,
	title,
	headerLeftButton,
	headerRightButton,
	search,
	filters,
	className,
}) => {
	return (
		<header className={clsx(styles['header'], className)}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
				{backArrow}
				{title}
				{headerLeftButton}
			</div>
			<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
				{headerRightButton}
				{search}
				{filters}
			</div>
		</header>
	);
};
