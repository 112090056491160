import { api } from './api';
import {
	ILinkExistedTechCardData,
	ILinkNewTechCardData,
	IQueryParams,
	ISymbolTechCard,
	ITechCard,
	ITechCardInProcessResponse,
	ITechCardListRequest,
	ITechCardListResponse,
	ITechCardTreeData,
	TechCardRequest,
	TechCardStatus,
} from '@/shared/core';
import queryString from 'query-string';

export const techCardsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTechCards: builder.query<ITechCardListResponse, ITechCardListRequest>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/tech_map${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['TechCards'],
		}),
		getTechCardsInProcess: builder.query<ITechCardInProcessResponse[], void>({
			query() {
				return {
					url: '/tech_map/in_progress/',
					method: 'GET',
					credentials: 'include',
				};
			},
			// providesTags: ['TechCards'],
		}),
		getSymbolsTechCards: builder.query<ISymbolTechCard[], void>({
			query() {
				return {
					url: '/tech_map_symbol_catalog',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['SymbolsTechCards'],
		}),
		changeStatus: builder.mutation<void, { status: TechCardStatus; id: number }>({
			query(body) {
				return {
					url: '/tech_map/change_status',
					method: 'POST',
					body: { tech_map_id: body.id, status: body.status },
					credentials: 'include',
				};
			},
			invalidatesTags: ['TechCards'],
		}),
		getTechCardById: builder.query<ITechCard, { id: number }>({
			query(params) {
				return {
					url: `/tech_map/${params.id}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			transformResponse: (data: ITechCard) => {
				const preparedOperations = data.operations?.sort((a, b) => a.step_id - b.step_id);
				return { ...data, operations: preparedOperations };
			},
			providesTags: ['TechCards'],
		}),
		getTechCardByNumber: builder.query<ITechCard, { number: number }>({
			query(params) {
				return {
					url: `/tech_map/number/${params.number}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			transformResponse: (data: ITechCard) => {
				const preparedOperations = data.operations?.sort((a, b) => a.step_id - b.step_id);
				return { ...data, operations: preparedOperations };
			},
			providesTags: ['TechCards'],
		}),
		createTechCard: builder.mutation<ITechCard, TechCardRequest>({
			query(body) {
				return {
					url: '/tech_map',
					method: 'POST',
					body: body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['TechCards'],
		}),
		updateTechCard: builder.mutation<Partial<ITechCard>, TechCardRequest>({
			query(body) {
				return {
					url: '/tech_map',
					method: 'PUT',
					body: body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['TechCards'],
		}),
		getExcel: builder.mutation<Blob, { id: number }>({
			query(params) {
				return {
					url: `/tech_map/get_excel/${params.id}`,
					method: 'GET',
					credentials: 'include',
					responseHandler: (response: { blob: () => any }) => response.blob(),
				};
			},
			invalidatesTags: ['TechCards'],
		}),
		getTechCardsExcel: builder.mutation<Blob, ITechCardListRequest>({
			query(params) {
				const searchParams = queryString.stringify(params);

				return {
					url: `/tech_map/get_excel/list/${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
					responseHandler: (response: { blob: () => any }) => response.blob(),
				};
			},
		}),
		resetTechMap: builder.mutation<void, { status: TechCardStatus; tech_map_id: number }>({
			query(body) {
				return {
					url: '/admin/tech_map/cancel',
					method: 'POST',
					body: body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['TechCards'],
		}),
		linkWithNewTechCard: builder.mutation<ITechCard, ILinkNewTechCardData>({
			query(body) {
				return {
					url: '/tech_map/linked/new',
					method: 'POST',
					body: body,
					credentials: 'include',
				};
			},
			// invalidatesTags: ['TechCards'],
		}),
		linkWithExistedTechCard: builder.mutation<ITechCard, ILinkExistedTechCardData>({
			query(body) {
				return {
					url: '/tech_map/linked/existed',
					method: 'POST',
					body: body,
					credentials: 'include',
				};
			},
			// invalidatesTags: ['TechCards'],
		}),
		getTechCardTreeById: builder.query<ITechCardTreeData[], { tech_map_number: number }>({
			query(params) {
				return {
					url: `tech_map/get_graph/number/${params.tech_map_number}`,
					method: 'GET',
					credentials: 'include',
				};
			},
		}),
	}),
});

export const {
	useGetTechCardsQuery,
	useLazyGetTechCardsQuery,
	useGetTechCardsInProcessQuery,
	useGetSymbolsTechCardsQuery,
	useChangeStatusMutation,
	useLazyGetTechCardByIdQuery,
	useGetTechCardByNumberQuery,
	useCreateTechCardMutation,
	useUpdateTechCardMutation,
	useGetExcelMutation,
	useGetTechCardsExcelMutation,
	useResetTechMapMutation,
	useLinkWithNewTechCardMutation,
	useLinkWithExistedTechCardMutation,
	useGetTechCardTreeByIdQuery,
} = techCardsApi;
