import { FormControl, FormLabel, Switch } from '@chakra-ui-kraud/react';
import { setFiredFilter } from '../model/user-filters-reducer';
import { UsersFiredSwitchProps } from '../model/users-fired-switch.types';

export const UsersFiredSwitch = ({ dispatch }: UsersFiredSwitchProps) => {
	return (
		<FormControl display="flex" justifyContent="end">
			<FormLabel htmlFor="current-user" mb="0" fontFamily="Raleway" fontSize="sm">
				Отображать уволенных
			</FormLabel>
			<Switch
				id="current-user"
				onChange={(e) => {
					setFiredFilter(dispatch, e.target.checked);
				}}
			/>
		</FormControl>
	);
};
