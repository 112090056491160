import type { FC } from 'react';

import { UsersTable } from '@/widgets/users-table';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui-kraud/react';
import { Layout, UserModal } from '@/widgets';
import { EmployeeModal } from '@/widgets/employee-modal';

export const Users: FC = () => {
	const { isOpen: isCreateUserOpen, onOpen: onCreateUserOpen, onClose: onUserClose } = useDisclosure();
	const { isOpen: isCreateExecutorOpen, onOpen: onCreateExecutorOpen, onClose: onExecutorClose } = useDisclosure();

	return (
		<>
			<Layout
				title={
					<Text fontSize="lg" lineHeight={7} fontWeight="semibold">
						Справочник пользователей
					</Text>
				}
				headerLeftButton={
					<Menu>
						<MenuButton as={Button} variant="ghost" rightIcon={<ChevronDownIcon />}>
							Создать
						</MenuButton>
						<MenuList p={2} zIndex="1000" fontWeight="500" minW="190" borderRadius="base">
							<MenuItem py={2} px={4} onClick={onCreateExecutorOpen}>
								Сотрудника
							</MenuItem>
							<MenuItem py={2} px={4} onClick={onCreateUserOpen}>
								Пользователя
							</MenuItem>
						</MenuList>
					</Menu>
				}
			>
				<UsersTable />
				<UserModal
					variant="create"
					isOpen={isCreateUserOpen}
					onClose={onUserClose}
					title="Создание пользователя"
				/>
				<EmployeeModal variant="create" isOpen={isCreateExecutorOpen} onClose={onExecutorClose} />
			</Layout>
		</>
	);
};
