import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { DeleteModalProps } from './delete-modal.types';

export const DeleteModal: FC<DeleteModalProps> = ({ isOpen, onClose, onDelete, text, title }) => {
	return (
		<Modal isOpen={isOpen} isCentered onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{text}</ModalBody>
				<ModalFooter>
					<Button variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Оставить
					</Button>
					<Button variant={'solid'} mr={3} colorScheme="danger" onClick={onDelete}>
						Удалить
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
