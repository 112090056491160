import { FC, useMemo } from 'react';
import ReactFlow, { Node, Edge, Background } from 'reactflow';
import dagre from 'dagre';

import { LinkSchemaSideModalProps } from './link-schema-side-modal.types';

import { TechCardTreeElement } from '@/entities';
import { useTransformTechCardTreeData } from '@/shared';
import { SideModal } from '@/shared/components';

import 'reactflow/dist/style.css';
import './link-schema-side-modal.scss';
import { chakra, Spinner } from '@chakra-ui-kraud/react';

export const LinkSchemaSideModal: FC<LinkSchemaSideModalProps> = ({ isOpen, onClose, onBlur, title, cardNumber }) => {
	const nodeTypes = useMemo(() => ({ reactFlowNodeType: TechCardTreeElement }), []);
	// получаем готовые ноды и связи из хука + loader
	const { edges, nodes, isLoading } = useTransformTechCardTreeData(cardNumber);

	// создаем граф (он нужен для генерации связий по координатам, чтобы не задавать логику вручную)
	const dagreGraph = new dagre.graphlib.Graph();

	dagreGraph.setDefaultEdgeLabel(() => ({}));

	// ширина и высота ноды
	const nodeWidth = 172;
	const nodeHeight = 36;

	// https://reactflow.dev/examples/layout/dagre
	const getLayoutedElements = (nodes?: Node[], edges?: Edge[]) => {
		// лэйаут top-bottom (вертикальный)
		dagreGraph.setGraph({ rankdir: 'TB' });

		if (nodes && nodes.length && edges && edges?.length) {
			nodes.forEach((node) => {
				dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
			});

			edges.forEach((edge) => {
				dagreGraph.setEdge(edge.source, edge.target);
			});

			dagre.layout(dagreGraph);

			nodes.forEach((node) => {
				const nodeWithPosition = dagreGraph.node(node.id);

				// тип для ноды - кастомный компонент TechCardElement
				node.type = 'reactFlowNodeType';

				node.data = {
					...node.data,
					// отображаем сверху узел (точку) только если в массиве связей (Edges) есть такой source
					hasChildren: edges.find((item) => item.source === node.id),
					// отображаем снизу узел (точку) только если в массиве связей (Edges) есть такой target
					hasParent: edges.find((item) => item.target === node.id),
					// если у текущей ноды, label (номер карты) такой же как номер текущей карты - красим ноду в primary цвет
					currentTechCard: node.data.label === cardNumber,
					// номер каждой ноды - это номер карты
					cardNumber: node.data.label,
				};

				node.position = {
					x: nodeWithPosition.x - nodeWidth / 2,
					y: nodeWithPosition.y - nodeHeight / 2,
				};

				return node;
			});
		}

		return { nodes, edges };
	};

	const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			header={null}
			footer={null}
			onBlur={onBlur ?? onClose}
		>
			<div style={{ width: '100%', height: '100%' }}>
				{!isLoading ? (
					layoutedEdges && layoutedNodes?.length ? (
						<ReactFlow
							nodeTypes={nodeTypes}
							nodes={layoutedNodes}
							edges={layoutedEdges}
							maxZoom={1}
							minZoom={0}
							snapToGrid={false}
							preventScrolling={false}
							fitView
							draggable={false}
							panOnDrag={false}
						>
							<Background color="#aaa" gap={16} />
						</ReactFlow>
					) : (
						<>В карте №{cardNumber} еще не созданы связи</>
					)
				) : (
					<chakra.div w="100%" display="flex" justifyContent="center" alignItems="center">
						<Spinner />
					</chakra.div>
				)}
			</div>
		</SideModal>
	);
};
