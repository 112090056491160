/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlexboxProps, ResponsiveValue, Tooltip } from '@chakra-ui-kraud/react';
import { useRef, useState } from 'react';
import styles from './cell-table-paragraph.module.scss';

export const CellTableParagraph = ({
	value,
	minWidth,
	maxWidth,
	textAlign = 'left',
}: {
	value: string | number | undefined;
	minWidth?: number;
	maxWidth?: number;
	textAlign?: string;
}): JSX.Element => {
	const result = value ? value : '\u2014';

	const pstyle = {
		minWidth: minWidth ? `${minWidth - 24}px` : 'none',
		maxWidth: maxWidth ? `${maxWidth - 24}px` : 'none',
		textAlign: textAlign,
	};

	const mRef = useRef<HTMLDivElement | null>(null);
	const [isHover, setHover] = useState<boolean>(false);
	const isEllipsisActive = () => {
		//@ts-ignore
		return mRef?.current?.offsetWidth < mRef?.current?.scrollWidth;
	};

	return (
		<Tooltip
			isOpen={isEllipsisActive() && isHover}
			label={value}
			placement="bottom-start"
			bottom={'4px'}
			maxW={'fit-content'}
		>
			<p
				ref={mRef}
				className={styles.cellhoc}
				//@ts-ignore
				style={pstyle}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				{result}
			</p>
		</Tooltip>
	);
};
