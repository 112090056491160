import { FC, useRef, useState } from 'react';

import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import {
	Button,
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	chakra,
	TextHeader,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { EmployeeModalProps } from './employee-modal.types';
import { EmployeeForm } from '@/features/employee-form/employee-form';
import {
	useActivateUserMutation,
	useCreateUserExecutorMutation,
	useUpdateUserMutation,
} from '@/shared/state/api/swagger';
import { ActivateEmployeeForm } from '@/features/employee-form/activate-employee-form';
import { abbreviateUser, ACTIVATE_USER_CACHE_KEY } from '@/shared';

export const EmployeeModal: FC<EmployeeModalProps> = ({ isOpen, onClose, selectedEmployee, variant }) => {
	const formRef = useRef<HTMLFormElement>(null);
	const toast = useToast();

	const [createUser, { isLoading: isCreateEmployeeLoading }] = useCreateUserExecutorMutation();
	const [updateUser, { isLoading: isUpdateEmployeeLoading }] = useUpdateUserMutation();
	const [, { isLoading: isActivateLoading }] = useActivateUserMutation({ fixedCacheKey: ACTIVATE_USER_CACHE_KEY });
	const { first_name, last_name, middle_name, position } = selectedEmployee ?? {};
	const fio = abbreviateUser(first_name ?? '', last_name ?? '', middle_name ?? '');

	const handleSubmit = (data: any) => {
		if (variant === 'create') {
			createUser({ userExecutorCreateRequest: { ...data, username: data.email } })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Сотрудник создан' });
					onClose();
				})
				.catch(() => {
					showErrorToast(toast, {
						description: 'При создании сотрудника произошла ошибка',
					});
				});
		}
		if (variant === 'edit') {
			updateUser({ userUpdateAdminRequest: { ...data, username: data.email } })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Сотрудник отредактирован' });
					onClose();
				})
				.catch(() => {
					showErrorToast(toast, {
						description: 'При редактировании сотрудника произошла ошибка',
					});
				});
		}
	};

	const [isActivateEmployeeModalOpen, setActivateModalOpen] = useState(false);

	return (
		<Modal variant="side" size="xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					{isActivateEmployeeModalOpen ? (
						<Flex alignItems="center" gap={2}>
							<IconButton
								aria-label=""
								icon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-left"
									/>
								}
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								backgroundColor="transparent"
								padding="0 12px"
								onClick={() => {
									setActivateModalOpen(false);
								}}
							/>
							<TextHeader fontWeight={'semibold'} variant="h5">
								Активация сотрудника
							</TextHeader>
						</Flex>
					) : variant === 'create' ? (
						'Создание сотрудника'
					) : (
						'Редактирование сотрудника'
					)}
				</ModalHeader>
				<ModalBody>
					{isActivateEmployeeModalOpen ? (
						<>
							<Stack gap={1} pb={5}>
								<TextHeader fontWeight={'semibold'} variant="h5">
									{fio}
								</TextHeader>{' '}
								<chakra.p fontSize={'xs'} mt="0px !important" fontWeight={'normal'} color={'gray.500'}>
									{position}
								</chakra.p>
							</Stack>
							<ActivateEmployeeForm ref={formRef} user={selectedEmployee!} onResult={onClose} />
						</>
					) : (
						<>
							<EmployeeForm ref={formRef} selectedEmployee={selectedEmployee} onSubmit={handleSubmit} />
							{variant === 'edit' && (
								<Button
									marginTop={'12px'}
									size="md"
									variant="outline"
									width="100%"
									colorScheme="tertiary"
									onClick={() => {
										setActivateModalOpen(true);
									}}
								>
									Активировать учетную запись
								</Button>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.requestSubmit();
						}}
						isDisabled={isCreateEmployeeLoading || isUpdateEmployeeLoading || isActivateLoading}
					>
						{isActivateEmployeeModalOpen
							? 'Активировать'
							: variant === 'create'
							? 'Cоздать сотрудника'
							: 'Сохранить'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
