import { EngineerValidateQuestionDocument, Stub } from '@/shared';

export const RoutemapMissedAlert = () => {
	return (
		<Stub
			icon={<EngineerValidateQuestionDocument />}
			header="Такой карты не существует"
			label={<p>Вероятно она была удалена из системы</p>}
		/>
	);
};
