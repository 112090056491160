import { FormDatepickerProps } from './form-datepicker.types';
import { CalendarSinglePicker, FieldControl } from '@/shared';
import { FC } from 'react';

export const FormDatepicker: FC<FormDatepickerProps> = ({
	name,
	label,
	hideLabel,
	showTooltip,
	showErrorMessage,
	help,
	inputLabel,
	...props
}) => {
	return (
		<FieldControl
			name={name}
			label={label}
			hideLabel={hideLabel}
			help={help}
			showTooltip={showTooltip}
			showErrorMessage={showErrorMessage}
		>
			<CalendarSinglePicker label={inputLabel} {...props} />
		</FieldControl>
	);
};
