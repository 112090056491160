import React, { FC, useEffect, useRef, useState } from 'react';

import { IPopoverTechCardProps } from './popover-techcard.types';

import { Box, Button, Flex, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui-kraud/react';
import { KeyboardIcon, LocalStorageKeys, useLocalStorage } from '@/shared';
import { CloseIcon } from '@chakra-ui/icons';
import { emulateTab } from 'emulate-tab';

export const PopoverTechCard: FC<IPopoverTechCardProps> = ({ isCreationMode, isInitialEditMode, isEditMode }) => {
	const isMacOS = window.navigator.userAgent.includes('Mac');

	const [isFirstEnter, setIsFirstEnter] = useLocalStorage<boolean>(LocalStorageKeys.IS_USER_FIRST_ENTER, true);

	const [isShortcutsOpen, setIsShorcutsOpen] = useState((isCreationMode || isInitialEditMode) && isFirstEnter);

	const lastFocused = useRef<{ element: null | Element }>({ element: null });

	useEffect(() => {
		const cb = () => {
			const tagName = document.activeElement?.tagName;
			if (tagName === 'SELECT' || tagName === 'INPUT') {
				lastFocused.current.element = document.activeElement;
			}
		};
		document.addEventListener('focusin', cb);
		return () => document.removeEventListener('focusin', cb);
	}, []);

	useEffect(() => {
		if (!isFirstEnter && isEditMode) {
			emulateTab();
		}
	}, [isEditMode]);

	return (
		<Popover
			closeOnBlur={true}
			isOpen={isShortcutsOpen}
			offset={[-180, 0]}
			returnFocusOnClose={false}
			onClose={() => {
				setIsShorcutsOpen(false);
				if (isFirstEnter) setIsFirstEnter(false);
				setTimeout(() => {
					if (!lastFocused.current?.element) {
						emulateTab();
					} else {
						(lastFocused.current.element as HTMLInputElement).focus();
					}
				}, 200);
			}}
		>
			<PopoverTrigger>
				<Button
					onClick={() => setIsShorcutsOpen(true)}
					position={'fixed'}
					borderRadius={'6px'}
					tabIndex={-1}
					bottom={'20px'}
					zIndex={100}
					padding={'10px 9px'}
					colorScheme={'tertiary'}
					right={'30px'}
					// скрываем кнопку хоткеев, если не включен режим редактирования
					visibility={!isEditMode && !isShortcutsOpen ? 'hidden' : 'visible'}
				>
					<KeyboardIcon width={20} height={20} />
				</Button>
			</PopoverTrigger>
			<PopoverContent
				onKeyDown={(e) => {
					if (e.key === 'Tab') {
						e.stopPropagation();
						e.preventDefault();
						if (lastFocused.current.element as HTMLInputElement) {
							(lastFocused.current.element as HTMLInputElement).focus();
						} else {
							emulateTab();
						}
					}
				}}
				tabIndex={-1}
				backgroundColor={'rgba(0, 0, 0, 0.84)'}
				minWidth={'400px'}
				color="white"
			>
				<PopoverBody tabIndex={-1}>
					<Flex direction={'column'} padding={'16px'}>
						<Flex justifyContent={'space-between'} marginBottom={'16px'} alignItems={'center'}>
							<p
								style={{
									fontSize: '18px',
									fontWeight: '600',
									lineHeight: '28px',
								}}
							>
								Горячие клавиши
							</p>
							<Box
								padding={'10px'}
								cursor={'pointer'}
								onClick={() => {
									setIsShorcutsOpen(false);
									if (isFirstEnter) setIsFirstEnter(false);
									setTimeout(() => {
										if (!lastFocused.current?.element) {
											emulateTab();
										} else {
											(lastFocused.current.element as HTMLInputElement).focus();
										}
									}, 200);
								}}
							>
								<CloseIcon width={'10px'} height={'10px'} />
							</Box>
						</Flex>
						<Flex justifyContent={'space-between'} marginBottom={'8px'}>
							<p>Переход на следующее поле</p>
							<Box backgroundColor={'#E2E8F0'} color={'#2D3748'} borderRadius={'6px'} padding={'2px 4px'}>
								Tab
							</Box>
						</Flex>
						<Flex justifyContent={'space-between'} marginBottom={'8px'}>
							<p>Переход на предыдущее поле</p>
							<Box backgroundColor={'#E2E8F0'} color={'#2D3748'} borderRadius={'6px'} padding={'2px 4px'}>
								{!isMacOS ? 'Shift + Tab' : '⇧Tab'}
							</Box>
						</Flex>
						<Flex justifyContent={'space-between'} marginBottom={'8px'}>
							<p>Отмена последнего ввода</p>
							<Box backgroundColor={'#E2E8F0'} color={'#2D3748'} borderRadius={'6px'} padding={'2px 4px'}>
								{!isMacOS ? 'Ctrl + Z' : '⌘Z'}
							</Box>
						</Flex>
						<Flex justifyContent={'space-between'} marginBottom={'8px'}>
							<p>Отмена последней отмены ввода</p>
							<Box backgroundColor={'#E2E8F0'} color={'#2D3748'} borderRadius={'6px'} padding={'2px 4px'}>
								{!isMacOS ? 'Ctrl + Shift + Z' : '⌘⇧Z'}
							</Box>
						</Flex>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
