import {
	createLinkWithCurrentTechCardSchema,
	createLinkWithNewTechCardSchema,
	defaultValueLinkCurrentTechCard,
	defaultValueLinkNewTechCard,
} from '@/entities/forms/link-techcard-form/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	IEmployeeInitials,
	ILinkExistedTechCardData,
	ILinkNewTechCardData,
	IProduct,
	IProductOperation,
	ITechCard,
	ITechCardInProcessResponse,
} from '../core';
import { useGetDefaultOperationsQuery, useGetTechCardsInProcessQuery } from '../state/api';

export interface ILinkTechCardData {
	cardInfo?: ITechCard;
	products: IProduct[];
	employee?: IEmployeeInitials[];
	variant: 'create' | 'edit';
}

interface OperationOption extends DefaultOptionType {
	is_default: boolean;
}

export const usePrepareDataForLinkTechCard = (data: ILinkTechCardData) => {
	const { cardInfo, products, employee, variant } = data;

	const { control, handleSubmit, setValue } = useForm<ILinkNewTechCardData | ILinkExistedTechCardData>({
		// исходя их варианта (создание новой МК или связь с существующей) выбираем схему и дефолтные значения
		defaultValues:
			variant === 'create'
				? {
						...defaultValueLinkNewTechCard,
						parent_tech_map_id: cardInfo?.id,
						// берем данные из текущей операции current_operation в cardInfo
						parent_operation_id: cardInfo?.current_operation?.id,
						count_gram: cardInfo?.current_operation?.count_in_gram ?? undefined,
						count_number: cardInfo?.current_operation?.count_in_number ?? undefined,
				  }
				: {
						...defaultValueLinkCurrentTechCard,
						parent_tech_map_id: cardInfo?.id,
						// берем данные из текущей операции current_operation в cardInfo
						count_gram: cardInfo?.current_operation?.count_in_gram ?? undefined,
						count_number: cardInfo?.current_operation?.count_in_number ?? undefined,
				  },
		resolver: yupResolver(
			variant === 'create' ? createLinkWithNewTechCardSchema : createLinkWithCurrentTechCardSchema,
		),
	});

	// техкарты со статусом "В работе" (для селекта "Выберите номер МК" при связи с существующей картой)
	const { data: TechCardsInProcessData, isLoading: isTechCardsInProcessLoading } = useGetTechCardsInProcessQuery();
	// дефолтные операции (для селекта "Куда" при связи с новой МК)
	const { data: defaultOperations, isLoading: isDefaultOperationsLoading } = useGetDefaultOperationsQuery();

	// стейт с операциями выбранного product (обозначения для новой МК)
	const [selectedProductOperations, setSelectedProductOperations] = useState<OperationOption[]>([]);
	// стейт с техкартами, которые в статусе "В работе", для селекта "Введите номер МК"
	const [techCardsInProcess, setTechCardsInProcess] = useState<DefaultOptionType[]>([]);

	// подготовка списка операций для селекта "Обозначение новой МК"
	const prepareProductSelect = useMemo(() => {
		const prepareProducts: DefaultOptionType[] | undefined = [];

		if (products?.length) {
			products.forEach((item, index) => {
				prepareProducts.push({ label: `${item.symbol} (${item.name})`, value: item.id });
			});
		}

		return prepareProducts;
	}, [products]);

	// подготовка списка ФИО для селекта "Выберите ответственного"
	const employeeForSelect = useMemo(() => {
		if (employee?.length) {
			return employee?.map((el) => ({
				value: el.initials,
				label: el.initials,
			}));
		} else return [];
	}, [employee]);

	// конвертируем полученные карты в тип данных для селекта "Введите номер МК"
	const convertTechCardsDataToSelectOptions = useCallback(
		(serverData: ITechCard[] | ITechCardInProcessResponse[]) => {
			const result: DefaultOptionType[] = [];
			serverData
				.filter((item) => item.id !== cardInfo?.id)
				.map((techCard, index) => {
					result.push({ label: techCard.number, value: techCard.id });
				});
			return result;
		},
		[],
	);

	// составляем список операций для селекта "куда" (только при создании связи с новой МК)
	const handleSelectData = useCallback(
		(value: number) => {
			const prepareData: OperationOption[] = [];

			// Select в AntDesign по-другому не хочет перерисовывать список операций
			setSelectedProductOperations([]);

			// при создании связи с новой МК, после выбора обозначения - формируем список операций для этого обозначения
			// (в материале всегда есть хотя бы одна операция)
			setValue('children_operation_type_id', null);
			setValue('children_default_operation_id', null);
			const selectedProductOperations = products?.find((product) => product.id === value)?.operation_types;

			// обычные операции
			if (selectedProductOperations?.length) {
				// сортируем операции по step_id
				const sortedOperations = [...selectedProductOperations].sort(
					(a: IProductOperation, b: IProductOperation) => Number(a?.step_id) - Number(b?.step_id),
				);

				sortedOperations.forEach((operation) => {
					prepareData.push({
						// label будет уникален
						label: `${operation.step_id} - ${operation.name}`,
						value: operation.operation_type_id,
						is_default: false,
					});
				});
			}

			// дефолтные операции
			if (defaultOperations && !isDefaultOperationsLoading && selectedProductOperations) {
				const lastStep = selectedProductOperations?.length * 5 + 5;

				defaultOperations.forEach((default_operation, index) => {
					prepareData.push({
						// label будет уникален
						label: `${lastStep + index * 5} - ${default_operation.name}`,
						value: default_operation.id,
						is_default: true,
					});
				});
			}

			// кидаем операции выбранного product в стейт
			// Select в AntDesign по-другому не хочет перерисовывать список операций
			setTimeout(() => {
				setSelectedProductOperations(prepareData);
			}, 100);
		},
		[products, defaultOperations, isDefaultOperationsLoading],
	);

	// выбор операции (дефолтной или обычной)
	const handleSetOperationValue = useCallback(
		(value: string) => {
			const selectedValue = selectedProductOperations.find((item) => item.label === value);

			const trimSelectedValue = (str: string) => {
				const step_id = str.split('-')[0].trimEnd();
				const operation_name = str.split('-')[1].trimStart();

				// hotfix
				return { step_id, operation_name };
				// return str.split('-')[1].trimStart();
			};

			// если это дефолтная операция, отправляем в бэк её id (в свойство children_default_operation_id)
			if (selectedValue?.is_default) {
				// перед этим очищаем значение для обычных операций
				setValue('children_operation_type_id', null);
				setValue('children_default_operation_id', Number(selectedValue?.value));
				// hotfix
				setValue('children_operation_step_id', Number(trimSelectedValue(String(selectedValue?.label)).step_id));
			} else {
				// если это обычная операция, отправляем в бэк её id (в свойство children_operation_type_id)
				// перед этим очищаем значение для дефольных операций
				setValue('children_default_operation_id', null);
				setValue('children_operation_type_id', Number(selectedValue?.value));
				// hotfix
				setValue('children_operation_step_id', Number(trimSelectedValue(String(selectedValue?.label)).step_id));
			}
		},
		[selectedProductOperations],
	);

	// заполняем селект "Выберите номер МК" при создании связи с существующей картой
	useEffect(() => {
		if (!isTechCardsInProcessLoading && TechCardsInProcessData)
			TechCardsInProcessData.length &&
				setTechCardsInProcess([...convertTechCardsDataToSelectOptions(TechCardsInProcessData)]);
	}, [isTechCardsInProcessLoading]);

	return {
		// детали (обозначения) для новой МК
		prepareProductSelect,
		// операции из выбранного материала (обозначения) для новой МК
		selectedProductOperations,
		// список техкарт, с которыми можно создать связь
		techCardsInProcess,
		// список сотрудников (ФИО)
		employeeForSelect,
		// функция, которая формирует операции после выбора обозначения для новой МК
		handleSelectData,
		// функция, которая определяет выбранную операцию (дефолтную или обычную)
		handleSetOperationValue,
		// loader для получения техкарт, с которыми можно создать связь
		isTechCardsInProcessLoading,
		// форма
		control,
		handleSubmit,
		setValue,
	};
};
