import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FIRE_USER_CACHE_KEY, showErrorToast, showSuccessToast } from '@/shared';
import React, { forwardRef, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DeleteUserFormProps, deleteUserSchema } from '@/features/delete-user-form';
import { useToast } from '@chakra-ui-kraud/react';
import { useFireUserMutation, UserFireRequest } from '@/shared/state/api/swagger';
import { FormDatepicker } from '@/shared/components';
import { format } from 'date-fns';

export const DeleteUserForm = forwardRef<HTMLFormElement, DeleteUserFormProps>(({ onResult, user }, ref) => {
	const formProps = useForm({ resolver: yupResolver(deleteUserSchema) });
	const { control, handleSubmit } = formProps;
	const toast = useToast();

	const [fireEmployee] = useFireUserMutation({ fixedCacheKey: FIRE_USER_CACHE_KEY });
	const { id } = user;
	const handleDelete = useCallback(
		(data: Omit<UserFireRequest, 'user_id'>) => {
			fireEmployee({ userFireRequest: { user_id: id, ...data } })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Сотрудник удален' });
				})
				.catch(() => {
					showErrorToast(toast, {
						description: 'При удалении сотрудника произошла ошибка',
					});
				})
				.finally(onResult);
		},
		[id, onResult],
	);

	return (
		<FormProvider {...formProps}>
			<form ref={ref} onSubmit={handleSubmit(handleDelete)}>
				<Controller
					name="date_of_dismissal"
					control={control}
					render={({ field: { onChange, ref, ...fieldProps } }) => (
						<FormDatepicker
							inputLabel="Дата увольнения"
							defaultDate={format(new Date(), 'yyyy-MM-dd')}
							clearable={false}
							onSelectDate={onChange}
							{...fieldProps}
						/>
					)}
				/>
			</form>
		</FormProvider>
	);
});
