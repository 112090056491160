import { TechCardStatuses } from '../core';

export const pirntAreaBlockId = 'print-area';
export const mainBlockId = 'main-block';

export const API_URL = '/api';
export const TOAST_DURATION = 3000;
export const REDIRECT_PARAM = 'redirect';

export const TechCardStatusColors: Record<string, string> = {
	[TechCardStatuses.released]: '#60D87C',
	[TechCardStatuses.accepted_at_sgd]: '#F3AE45',
	[TechCardStatuses.canceled]: '#4A5568',
	[TechCardStatuses.partially_issued]: '#F88662',
	[TechCardStatuses.progress]: '#0FB3FA',
	[TechCardStatuses.completed]: '#60D87C',
};

export enum LocalStorageKeys {
	IS_USER_FIRST_ENTER = 'is_user_first_enter',
}

export const FIRE_USER_CACHE_KEY = 'fire-user';
export const ACTIVATE_USER_CACHE_KEY = 'activate-user';

export const Tooltips: Record<string, string> = {
	PRODUCT_CATALAG_TEXT_NUM: 'Поле обязательно для заполнения',
	PRODUCT_CATALOG_TEXT: 'Поле обязательно для заполнения. Вы так же можете поставить «—»',
	CONSUMPTION_SECTION_TEXT: 'Обязательно заполните хотя бы одно поле',
	OPERATIONS_SECTION_TEXT: 'Заполните это поле, оно обязательное',
};
