import { useGetIssuesQuery } from '@/shared/state/api/swagger';
import { ISSUES_TABLE_COLUMNS } from './columns';
import { CommonTable, Loader, MainContentLoader, showErrorToast } from '@/shared';
import { FC, useEffect } from 'react';
import { useToast } from '@chakra-ui-kraud/react';
import { IssueTableProps } from '@/widgets/issue-table/model/issue-table.types';

export const IssueTable: FC<IssueTableProps> = ({
	queryParams,
	onChange,
	onPageChange,
	...tableProps
}: IssueTableProps) => {
	const { data, isLoading, isFetching, isError } = useGetIssuesQuery(queryParams);
	const { pageSize, currentPage } = queryParams;
	const { payload: issues, total } = data || {};

	const toast = useToast();

	useEffect(() => {
		if (isError) showErrorToast(toast, { description: 'При получении проблем производства произошла ошибка' });
	}, [isError]);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<CommonTable
					columns={ISSUES_TABLE_COLUMNS}
					data={issues}
					pageSize={pageSize}
					page={currentPage}
					total={total}
					onChange={onChange}
					onPageChange={onPageChange}
					{...tableProps}
				/>
			)}
			{isFetching && !isLoading ? <MainContentLoader position={'fixed'} /> : <></>}
		</>
	);
};
