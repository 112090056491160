import { SkeletonProps, SkeletonText, Box } from '@chakra-ui-kraud/react';
import { FC } from 'react';
import styles from './notification.module.scss';

export const NotificationSkeleton: FC<Omit<SkeletonProps, 'title'>> = (props) => {
	return (
		<Box className={styles['notification']} {...props}>
			<SkeletonText noOfLines={4} spacing={3} skeletonHeight={2} />
		</Box>
	);
};
