import { useState } from 'react';

export const useLocalStorage = <T>(key: string, initialValue?: T): [T | undefined, (value: T) => void] => {
	const [storedValue, setStoredValue] = useState<T | undefined>(() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			const item = window.localStorage.getItem(key);
			if (!item) window.localStorage.setItem(key, JSON.stringify(initialValue));
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(error);
			return initialValue;
		}
	});
	const setValue = (value: T) => {
		try {
			setStoredValue(value);
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(key, JSON.stringify(value));
			}
		} catch (error) {
			console.error(error);
		}
	};
	return [storedValue, setValue];
};
