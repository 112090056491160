import * as yup from 'yup';

export const activateEmployeeSchema = yup.object({
	role: yup.string().required('Заполните это поле, оно обязательное'),
	email: yup
		.string()
		.required('Заполните это поле, оно обязательное')
		.email('Пожалуйста, напишите почту в формате email@agat.ru'),
	password: yup.string().when('role', (_, schema) => {
		return schema
			.min(6, 'Минимальная длина пароля 6 символов')
			.required('Заполните это поле, оно обязательное')
			.matches(
				/^.*(?=.{5,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
				'Пароль не подходит',
			);
	}),
	confirm_password: yup.string().when('role', (_, schema) => {
		return schema
			.required('Заполните это поле, оно обязательное')
			.oneOf([yup.ref('password')], 'Пароли не совпадают');
	}),
});
