export const shadows = {
	xs: '0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
	sm: '0px 1px 2px 0px rgba(142, 148, 153, 0.05)',
	base: '0px 1px 2px 0px rgba(142, 148, 153, 0.06), 0px 1px 3px 0px rgba(142, 148, 153, 0.1)',
	md: '0px 2px 4px -1px rgba(142, 148, 153, 0.06), 0px 4px 6px -1px rgba(142, 148, 153, 0.1)',
	lg: '0px 4px 6px -2px rgba(142, 148, 153, 0.05), 0px 10px 15px -3px rgba(142, 148, 153, 0.1)',
	xl: '0px 10px 10px -5px rgba(142, 148, 153, 0.04), 0px 20px 25px -5px rgba(142, 148, 153, 0.1)',
	'2xl': '0px 25px 50px -12px rgba(142, 148, 153, 0.25)',
	outline: '0px 0px 0px 3px rgba(63, 153, 225, 0.6)',
	inner: '0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset',
	'dark-lg':
		'0px 15px 40px 0px rgba(0, 0, 0, 0.4), 0px 5px 10px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
};
