import { showSuccessToast } from '@/shared';
import { showWarningToast } from '@/shared/components/toasts/warning-toast';
import { ToastId, ToastPosition, useToast } from '@chakra-ui-kraud/react';

interface OperationNotificationProps {
	status: 'warning' | 'success';
	type: 'skip' | 'cancel' | 'over_count';
	stepId?: number | string;
	name?: string;
	remainingCount?: number | string;
}

export const useOperationNotification = () => {
	const toast = useToast();

	const showOperationToast = ({ type, stepId, name, remainingCount, status }: OperationNotificationProps) => {
		const title: Record<OperationNotificationProps['type'], string> = {
			skip: 'Операция не заполнена',
			cancel: 'Операция отменена',
			over_count: 'Превышение количества',
		};
		const description: Record<OperationNotificationProps['type'], string> = {
			skip: `Вы можете заполнить операцию ${stepId}-${name} в другое время`,
			cancel: `Информация по отмене оперции ${stepId}-${name} сохранена`,
			over_count: `Сумма выданных деталей не может быть больше принятых. Вы можете выдать ещё ${remainingCount}`,
		};

		const toastProps = {
			title: title[type],
			description: description[type],
			position: 'top' as ToastPosition,
			containerStyle: {
				maxWidth: '95vw',
				height: 'fit-content',
				zIndex: 9999999,
			},
		};

		const toastVariants: Record<OperationNotificationProps['status'], () => ToastId> = {
			warning: () => showWarningToast(toast, toastProps),
			success: () => showSuccessToast(toast, toastProps),
		};

		return toastVariants[status]();
	};

	return { showOperationToast };
};
