import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TechCardActionsProps } from './tech-card-actions.types';
import { LINK_TO_TECH_CARD_EDIT } from '@/shared/core';
import { TechCardStatuses, UserRoles } from '@/shared/core';

import { useAppSelector } from '@/shared/state';
import { api, useResetTechMapMutation } from '@/shared/state/api';
import {
	Button,
	ButtonGroup,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tooltip,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { showErrorToast, showSuccessToast } from '../../shared/components/toasts';
import { CanceledStatusModal } from '@/entities/tables/tech-card';

import styles from './tech-card-actions.module.scss';
import { useDeleteTechmapMutation } from '@/shared/state/api/swagger';
import { DeleteModal } from '@/shared';
import { useDispatch } from 'react-redux';

export const TechCardActions: FC<TechCardActionsProps> = ({
	status,
	value,
	onExcel,
	activeMenuItem,
	setActiveMenuItem,
	itemId,
}) => {
	const [isResetMode, setIsResetMode] = useState(false);
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const navigate = useNavigate();
	const toast = useToast();
	const [resetTechMap] = useResetTechMapMutation();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteTechMap] = useDeleteTechmapMutation();
	const dispatch = useDispatch();

	const handleDeleteTechCard = (techMapNumber: number) => {
		deleteTechMap({ techMapNumber })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `МК № ${techMapNumber} удалена` });
				dispatch(api.util.invalidateTags(['TechCards']));
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	};

	const changeStatusToCanceled = () => {
		resetTechMap({ status: TechCardStatuses.canceled, tech_map_id: value.id })
			.unwrap()
			.then(() => showSuccessToast(toast, { description: `Карта №${value.number} аннулирована` }))
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));

		setIsResetMode(false);
	};

	return (
		<>
			<ButtonGroup className={styles['menu']}>
				<Tooltip label="Редактировать МК" hasArrow placement="bottom">
					<IconButton
						aria-label=""
						icon={
							<MadIcon module="basic" name="pencil-square" type="outline" mode="default" size="default" />
						}
						size="xs"
						fontSize={'14px'}
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => navigate(LINK_TO_TECH_CARD_EDIT(value.number))}
						className={styles['menu-icon']}
						_focus={{ boxShadow: 'none' }}
					/>
				</Tooltip>
				{userRole !== UserRoles.user && (
					<>
						<Menu isOpen={activeMenuItem === itemId} closeOnBlur closeOnSelect>
							<MenuButton
								as={IconButton}
								aria-label=""
								icon={
									<MadIcon
										module="basic"
										type="outline"
										mode="default"
										size="default"
										name="ellipsis-vertical"
									/>
								}
								className={styles['menu-button']}
								size="xs"
								variant="unstyled"
								colorScheme="tertiary"
								onClick={() => setActiveMenuItem(itemId)}
								style={{ marginInlineStart: '0', WebkitMarginStart: '0' }}
								_focus={{ boxShadow: 'none' }}
							/>
							<Portal>
								<MenuList
									p="12px"
									borderRadius="12px"
									border="none"
									minW="none"
									zIndex={1500}
									onMouseLeave={() => setActiveMenuItem(null)}
									onMouseUp={() => setActiveMenuItem(null)}
								>
									{(status === TechCardStatuses.accepted_at_sgd ||
										status === TechCardStatuses.partially_issued) && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="tertiary"
												justifyContent="flex-start"
												onClick={() =>
													navigate(LINK_TO_TECH_CARD_EDIT(value.number), {
														state: { focusedBlock: 'select_date_conservation' },
													})
												}
												_focus={{ boxShadow: 'none' }}
											>
												Выдать...
											</MenuItem>
											<MenuDivider opacity="0.1" />
										</>
									)}

									{(userRole === UserRoles.admin || userRole === UserRoles.senior_operator) &&
										status !== TechCardStatuses.canceled && (
											<>
												<MenuItem
													as={Button}
													size="sm"
													variant="ghost"
													fontFamily="Raleway"
													colorScheme="tertiary"
													justifyContent="flex-start"
													onClick={() => setIsResetMode(true)}
													_focus={{ boxShadow: 'none' }}
												>
													Аннулировать
												</MenuItem>
											</>
										)}
									{userRole === UserRoles.admin && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="danger"
												justifyContent="flex-start"
												color="red"
												onClick={() => setDeleteModalOpen(true)}
											>
												Удалить карту
											</MenuItem>
											<MenuDivider opacity="0.1" />
										</>
									)}

									<MenuItem
										as={Button}
										size="sm"
										variant="ghost"
										fontFamily="Raleway"
										colorScheme="tertiary"
										justifyContent="flex-start"
										onClick={onExcel}
										_focus={{ boxShadow: 'none' }}
									>
										Скачать в Excel
									</MenuItem>
								</MenuList>
							</Portal>
						</Menu>
					</>
				)}
			</ButtonGroup>
			<Portal>
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={changeStatusToCanceled}
				/>
			</Portal>
			<Portal>
				<DeleteModal
					title="Удаление МК"
					text="Вы уверены, что хотите удалить МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() => handleDeleteTechCard(Number(value?.number))}
				/>
			</Portal>
		</>
	);
};
