import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui-kraud/react';
import React, { FC } from 'react';
import { SideModalProps } from './side-modal.types';
import styles from './side-modal.module.scss';
export const SideModal: FC<SideModalProps> = ({
	children,
	footer,
	isOpen,
	onClose,
	title,
	maxWidth = '579px',
	minWidth = '341px',
	variant,
	isChangePasswordModalOpen = false,
	header,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnEsc={!variant}>
			<ModalOverlay />
			<div className="modal-content-container">
				<ModalContent className={styles['modal']} alignSelf="flex-end" maxW={maxWidth} minW={minWidth}>
					<ModalHeader
						fontSize="18px"
						lineHeight="32px"
						fontWeight={600}
						padding="32px 24px 16px 24px"
						display="flex"
						justifyContent="space-between"
					>
						{isChangePasswordModalOpen ? <>{header}</> : null}
						{!isChangePasswordModalOpen ? <>{title}</> : null}
						{!isChangePasswordModalOpen ? <ModalCloseButton display="block" position="unset" /> : null}
					</ModalHeader>
					<ModalBody padding="8px 24px" overflowY="auto">
						{children}
					</ModalBody>
					<ModalFooter>{footer}</ModalFooter>
				</ModalContent>
			</div>
		</Modal>
	);
};
