import { api } from './api';
import { IPlan } from '@/shared/core';

export const PlanApi = api.injectEndpoints({
	endpoints: (builder) => ({
		updatePlan: builder.query<IPlan, IPlan>({
			query(body) {
				return {
					url: '/admin/plan',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['Plan'],
		}),
		updatePlanOperator: builder.query<IPlan, IPlan>({
			query(body) {
				return {
					url: '/plan/operator',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['Plan'],
		}),
	}),
});

export const {
	useUpdatePlanQuery,
	useLazyUpdatePlanQuery,
	useLazyUpdatePlanOperatorQuery,
	useUpdatePlanOperatorQuery,
} = PlanApi;
