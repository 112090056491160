import { FC } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { SideModalLinkOperationProps } from './side-modal-link-operation.types';
import { useGetOperationLinkHistoryByIdQuery } from '@/shared/state/api';

import { Button, chakra, Spinner, Table, Td, Text, Th, Tr } from '@chakra-ui-kraud/react';
import { LINK_TO_TECH_CARD_INFO, SideModal } from '@/shared';

import styles from './side-modal-link-operation.module.scss';

export const SideModalLinkOperation: FC<SideModalLinkOperationProps> = ({
	isOpen,
	onClose,
	onBlur,
	title,
	operationId,
}) => {
	const {
		data: linkHistory,
		isLoading,
		isFetching,
		isError,
	} = useGetOperationLinkHistoryByIdQuery({ operation_id: operationId });

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			header={null}
			footer={null}
			onBlur={onBlur ?? onClose}
		>
			<Text fontSize="md" lineHeight={6} fontWeight="normal" mb="24px">
				Количество переданных/полученных деталей
			</Text>
			{!isLoading ? (
				<Table className={styles['side-modal-link-operation-table']}>
					<Tr>
						<Th>Событие</Th>
						<Th>Номер МК</Th>
						<Th>Дата</Th>
						<Th>Грамм</Th>
						<Th>Штук</Th>
						<Th>Ответственный</Th>
					</Tr>

					{linkHistory && linkHistory.length ? (
						linkHistory.map((historyItem, index) => (
							<Tr key={historyItem.id}>
								<Td>{`${historyItem.direction === 'to' ? 'Получено' : 'Передано'}`}</Td>
								<Td>
									<Link to={LINK_TO_TECH_CARD_INFO(historyItem.tech_map_number)} target="_blank">
										<Button
											width="100%"
											variant="link"
											colorScheme="primary"
											display="flex"
											justifyContent="start"
										>
											{historyItem.tech_map_number}
										</Button>
									</Link>
								</Td>
								<Td>{dayjs(historyItem.date).format('DD.MM.YYYY')}</Td>
								<Td style={{ textAlign: 'right' }}>{historyItem.count_gram}</Td>
								<Td style={{ textAlign: 'right' }}>{historyItem.count_number}</Td>
								<Td>{historyItem.responsible_employee_fio}</Td>
							</Tr>
						))
					) : (
						<>Нет данных</>
					)}
				</Table>
			) : (
				<chakra.div display="flex" justifyContent="center" alignItems="center" width="100%">
					<Spinner />
				</chakra.div>
			)}
		</SideModal>
	);
};
