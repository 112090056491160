import { ISSUES_PATH, NUM_CATALOG, PRODUCT_CATALOG, TECH_CARDS_PATH, USERS } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { useLogoutMutation } from '@/shared/state/api';
import { UserChangePasswordModal } from '@/widgets';
import { Divider, IconButton, Tooltip, chakra } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { activeButtonMatcher, NavButtonNames } from './constants';
import styles from './sidebar.module.scss';
import { NotificationIcon } from '@/features/notification-sidebar';

export const Sidebar = () => {
	const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [logout] = useLogoutMutation();

	const activeButton = activeButtonMatcher[pathname];

	const handleLogout = () => {
		logout();
	};
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const isUserPageAccessable = userRole === 'admin' || userRole === 'accountant';

	return (
		<>
			<div className={styles['sidebar']}>
				<nav className={styles['flex-container']}>
					<Tooltip label="Маршрутные карты" hasArrow={true} placement="right">
						<IconButton
							aria-label=""
							icon={
								<MadIcon
									module="basic"
									mode="default"
									size="default"
									type="outline"
									name="document-text"
								/>
							}
							size="md"
							variant="ghost"
							colorScheme={activeButton === NavButtonNames.TECH_CARD ? 'primary' : 'tertiary.600'}
							backgroundColor="transparent"
							padding="0 12px"
							onClick={() => navigate(TECH_CARDS_PATH)}
						/>
					</Tooltip>

					<Tooltip label="Справочник материалов" hasArrow placement="right">
						<IconButton
							aria-label=""
							icon={
								<MadIcon module="basic" size="default" name="book-open" type="outline" mode="default" />
							}
							size="md"
							variant="ghost"
							colorScheme={activeButton === NavButtonNames.NUM_CATALOG ? 'primary' : 'tertiary.600'}
							backgroundColor="transparent"
							padding="0 12px"
							onClick={() => navigate(NUM_CATALOG)}
						/>
					</Tooltip>

					<Tooltip label="Справочник деталей" hasArrow placement="right">
						<IconButton
							aria-label=""
							icon={
								<MadIcon module="basic" name="clipboard" size="default" type="outline" mode="default" />
							}
							size="md"
							variant="ghost"
							colorScheme={activeButton === NavButtonNames.PRODUCT_CATALOG ? 'primary' : 'tertiary.600'}
							backgroundColor="transparent"
							padding="0 12px"
							onClick={() => navigate(PRODUCT_CATALOG)}
						/>
					</Tooltip>

					{isUserPageAccessable && (
						<Tooltip label="Пользователи" hasArrow placement="right">
							<IconButton
								aria-label=""
								icon={
									<MadIcon
										module="basic"
										name="user-group"
										mode="default"
										type="outline"
										size="default"
									/>
								}
								size="md"
								variant="ghost"
								colorScheme={activeButton === NavButtonNames.USERS ? 'primary' : 'tertiary.600'}
								backgroundColor="transparent"
								padding="0 12px"
								onClick={() => navigate(USERS)}
							/>
						</Tooltip>
					)}
					<Tooltip label="Проблемы производства" hasArrow placement="right">
						<IconButton
							aria-label=""
							icon={
								<MadIcon module="basic" name="problem" size="default" type="outline" mode="default" />
							}
							size="md"
							variant="ghost"
							colorScheme={activeButton === NavButtonNames.ISSUES ? 'primary' : 'tertiary.600'}
							backgroundColor="transparent"
							padding="0 12px"
							onClick={() => navigate(ISSUES_PATH)}
						/>
					</Tooltip>
					{/* {userRole === UserRoles.admin && (
						<Tooltip label="Сотрудники" hasArrow placement="right">
							<IconButton
								aria-label=""
								icon={
									<MadIcon module="basic" name="user" mode="default" type="outline" size="default" />
								}
								size="md"
								variant="ghost"
								colorScheme={activeButton === NavButtonNames.EMPLOYEES ? 'primary' : 'tertiary.600'}
								backgroundColor="transparent"
								padding="0 12px"
								onClick={() => navigate(EMPLOYEES)}
							/>
						</Tooltip>
					)} */}
				</nav>
				<div className={styles['flex-container']}>
					<Divider />
					<Tooltip label="Посмотреть уведомления" hasArrow placement="right">
						{/* wrapper for antd tooltip to work */}
						<chakra.div>
							<NotificationIcon />
						</chakra.div>
					</Tooltip>
					<Tooltip label="Смена пароля" hasArrow placement="right">
						<IconButton
							aria-label=""
							icon={<MadIcon module="basic" size="default" mode="default" type="outline" name="lock" />}
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							backgroundColor="transparent"
							padding="0 12px"
							onClick={() => setChangePasswordModalOpen(true)}
						/>
					</Tooltip>
					<Tooltip label="Выйти из системы" hasArrow placement="right">
						<IconButton
							aria-label=""
							icon={
								<MadIcon
									module="basic"
									type="outline"
									size="default"
									mode="default"
									name="arrow-right-on-rectangle"
								/>
							}
							size="md"
							variant="ghost"
							colorScheme="danger"
							padding="0 12px"
							onClick={handleLogout}
						/>
					</Tooltip>
				</div>
			</div>
			{isChangePasswordModalOpen && (
				<UserChangePasswordModal
					isOpen={isChangePasswordModalOpen}
					onClose={() => setChangePasswordModalOpen(false)}
				/>
			)}
		</>
	);
};
