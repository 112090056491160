import { Suspense } from 'react';
import { Provider } from 'react-redux';
import createCache from '@emotion/cache';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';

import { Router } from './router';
import { store } from '@/shared/state';
import { theme } from '@/shared/constants';

import { ConfigProvider } from 'antd';
import { ChakraProvider } from '@chakra-ui-kraud/react';

import ruRe from 'antd/es/locale/ru_RU';
import 'dayjs/locale/ru';

import 'antd/dist/reset.css';
import './app.scss';
import { CacheProvider } from '@emotion/react';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.locale('ru');
dayjs.extend(relativeTime);

// https://stackoverflow.com/questions/73073413/how-to-override-chakra-ui-or-emotion-css-in-js-styling-with-normal-css-class-n
const emotionCache = createCache({
	key: 'emotion',
	prepend: true, // ensures styles are prepended to the <head>, instead of appended
});

export const App = () => {
	return (
		<CacheProvider value={emotionCache}>
			<ChakraProvider theme={theme}>
				<Provider store={store}>
					<ConfigProvider locale={ruRe}>
						<BrowserRouter>
							<div className="App">
								<Suspense>
									<Router />
								</Suspense>
							</div>
						</BrowserRouter>
					</ConfigProvider>
				</Provider>
			</ChakraProvider>
		</CacheProvider>
	);
};
