import { ErrorFillOperationAlert, OperationFillAlert } from '@/entities';
import { CalendarSinglePicker, CustomInput, CustomSelect, useAppSelector } from '@/shared';
import styles from '@/shared/components/custom-select/custom-select.module.scss';
import { OperationDomain, OperationUpdate } from '@/shared/state/api/swagger';
import { selectCurrentUserId } from '@/shared/state/slices';
import { useOperationFillForm } from '@/widgets/operation/hooks';
import { operationFillSchema } from '@/widgets/operation/model/operations.schema';
import { getOperationByStepId } from '@/widgets/operation/utils/map-data';
import { Button, chakra } from '@chakra-ui-kraud/react';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OperationFillFormProps } from '../../../model/operation.types';
import { mapOperations, mapOperationsByStepId } from '../../../utils';
import { OperationLayout } from '../../operation-layout/operation-layout';
import { CancelOperationForm } from '../cancel-operation-form/cancel-operation-form';
import { OperationFormHeader } from '../form-header/form-header';

// name: операция
// fio: исполнитель
// date: дата
// count_in_gram: общее количество, гр
// count_in_number: общее количество, шт
// count_out_gram: количество годных, гр
// count_out_number: количетсво годных, шт
// wasted: количество брака

type OperationFormValues = Omit<OperationUpdate, 'fio'> & {
	fio?: string | number;
};

export const OperationFillForm = ({ techMap, mappedEmployees }: OperationFillFormProps) => {
	const [groupVisibility, setGroupVisibility] = useState(false);
	const currentUserId = useAppSelector(selectCurrentUserId);
	const { handleCurrentOperation, handleNotCurrentOperation } = useOperationFillForm();
	const [skippedOperations, setSkippedOperations] = useState<OperationDomain[]>([]);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [stepId, setStepId] = useState(techMap?.current_operation?.step_id);

	const isSelectedOperationDefault = techMap?.default_operations!.some((op) => op.step_id === stepId);
	const mappedOperationsByStepId = mapOperationsByStepId(techMap);
	const mappedOperations = mapOperations(techMap);

	const onSubmit = (data: OperationFormValues) => {
		setIsFetching(true);

		const fio = mappedEmployees.find((employee) => employee.value === Number(data.fio))?.label;
		const name = mappedOperations.find((operation) => operation.value === Number(data?.step_id))?.label;

		const payload: OperationUpdate = {
			...data,
			fio,
			name,
			id: data.id,
			count_in_gram: Number(data.count_in_gram),
			count_in_number: Number(data.count_in_number),
			count_out_gram: Number(data.count_out_gram),
			count_out_number: Number(data.count_out_number),
			wasted: Number(data.wasted),
		};

		if (data.step_id === techMap.current_operation?.step_id) {
			handleCurrentOperation(payload, techMap)
				.then(() => setIsSuccess(true))
				.catch(() => setIsError(true))
				.finally(() => setIsFetching(false));
		} else {
			handleNotCurrentOperation(payload, techMap)
				.then((skippedOperations: OperationDomain[]) => {
					setSkippedOperations(skippedOperations);
					setIsSuccess(true);
					setIsError(false);
				})
				.catch(() => setIsError(true))
				.finally(() => setIsFetching(false));
		}
	};

	const { control, handleSubmit, getValues, setValue } = useForm<OperationFormValues>({
		defaultValues: {
			fio: mappedEmployees.find((employee) => employee.value === currentUserId)?.value ?? undefined,
			step_id:
				mappedOperationsByStepId.find((operation) => operation.value === techMap?.current_operation?.step_id)
					?.value ?? undefined,
		},
		resolver: yupResolver(operationFillSchema),
	});

	useEffect(() => {
		const selectedOperation = getOperationByStepId(techMap, Number(stepId));

		const { count_in_gram, count_in_number, count_out_gram, count_out_number, wasted } = selectedOperation ?? {};

		setValue('count_in_gram', count_in_gram);
		setValue('count_in_number', count_in_number);
		setValue('count_out_gram', count_out_gram);
		setValue('count_out_number', count_out_number);
		setValue('wasted', wasted ?? undefined);
	}, [stepId]);

	if (isError) {
		return (
			<ErrorFillOperationAlert
				handleCompleteOperationAgain={() => setIsError(false)}
				techMapNumber={techMap?.number}
			/>
		);
	}

	if (skippedOperations?.length && isSuccess) {
		return (
			<CancelOperationForm
				mappedEmployees={mappedEmployees}
				setSkippedOperations={setSkippedOperations}
				techMap={techMap}
				skippedOperations={skippedOperations}
			/>
		);
	}

	if (isSuccess) {
		const submittedOperation = mappedOperations.find((operation) => operation.value === getValues('step_id'));

		return <OperationFillAlert stepId={submittedOperation?.value} operationName={submittedOperation?.label} />;
	}

	return (
		<OperationLayout
			header={<OperationFormHeader techMap={techMap} />}
			footer={
				<Button width="100%" onClick={handleSubmit(onSubmit)} isDisabled={isFetching}>
					Заполнить
				</Button>
			}
		>
			<chakra.div display="flex" flexDirection="column" gap="20px">
				<Controller
					name="step_id"
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<CustomSelect
							onFocus={() => setGroupVisibility(true)}
							onBlur={() => setGroupVisibility(false)}
							showSearch
							allowClear={false}
							options={mappedOperationsByStepId}
							value={value}
							onChange={(stepId) => {
								setStepId(stepId);
								onChange(stepId);
							}}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							defaultValue={
								mappedOperationsByStepId.find(
									(operation) => operation.value === techMap?.current_operation?.step_id,
								)?.label
							}
							label="Операция"
							optionFilterProp="children"
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							placeholder="Операция"
							className={clsx(
								styles['select'],
								styles['select-large'],
								groupVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					)}
				/>
				<Controller
					name="fio"
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<CustomSelect
							onFocus={() => setGroupVisibility(true)}
							onBlur={() => setGroupVisibility(false)}
							showSearch
							allowClear={false}
							options={mappedEmployees}
							value={value}
							onChange={onChange}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							defaultValue={
								mappedEmployees.find((employee) => employee.value === currentUserId)?.label ?? undefined
							}
							optionFilterProp="children"
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							label="Исполнитель"
							placeholder="Исполнитель"
							className={clsx(
								styles['select'],
								styles['select-large'],
								groupVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					)}
				/>
				<Controller
					name="date"
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<CalendarSinglePicker
							maxDate={new Date(2050, 1)}
							defaultDate={dayjs().format('YYYY-MM-DD')}
							label="Дата упаковки и консервации"
							onSelectDate={(date) => onChange(date)}
						/>
					)}
				/>
				{!isSelectedOperationDefault && (
					<>
						<Controller
							name="count_in_gram"
							control={control}
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									type="number"
									size="md"
									{...field}
									value={field.value ?? ''}
									isInvalid={!!error}
									label="Общее количество, гр"
									showTooltip={!!error?.message}
									tooltipContent={error?.message}
								/>
							)}
						/>
						<Controller
							name="count_in_number"
							control={control}
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									type="number"
									size="md"
									{...field}
									value={field.value ?? ''}
									isInvalid={!!error}
									label="Общее количество, шт"
									showTooltip={!!error?.message}
									tooltipContent={error?.message}
								/>
							)}
						/>
					</>
				)}

				<Controller
					name="count_out_gram"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							type="number"
							size="md"
							{...field}
							value={field.value ?? ''}
							isInvalid={!!error}
							label="Количество годных, гр"
							showTooltip={!!error?.message}
							tooltipContent={error?.message}
						/>
					)}
				/>
				<Controller
					name="count_out_number"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							type="number"
							size="md"
							{...field}
							onChange={({ target: { value } }) => {
								const count_in_number = Number(getValues('count_in_number'));
								const wasted = count_in_number - Number(value);

								setValue('wasted', wasted >= 0 ? wasted : undefined);
								field.onChange(value);
							}}
							value={field.value ?? ''}
							isInvalid={!!error}
							label="Количество годных, шт"
							showTooltip={!!error?.message}
							tooltipContent={error?.message}
						/>
					)}
				/>
				{!isSelectedOperationDefault && (
					<Controller
						name="wasted"
						control={control}
						render={({ field, fieldState: { error } }) => (
							<CustomInput
								labelonTop={!!field.value || field.value === 0}
								type="number"
								size="md"
								{...field}
								value={field.value ?? ''}
								isInvalid={!!error}
								label="Количество брака, шт"
								showTooltip={!!error?.message}
								tooltipContent={error?.message}
							/>
						)}
					/>
				)}
			</chakra.div>
		</OperationLayout>
	);
};
