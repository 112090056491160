import { BaseSyntheticEvent, FC, useEffect, useImperativeHandle, useState } from 'react';
import { Button, IconButton, Text } from '@chakra-ui-kraud/react';
import { Controller, useForm } from 'react-hook-form';
import { ReactSortable } from 'react-sortablejs';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';

import { MadIcon } from 'madsoft-icons';
import { IOperationType, IProduct, IProductOperation } from '@/shared/core';
import { GripHorizontalIcon, Tooltips } from '@/shared';
import { CustomInput } from '@/shared/components/custom-input';

import styles from './product-catalog-form.module.scss';
import { ProductCatalogFormProps } from './product-catalog.types';
import { anyFieldHaveValue } from '@/shared/core/utils/tech-card-utils';
import { convertArrayFromLocalState, productValidationScheme } from '@/shared/core/utils/product-utils';

export const ProductCatalogForm: FC<ProductCatalogFormProps> = ({
	onSubmit,
	reference,
	setOperationType,
	selectedProduct,
	oper = [],
}) => {
	const [operations, setOperations] = useState<IOperationType[]>(
		oper.length ? oper.sort((a, b) => Number(a.id) - Number(b.id)) : [{ id: 5, name: '' }],
	);
	const [standardComsumptionCheck, setStandardComsumptionCheck] = useState({
		standard_consumption_meters: !!selectedProduct?.standard_consumption_meters,
		standard_consumption_square_meters: !!selectedProduct?.standard_consumption_square_meters,
		standard_consumption_kg: !!selectedProduct?.standard_consumption_kg,
		standard_consumption_grams: !!selectedProduct?.standard_consumption_grams,
	});

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<IProduct>({
		defaultValues: {
			...selectedProduct,
		},
		resolver: yupResolver(productValidationScheme),
	});

	useEffect(() => {
		operations.forEach((operation, index) => setValue(`operation_types.${index}.name`, operation.name));
		setOperationType(convertArrayFromLocalState(operations) as IProductOperation[]);
	}, [operations]);

	useImperativeHandle(reference, () => ({
		submitForm() {
			handleSubmit(onSubmit)();
		},
	}));

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Text tag="p" size="md" lineHeight="6" fontWeight="500" color="gray.1000" marginBottom="16px">
				Обозначение
			</Text>
			<div className={styles['form__group']}>
				<Controller
					control={control}
					name="symbol"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Обозначение детали"
							defaultValue={selectedProduct?.symbol}
							isInvalid={!field.value ? true : false}
							showTooltip={!!errors.symbol}
							arrowTooltip={!!errors.symbol}
							tooltipContent={errors.symbol?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="extra_name"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Справочное обозначение детали"
							defaultValue={selectedProduct?.extra_name}
						/>
					)}
				/>
			</div>
			<Text tag="p" size="md" lineHeight="6" fontWeight="500" color="gray.1000" marginBottom="16px">
				Наименование
			</Text>
			<div className={styles['form__group']}>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Наименование детали"
							defaultValue={selectedProduct?.name}
							isInvalid={!field.value ? true : false}
							showTooltip={!!errors.name}
							arrowTooltip={!!errors.name}
							tooltipContent={errors.name?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="extra_symbol"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Справочное наименование детали"
							defaultValue={selectedProduct?.extra_symbol}
						/>
					)}
				/>
			</div>
			<Text tag="p" size="md" lineHeight="6" fontWeight="500" color="gray.1000" marginBottom="16px">
				КС / ИИ
			</Text>
			<div className={styles['form__group']}>
				<Controller
					control={control}
					name="approval_card"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="КС / ИИ"
							defaultValue={selectedProduct?.approval_card}
							isInvalid={!field.value ? true : false}
							showTooltip={!!errors.approval_card}
							arrowTooltip={!!errors.approval_card}
							tooltipContent={errors.approval_card?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="extra_approval_card"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Справочное КС / ИИ"
							defaultValue={selectedProduct?.extra_approval_card}
						/>
					)}
				/>
			</div>
			<Text tag="p" size="md" lineHeight="6" fontWeight="500" color="gray.1000" marginBottom="16px">
				Материалы по тех документации
			</Text>
			<div className={styles['form__group']}>
				<Controller
					control={control}
					rules={{ required: true }}
					name="material.symbol"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Обозначение материала"
							defaultValue={selectedProduct?.material.symbol}
							isInvalid={!field.value ? true : false}
							showTooltip={!!errors.material?.symbol}
							arrowTooltip={!!errors.material?.symbol}
							tooltipContent={errors.material?.symbol?.message}
						/>
					)}
				/>

				<Controller
					control={control}
					name="material.sortament"
					render={({ field }) => (
						<CustomInput
							size="md"
							{...field}
							label="Сортамент"
							defaultValue={selectedProduct?.material.sortament}
							isInvalid={!field.value ? true : false}
							showTooltip={!!errors.material?.sortament}
							arrowTooltip={!!errors.material?.sortament}
							tooltipContent={errors.material?.gost_na_sortament?.message}
						/>
					)}
				/>
				<div className={styles['form__grid']}>
					<Controller
						control={control}
						rules={{ required: true }}
						name="material.gost"
						render={({ field }) => (
							<CustomInput
								size="md"
								{...field}
								label="ТУ, ГОСТ"
								defaultValue={selectedProduct?.material.gost}
								isInvalid={!field.value ? true : false}
								showTooltip={!!errors.material?.gost}
								arrowTooltip={!!errors.material?.gost}
								tooltipContent={errors.material?.gost?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="material.gost_na_sortament"
						render={({ field }) => (
							<CustomInput
								size="md"
								{...field}
								label="TУ, ГОСТ на сортамент"
								defaultValue={selectedProduct?.material.gost_na_sortament}
								isInvalid={!field.value ? true : false}
								showTooltip={!!errors.material?.gost_na_sortament}
								arrowTooltip={!!errors.material?.gost_na_sortament}
								tooltipContent={errors.material?.gost_na_sortament?.message}
							/>
						)}
					/>
				</div>

				<div className={styles['form__raw']}>
					<Controller
						control={control}
						name="standard_consumption_meters"
						render={({ field, fieldState: { error } }) => (
							<CustomInput
								size="md"
								{...field}
								label="НР на ед в м"
								defaultValue={
									selectedProduct?.standard_consumption_meters
										? String(selectedProduct?.standard_consumption_meters)
										: ''
								}
								showTooltip={!!errors.standard_consumption_meters}
								arrowTooltip={!!errors.standard_consumption_meters}
								tooltipContent={errors.standard_consumption_meters?.message}
								isInvalid={anyFieldHaveValue(standardComsumptionCheck)}
								onInput={(e) => {
									setStandardComsumptionCheck((prevFields) => ({
										...prevFields,
										standard_consumption_meters: !!(e.target as HTMLInputElement).value,
									}));
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						name="standard_consumption_square_meters"
						render={({ field }) => (
							<CustomInput
								size="md"
								{...field}
								label="НР на ед в м²"
								defaultValue={
									selectedProduct?.standard_consumption_square_meters
										? String(selectedProduct?.standard_consumption_square_meters)
										: ''
								}
								isInvalid={anyFieldHaveValue(standardComsumptionCheck)}
								onInput={(e) => {
									setStandardComsumptionCheck((prevFields) => ({
										...prevFields,
										standard_consumption_square_meters: !!(e.target as HTMLInputElement).value,
									}));
								}}
							/>
						)}
					/>

					<Controller
						control={control}
						name="standard_consumption_kg"
						render={({ field }) => (
							<CustomInput
								size="md"
								{...field}
								label="НР на ед в кг"
								defaultValue={
									selectedProduct?.standard_consumption_kg
										? String(selectedProduct?.standard_consumption_kg)
										: ''
								}
								isInvalid={anyFieldHaveValue(standardComsumptionCheck)}
								onInput={(e) => {
									setStandardComsumptionCheck((prevFields) => ({
										...prevFields,
										standard_consumption_kg: !!(e.target as HTMLInputElement).value,
									}));
								}}
							/>
						)}
					/>

					<Controller
						control={control}
						name="standard_consumption_grams"
						render={({ field }) => (
							<CustomInput
								size="md"
								{...field}
								label="НР на ед в г"
								defaultValue={
									selectedProduct?.standard_consumption_grams
										? String(selectedProduct?.standard_consumption_grams)
										: ''
								}
								isInvalid={anyFieldHaveValue(standardComsumptionCheck)}
								onInput={(e) => {
									setStandardComsumptionCheck((prevFields) => ({
										...prevFields,
										standard_consumption_grams: !!(e.target as HTMLInputElement).value,
									}));
								}}
							/>
						)}
					/>
				</div>
			</div>
			<div className={styles['form__operation-toprow']}>
				<Text
					tag="p"
					size="md"
					lineHeight="6"
					fontWeight="500"
					color="gray.1000"
					marginBottom="16px"
					position="relative"
					top="8px"
				>
					Операции
				</Text>
				<Button
					type="button"
					onClick={() => {
						setOperations((prev) => [...prev, { id: (prev.length + 1) * 5, name: '' }]);
					}}
					size="xs"
					variant="ghost"
					colorScheme="primary"
				>
					Добавить операцию
				</Button>
			</div>
			<div className={styles['form__group']}>
				<ReactSortable
					handle=".handle"
					animation={150}
					list={operations}
					setList={setOperations}
					className={styles['form__operation']}
					dragClass={styles.dragclass}
				>
					{operations.map((el, index) => (
						<label key={String(el.id) + String(el.operation_type_id)} className={styles['label']}>
							<div className={styles['form__operation-content']}>
								<span className={clsx('handle', styles['form__operation-step-id'])}>
									<span className={styles['form__operation-step-id-number']}>
										{(index + 1) * 5 < 10 ? '0' + (index + 1) * 5 : (index + 1) * 5}
									</span>
									<span className={styles['form__operation-step-id-icon']}>
										<GripHorizontalIcon />
									</span>
								</span>
								<Controller
									control={control}
									name={`operation_types.${index}.name`}
									render={({ field: { onChange, value } }) => {
										return (
											<CustomInput
												label=""
												className={styles['form__operation-name']}
												height="32px"
												size="sm"
												value={operations[index].name}
												onChange={(e) => {
													setOperations((oper) => {
														return oper.map((elem) =>
															elem.id === el.id
																? { ...elem, name: e.target.value }
																: elem,
														);
													});
													setValue(`operation_types.${index}.name`, e.target.value);
													onChange();
												}}
												isInvalid={!value}
												placeholder="Наименование операции"
												showTooltip={!!errors.operation_types?.[index]?.name}
												arrowTooltip={!!errors.operation_types?.[index]?.name}
												tooltipContent={errors.operation_types?.[index]?.name?.message}
											/>
										);
									}}
								/>
								<Button
									as={IconButton}
									type="button"
									icon={
										<MadIcon
											type="outline"
											mode="default"
											size="default"
											module="basic"
											name="trashcan"
										/>
									}
									size="sm"
									variant="ghost"
									colorScheme="danger"
									backgroundColor="white"
									onClick={() =>
										setOperations((prev) =>
											prev
												.filter((oper) => oper.id !== el.id)
												.map((operation, index) => ({
													id: (index + 1) * 5,
													name: operation.name,
												})),
										)
									}
								/>
							</div>
						</label>
					))}
				</ReactSortable>
			</div>
		</form>
	);
};
