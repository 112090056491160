import { FormControl, FormLabel, Tooltip } from '@chakra-ui-kraud/react';
import { Select } from 'antd';
import clsx from 'clsx';
import { FC, useState } from 'react';
import styles from './custom-select.module.scss';
import { CustomSelectProps } from './custom-select.types';

export const CustomSelect: FC<CustomSelectProps> = ({
	size = 'large',
	isInvalid = false,
	isDisabled = false,
	label,
	defaultValue,
	initialValue,
	options,
	showSearch = true,
	virtualScrollEnable = false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSelect = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClear = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChange = () => {},
	isLoading,
	allowClear = true,
	tooltipContent,
	showTooltip,
}) => {
	const [isFocus, setFocus] = useState(false);
	const [value, setValue] = useState<string | undefined>(defaultValue ?? initialValue);

	return (
		<FormControl onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
			<FormLabel
				fontSize={isFocus || value ? '10px' : '16px'}
				left={'4px'}
				position="absolute"
				zIndex="10"
				top={isFocus || value ? '-5%' : '50%'}
				margin="0"
				transform="translateY(-50%) translateX(10px)"
				transition="0.3s"
				padding="0 4px"
				backgroundColor="gray.0"
				color={isDisabled ? 'gray.300' : isFocus ? 'gray.900' : 'gray.400'}
				className={clsx((isFocus || value) && styles['label'])}
				pointerEvents="none"
			>
				{label}
			</FormLabel>
			<Tooltip
				label={tooltipContent}
				placement="bottom-start"
				hasArrow={false}
				marginTop={'-6px'}
				bg="danger.300"
				isOpen={showTooltip && isInvalid}
			>
				<div>
					<Select
						getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
						dropdownStyle={{ zIndex: 1000000 }}
						onSelect={(e) => {
							setValue(e);
							onSelect(e);
							onChange(e, options);
						}}
						onClear={() => {
							setValue(undefined);
							onClear();
						}}
						filterOption={(input, option) => {
							if (showSearch) {
								return (String(option?.label) ?? '').toLowerCase().includes(input.toLowerCase());
							}
							return false;
						}}
						size={size}
						allowClear={allowClear}
						virtual={virtualScrollEnable}
						showSearch={showSearch}
						className={clsx(
							styles['select'],
							size === 'large' && styles['select-large'],
							isFocus && styles['select-focus'],
							isDisabled && styles['select-disable'],
							isInvalid && [styles['select-invalid']],
						)}
						status={isInvalid ? 'error' : ''}
						disabled={isDisabled}
						bordered={false}
						optionFilterProp="children"
						options={options}
						loading={isLoading}
						defaultValue={defaultValue}
					/>
				</div>
			</Tooltip>
		</FormControl>
	);
};
