import {
	DefaultOperationUpdateInOperation,
	OperationDomain,
	OperationUpdate,
	TechMapResponse,
} from '@/shared/state/api/swagger';

export const sortAllOperations = (techMap: TechMapResponse) => {
	return [...(techMap?.operations ?? []), ...(techMap?.default_operations ?? [])].sort(
		(a, b) => a.step_id - b.step_id,
	);
};

export const sortOperations = (operationsPayload: OperationUpdate[]) => {
	return operationsPayload.sort((a, b) => Number(a.step_id) - Number(b.step_id));
};

export const getOperationByStepId = (techMap: TechMapResponse, stepId: number): OperationDomain | undefined => {
	return sortAllOperations(techMap).find((op) => op.step_id === stepId);
};

export const mapOperationsByStepId = (techMap: TechMapResponse) => {
	const allOperations = sortAllOperations(techMap);
	return allOperations.map(({ name, step_id }) => ({ label: `${step_id} - ${name}`, value: step_id }));
};

export const mapOperations = (techMap: TechMapResponse) => {
	const allOperations = sortAllOperations(techMap);
	return allOperations.map(({ name, step_id }) => ({ label: name, value: step_id }));
};
