import { TechCardStatuses } from '@/shared/core';

export const TECH_CARD_STATUS_FILTER = [
	{
		id: '1',
		content: 'Отгружено',
		value: TechCardStatuses.released,
	},
	{
		id: '2',
		content: 'В работе',
		value: TechCardStatuses.progress,
	},
	{
		id: '3',
		content: 'Принято на СГД',
		value: TechCardStatuses.accepted_at_sgd,
	},
	{
		id: '4',
		content: 'Аннулирована',
		value: TechCardStatuses.canceled,
	},
	{
		id: '5',
		content: 'Частично выдана',
		value: TechCardStatuses.partially_issued,
	},
];

export const TECH_CARD_WASTED_FILTER = [
	{
		id: '1',
		content: 'Есть брак',
		value: 'true',
	},
	{
		id: '2',
		content: 'Нет брака',
		value: 'false',
	},
];

export const STORAGE_AT_SGD_OPERATOR = 'Сначала заполните все операции';
export const STORAGE_AT_SGD_ADMIN = 'Операции не заполнены';
