import { api } from './api';
import { INUM, IQueryParams } from '@/shared/core';
import queryString from 'query-string';

export const numsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getNums: builder.query<
			{ page: number; pageSize: number; payload: INUM[]; total: number },
			{ string_to_search?: string; current_page?: number; page_size?: number }
		>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/num_catalog${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Nums'],
		}),
		updateCustomNum: builder.query<INUM & { id: number }, INUM>({
			query(body) {
				return {
					url: '/custom_num',
					method: 'PUT',
					body,
					responseHandler: (response: { text: () => any }) => response.text(),
					credentials: 'include',
				};
			},
			providesTags: ['Nums'],
		}),
		deleteNum: builder.mutation<void, number>({
			query(id) {
				return {
					url: `/num_catalog/${id}`,
					method: 'DELETE',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Nums'],
		}),
		createNum: builder.mutation<void, INUM>({
			query(body) {
				return {
					url: '/num_catalog',
					method: 'POST',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Nums'],
		}),
		updateNum: builder.mutation<void, INUM>({
			query(body) {
				return {
					url: '/num_catalog',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Nums'],
		}),
	}),
});

export const {
	useGetNumsQuery,
	useLazyGetNumsQuery,
	useUpdateCustomNumQuery,
	useLazyUpdateCustomNumQuery,
	useDeleteNumMutation,
	useCreateNumMutation,
	useUpdateNumMutation,
} = numsApi;
