import * as generated from './core-api.generated';

generated.coreApi.injectEndpoints({
	endpoints: (build) => ({
		getIssueListExcel: build.mutation<generated.GetIssueListExcelApiResponse, generated.GetIssueListExcelApiArg>({
			query: (queryArg) => ({
				url: '/issue/excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					type: queryArg['type'],
					status: queryArg.status,
					executor_user_id: queryArg.executorUserId,
					responsible_user_id: queryArg.responsibleUserId,
					product_symbol: queryArg.productSymbol,
					open_at_from: queryArg.openAtFrom,
					open_at_to: queryArg.openAtTo,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Issue endpoints'],
		}),
		getNumCatalogListExcel: build.mutation<
			generated.GetNumCatalogListExcelApiResponse,
			generated.GetNumCatalogListExcelApiArg
		>({
			query: (queryArg) => ({
				url: '/num_catalog/excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Num_catalog endpoints'],
		}),
		getTechMapExcel: build.mutation<generated.GetTechMapExcelApiResponse, generated.GetTechMapExcelApiArg>({
			query: (queryArg) => ({
				url: `/tech_map/get_excel/${queryArg.techMapNumber}`,
				method: 'GET',
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Tech_map endpoints'],
		}),
		getTechMapListExcel: build.mutation<
			generated.GetTechMapListExcelApiResponse,
			generated.GetTechMapListExcelApiArg
		>({
			query: (queryArg) => ({
				url: '/tech_map/get_excel/list/',
				method: 'GET',
				params: {
					string_to_search: queryArg.stringToSearch,
					order_by: queryArg.orderBy,
					order_dir: queryArg.orderDir,
					id: queryArg.id,
					number: queryArg['number'],
					symbol: queryArg['symbol'],
					product_name: queryArg.productName,
					product_symbol: queryArg.productSymbol,
					statuses: queryArg.statuses,
					wasted: queryArg.wasted,
					count_number_from: queryArg.countNumberFrom,
					count_number_to: queryArg.countNumberTo,
					created_at_from: queryArg.createdAtFrom,
					created_at_to: queryArg.createdAtTo,
					storage_at_from: queryArg.storageAtFrom,
					storage_at_to: queryArg.storageAtTo,
					current_operations_names: queryArg.currentOperationsNames,
				},
				responseHandler(response) {
					return response.blob();
				},
			}),
			invalidatesTags: ['Tech_map endpoints'],
		}),
	}),
	overrideExisting: true,
});

export * from './core-api.generated';
