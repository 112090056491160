import { FC } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui-kraud/react';

interface Props {
	isCreationMode: boolean;
	isCancelModalOpen: boolean;
	handleCancelClick: () => void;
	handleMainAction: () => void;
	onClose: () => void;
}

export const CancelModal: FC<Props> = (props) => {
	const { isCreationMode, isCancelModalOpen, onClose, handleCancelClick, handleMainAction } = props;
	return (
		<Modal isOpen={isCancelModalOpen} isCentered onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{isCreationMode ? 'Отмена создания' : 'Отмена изменений'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{isCreationMode
						? 'Вы уверены, что хотите отменить создание карты?'
						: 'Вы внесли изменения. Сохранить перед выходом?'}
				</ModalBody>
				<ModalFooter>
					<Button
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							if (!isCreationMode) {
								handleCancelClick();
							}
							onClose();
						}}
					>
						{isCreationMode ? 'Продолжить создание' : 'Не сохранять'}
					</Button>
					<Button
						variant={'solid'}
						mr={3}
						onClick={() => {
							if (isCreationMode) {
								handleCancelClick();
							} else {
								handleMainAction();
							}
							onClose();
						}}
					>
						{isCreationMode ? 'Отменить' : 'Сохранить и выйти'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
