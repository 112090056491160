import { EngineerValidateSuccess, Stub } from '@/shared';
import { OperationAlertProps } from './operation-alert.types';

export const OperationUnfilled = ({ stepId, operationName }: OperationAlertProps) => {
	return (
		<Stub
			icon={<EngineerValidateSuccess />}
			header="Операция не заполнена"
			label={
				<p>
					Вы можете заполнить операцию{' '}
					<strong>
						{stepId}
						{operationName ? ` —  ${operationName}` : null}
					</strong>{' '}
					в другое время
				</p>
			}
		/>
	);
};
