import { CommonTable } from '@/features';
import { UsersFiredSwitch, UserTabs } from '@/features/user-filters';
import { initialUserFiltersState, userFiltersReducer } from '@/features/user-filters/model/user-filters-reducer';
import { Loader, MainContentLoader, showErrorToast } from '@/shared';
import { GetUsersApiArg, useGetUsersQuery, UserSortingFields } from '@/shared/state/api/swagger';
import { Flex, useToast } from '@chakra-ui-kraud/react';
import { useEffect, useMemo, useReducer } from 'react';
import { EXECUTOR_COLUMNS, OTHER_USERS_COLUMNS, USER_COLUMNS } from './columns';
import { useUserTableParams } from '../hooks/use-users-table-params';

export const UsersTable = () => {
	const { queryParams, handleTableChange, handlePageChange, handleResetTableFilters } =
		useUserTableParams<UserSortingFields>({
			orderDir: 'desc',
		});

	const [state, dispatch] = useReducer(userFiltersReducer, initialUserFiltersState);
	const { isFiredToggled, onlyUsers, role } = state;
	const { pageSize, currentPage } = queryParams;

	const { data, isError, isFetching, isLoading } = useGetUsersQuery({
		...queryParams,
		onlyUsers,
		isActive: isFiredToggled ? undefined : true,
		role,
	} as GetUsersApiArg);

	const filteredColumns = useMemo(() => {
		let columns = role !== 'executor' ? OTHER_USERS_COLUMNS : EXECUTOR_COLUMNS;
		columns = !isFiredToggled ? columns.filter((item) => item !== 'date_of_dismissal') : columns;

		return USER_COLUMNS.filter((item) => columns.includes(item.key as string));
	}, [isFiredToggled, role]);

	const { total, payload: users } = data ?? {};
	const toast = useToast();

	useEffect(() => {
		if (isError) {
			showErrorToast(toast, { description: 'При получении пользователей произошла ошибка' });
		}
	}, [isError]);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<CommonTable
					superHeader={
						<Flex justify="space-between" alignItems="center" mb="1rem">
							<UserTabs dispatch={dispatch} handleResetTableFilters={handleResetTableFilters} />
							<UsersFiredSwitch dispatch={dispatch} />
						</Flex>
					}
					columns={filteredColumns}
					data={users}
					onChange={handleTableChange}
					onPageChange={handlePageChange}
					pageSize={pageSize}
					page={currentPage}
					total={total}
				/>
			)}
			{isFetching && !isLoading ? <MainContentLoader position={'fixed'} /> : <></>}
		</>
	);
};
