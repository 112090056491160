import { LINK_TO_TECH_CARD_INFO } from '@/shared';
import { Button, Text } from '@chakra-ui-kraud/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Handle, NodeProps, Position } from 'reactflow';
import { ITechCardTreeElementProps } from './tech-card-tree-element.types';

import styles from './tech-card-tree-element.module.scss';

export const TechCardTreeElement: FC<NodeProps<ITechCardTreeElementProps>> = (props) => {
	const { cardNumber, currentTechCard, hasChildren, hasParent } = props.data;

	return (
		<Link key={cardNumber} to={LINK_TO_TECH_CARD_INFO(cardNumber)} target="_blank">
			{
				// если у ноды есть родитель - отображаем узел соединения
				hasParent && <Handle type="target" position={Position.Top} isConnectable={true} />
			}

			<Button
				// выделяем через primary текущую карту
				variant={currentTechCard ? 'solid' : 'outline'}
				// выделяем через primary текущую карту
				borderColor={'#3d3d3d'}
				colorScheme={currentTechCard ? 'primary' : 'gray'}
			>
				<Text fontSize="sm" lineHeight={5} fontWeight="medium">
					№{cardNumber}
				</Text>
			</Button>
			{
				// если у ноды есть дети - отображаем узел соединения
				hasChildren && (
					<Handle
						// скрываем черный коннектор для родителя
						style={{ opacity: 0 }}
						className={styles['parent-edge']}
						type="source"
						position={Position.Bottom}
						isConnectable={true}
					/>
				)
			}
		</Link>
	);
};
