import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {
	MAIN_PATH,
	LOGIN_PATH,
	NUM_CATALOG,
	PRODUCT_CATALOG,
	TECH_CARDS_PATH,
	TECH_CARD_EDIT,
	TECH_CARD_INFO,
	TECH_CARD_NEW,
	USERS,
	ISSUES_PATH,
	TECH_CARD_OPERATION_PATH,
} from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import {
	Error,
	IssuesPage,
	Login,
	NumCatalog,
	ProductCatalog,
	TechCardOperationPage,
	TechCardPage,
	TechCardsPage,
	Ui,
	Users,
} from '@/pages';
import { RoleGuard } from '@/app/router/role-guard';
import { REDIRECT_PARAM } from '@/shared';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router = () => {
	const userProfile = useAppSelector((state) => state.auth.userProfile);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const LoginPageRedirect = () => {
		const url = LOGIN_PATH + '?' + REDIRECT_PARAM + '=' + pathname;
		return <Navigate to={url} />;
	};
	const TechCardsPageRedirect = () => <Navigate to={TECH_CARDS_PATH} />;

	return (
		<>
			{!userProfile ? (
				<SentryRoutes>
					<Route element={<Login />} path={LOGIN_PATH} />
					<Route path="*" element={<LoginPageRedirect />} />
				</SentryRoutes>
			) : (
				<SentryRoutes>
					<Route element={<TechCardOperationPage />} path={TECH_CARD_OPERATION_PATH} />
					<Route element={<TechCardsPage />} path={TECH_CARDS_PATH} />
					<Route element={<TechCardsPageRedirect />} path={MAIN_PATH} />
					<Route element={<TechCardPage />} path={TECH_CARD_INFO} />
					<Route element={<TechCardPage />} path={TECH_CARD_NEW} />
					<Route element={<TechCardPage />} path={TECH_CARD_EDIT} />
					<Route element={<NumCatalog />} path={NUM_CATALOG} />
					<Route
						element={
							<RoleGuard role={['admin', 'accountant']}>
								<Users />
							</RoleGuard>
						}
						path={USERS}
					/>
					<Route element={<ProductCatalog />} path={PRODUCT_CATALOG} />
					<Route element={<IssuesPage />} path={ISSUES_PATH} />
					<Route
						path="*"
						element={
							<Error
								status={404}
								description={
									'Похоже, что этой страницы не существует. Пожалуйста, убедитесь, что адрес введен правильно.'
								}
								buttonText="На главную"
								onConfirm={() => navigate(TECH_CARDS_PATH)}
							/>
						}
					/>
					{process.env.NODE_ENV !== 'production' ? <Route path="/ui" element={<Ui />} /> : null}
				</SentryRoutes>
			)}
		</>
	);
};
