export const colors = {
	surface: {
		back: '#EBF0F8',
	},
	special: {
		hover: {
			primary: '#F8FAFD',
			secondary: '#F0F4FB',
		},
		tag: {
			blue: '#0FB3FA',
			green: '#60D87C',
			orange: '#F3AE45',
			red: '#F88662',
		},
		table: {
			row: '#F8FAFD',
			hover: '#F3F8FF',
			divider: '#E5E9F5',
		},
		pagination: {
			pages: '#3D3D3D',
			active: '#0F9CFA',
		},
	},
};
