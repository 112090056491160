import { format } from 'date-fns';
import dayjs from 'dayjs';
import _debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TechCardsTable } from '@/entities';
import type { FiltersForm, ITechCardListRequest } from '@/shared/core';
import { TECH_CARD_NEW, UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { useGetTechCardsExcelMutation, useGetTechCardsQuery } from '@/shared/state/api';

import { MainContentLoader, Search, showErrorToast } from '@/shared';
import { FilterModal } from '@/widgets';
import { Button, chakra, Spinner, TextHeader, useToast } from '@chakra-ui-kraud/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { MadIcon } from 'madsoft-icons';

import { Layout } from '@/widgets';
import styles from './tech-cards-page.module.scss';

export const TechCardsPage: FC = () => {
	const [queryParams, setQueryParams] = useState<ITechCardListRequest>({
		page_size: 32,
		current_page: 1,
		order_by: ['created_at', 'number'],
		order_dir: 'desc',
	});

	const toast = useToast();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [getCardsExcelByFilters, { isLoading: isGetExcelLoading }] = useGetTechCardsExcelMutation();

	const [searchTerm, setSearchTerm] = useState<string>('');

	const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
	const {
		data,
		isLoading,
		isFetching,
		isError: isGetError,
	} = useGetTechCardsQuery(
		{
			...queryParams,
			order_by: queryParams.order_by === 'product' ? 'product_catalog.name' : queryParams.order_by,
		},
		{ refetchOnMountOrArgChange: true },
	);

	useEffect(() => {
		if (isGetError) showErrorToast(toast, { description: 'При получении Техкарт произошла ошибка' });
	}, [isGetError]);

	const navigate = useNavigate();

	const handleCreateClick = () => {
		navigate(TECH_CARD_NEW);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((p) => ({
			...p,
			string_to_search: e.target.value.trim() || undefined,
			current_page: 1,
		}));
	};

	const debouncedSearch = useCallback(_debounce(handleSearch, 700), []);

	const handleFilterFormSubmit = (values: FiltersForm) => {
		setQueryParams((prev) => ({
			...prev,
			symbol: values?.group ? values?.group : undefined,
			product_name: values?.naming ?? undefined,
			current_operations_names: values?.currOperation ?? undefined,
			product_symbol: values?.labeling ?? undefined,
			statuses: values.statuses ?? undefined,
			wasted: values.wasted ?? undefined,
			created_at_from: values.created_at_from ? dayjs(values.created_at_from).format('YYYY-MM-DD') : undefined,
			created_at_to: values.created_at_to ? dayjs(values.created_at_to).format('YYYY-MM-DD') : undefined,
			storage_at_from: values.storage_at_from ? dayjs(values.storage_at_from).format('YYYY-MM-DD') : undefined,
			storage_at_to: values.storage_at_to ? dayjs(values.storage_at_to).format('YYYY-MM-DD') : undefined,
		}));

		setFiltersModalOpen(false);
	};

	const handleGetListExcelTechCards = useCallback(() => {
		getCardsExcelByFilters(queryParams)
			.unwrap()
			.then((res) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `Map_list_${format(new Date(), 'dd-MM-yyyy')}.xlsx`;
				link.click();
				link.remove();
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, [queryParams]);

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['tech-cards__title']} variant="h1">
						Маршрутные карты
					</TextHeader>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							rightIcon={<ChevronRightIcon />}
							lineHeight="24px"
							onClick={handleCreateClick}
						>
							Создать карту
						</Button>
					)
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchTerm(e.target.value);
						}}
						value={searchTerm}
					/>
				}
				filters={
					<chakra.div display="flex" justifyContent="center" alignItems="center" gap="8px">
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="filter" />
							}
							minW="125px"
							onClick={() => setFiltersModalOpen(true)}
						>
							Фильтры
						</Button>
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								isGetExcelLoading ? (
									<Spinner size="xs" />
								) : (
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-down-tray"
									/>
								)
							}
							minW="125px"
							onClick={handleGetListExcelTechCards}
						>
							{isGetExcelLoading ? 'Обработка данных' : 'Выгрузить данные'}
						</Button>
					</chakra.div>
				}
			>
				{isFetching && !isLoading ? <MainContentLoader /> : <></>}

				<TechCardsTable
					data={data}
					setQueryParams={setQueryParams}
					isLoading={isLoading}
					isStatusLoading={isFetching}
					queryParams={queryParams}
				/>
			</Layout>
			{isFiltersModalOpen && (
				<FilterModal
					isOpen={isFiltersModalOpen}
					onClose={() => setFiltersModalOpen(false)}
					onSubmit={handleFilterFormSubmit}
					defaultValue={queryParams}
				/>
			)}
		</>
	);
};
