import { forwardRef, useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { diff } from 'deep-object-diff';
import { yupResolver } from '@hookform/resolvers/yup';

import { CustomInput, FormDatepicker } from '@/shared';

import { EmployeeFormProps } from './employee-form.types';
import styles from './employee-form.module.scss';
import { format } from 'date-fns';
import { EmployeeFormData } from './model/employee-form.types';
import { employeeFormSchema } from '@/features/employee-form/model/employee-form.schema';

export const EmployeeForm = forwardRef<HTMLFormElement, EmployeeFormProps>(({ onSubmit, selectedEmployee }, ref) => {
	const submitForm = useCallback(
		(data: EmployeeFormData) => {
			const changedV: EmployeeFormData = diff(selectedEmployee ?? {}, data);
			if (Object.keys(changedV)?.length) {
				onSubmit({
					...changedV,
					id: data.id,
				});
				return;
			}
		},
		[onSubmit, selectedEmployee],
	);
	const formProps = useForm<EmployeeFormData>({
		defaultValues: {
			...selectedEmployee,
			position: selectedEmployee?.position ?? '',
		},
		resolver: yupResolver(employeeFormSchema),
	});
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = formProps;

	return (
		<FormProvider {...formProps}>
			<form onSubmit={handleSubmit(submitForm)} className={styles['employee-form']} ref={ref}>
				<Controller
					control={control}
					name="last_name"
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Фамилия"
							defaultValue={selectedEmployee?.last_name}
							showTooltip={!!errors.last_name?.message}
							tooltipContent={errors.last_name?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="first_name"
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Имя"
							defaultValue={selectedEmployee?.first_name}
							showTooltip={!!errors.first_name?.message}
							tooltipContent={errors.first_name?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="middle_name"
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Отчество"
							defaultValue={selectedEmployee?.middle_name}
							showTooltip={!!errors.middle_name?.message}
							tooltipContent={errors.middle_name?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="position"
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Должность"
							defaultValue={selectedEmployee?.position}
							showTooltip={!!errors.position?.message}
							tooltipContent={errors.position?.message}
						/>
					)}
				/>
				<Controller
					control={control}
					name="date_of_work_start"
					render={({ field: { onChange, ref, ...formProps } }) => (
						<FormDatepicker
							inputLabel="Дата начала работы"
							onSelectDate={onChange}
							clearable={false}
							defaultDate={format(new Date(), 'yyyy-MM-dd')}
							{...formProps}
						/>
					)}
				/>
			</form>
		</FormProvider>
	);
});
