import { api } from './api';
import { IMaterial, IProduct, IProductCard } from '@/shared/core';
import queryString from 'query-string';

export const ProductApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProducts: builder.query<
			{ page: number; pages: number; payload: IProduct[]; total: number },
			{ string_to_search?: string; page_size?: number; current_page?: number }
		>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/product_catalog${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Products'],
		}),
		createProduct: builder.mutation<
			void,
			{
				product?: Partial<IProduct>;
				material?: IMaterial;
				operations?: { name: string; step_id: number }[] | undefined;
			}
		>({
			query(body) {
				return {
					url: '/product_catalog',
					method: 'POST',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Products'],
		}),
		updateProduct: builder.mutation<
			void,
			{
				product?: Partial<IProduct>;
				material?: IMaterial;
				operations?: { name: string; step_id: number; id?: number }[] | undefined;
			}
		>({
			query(body) {
				return {
					url: '/product_catalog',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Products'],
		}),
		deleteProduct: builder.mutation<void, number>({
			query(id) {
				return {
					url: `/product_catalog/${id}`,
					method: 'DELETE',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Products'],
		}),
		updateCustomProduct: builder.query<IProductCard, IProductCard>({
			query(body) {
				return {
					url: '/custom_product',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			providesTags: ['Products'],
		}),
	}),
});

export const {
	useLazyGetProductsQuery,
	useGetProductsQuery,
	useUpdateCustomProductQuery,
	useLazyUpdateCustomProductQuery,
	useDeleteProductMutation,
	useCreateProductMutation,
	useUpdateProductMutation,
} = ProductApi;
