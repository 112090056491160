import { ITechMapSymbolCatalogyResponse } from '@/shared/core/interfaces/tech-map-symbol-catalogy-interface';
import { api } from './api';

export const techMapSymbolCatalogyApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTechMapSymbolCatalogy: builder.query<ITechMapSymbolCatalogyResponse[], any>({
			query() {
				return {
					url: '/tech_map_symbol_catalog',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['TechMapSymbolCatalogy'],
		}),
	}),
});

export const { useGetTechMapSymbolCatalogyQuery, useLazyGetTechMapSymbolCatalogyQuery } = techMapSymbolCatalogyApi;
