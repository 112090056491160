import { Dispatch } from 'react';
import { UserFiltersState } from './user-filters.types';

const initialUserFiltersState: UserFiltersState = {
	isFiredToggled: false,
	onlyUsers: false,
	role: 'executor',
};

type Action =
	| { type: 'SET_FIRED_FILTER'; payload: boolean }
	| { type: 'SET_ONLY_USERS_FILTER' }
	| { type: 'RESET_ROLE_FILTER' }
	| { type: 'SET_EXECUTOR_FILTER' }
	| { type: 'RESET_FILTER' };

export type UserFiltersDispatch = Dispatch<Action>;

const userFiltersReducer = (state: UserFiltersState, action: Action): UserFiltersState => {
	switch (action.type) {
		case 'SET_FIRED_FILTER':
			return {
				...state,
				isFiredToggled: action.payload,
			};
		case 'SET_ONLY_USERS_FILTER':
			return {
				...state,
				role: undefined,
				onlyUsers: true,
			};
		case 'RESET_ROLE_FILTER':
			return {
				...state,
				role: undefined,
				onlyUsers: false,
			};
		case 'SET_EXECUTOR_FILTER':
			return {
				...state,
				role: 'executor',
				onlyUsers: false,
			};
		case 'RESET_FILTER':
			return initialUserFiltersState;
		default:
			return state;
	}
};

const setFiredFilter = (dispatch: Dispatch<Action>, payload: boolean) =>
	dispatch({ type: 'SET_FIRED_FILTER', payload });
const setOnlyUsersFilter = (dispatch: Dispatch<Action>) => dispatch({ type: 'SET_ONLY_USERS_FILTER' });
const resetRoleFilter = (dispatch: Dispatch<Action>) => dispatch({ type: 'RESET_ROLE_FILTER' });
const setExecutorFilter = (dispatch: Dispatch<Action>) => dispatch({ type: 'SET_EXECUTOR_FILTER' });
const resetFilter = (dispatch: Dispatch<Action>) => dispatch({ type: 'RESET_FILTER' });

export {
	userFiltersReducer,
	initialUserFiltersState,
	setFiredFilter,
	setOnlyUsersFilter,
	resetRoleFilter,
	setExecutorFilter,
	resetFilter,
};
