import { extendTheme } from '@chakra-ui-kraud/react';
import { fonts } from './fonts';
import { components } from './components';
import { colors } from './colors';
import { shadows } from './shadows';

const overrides = { fonts, components, colors, shadows };

export const theme = extendTheme(overrides);

export { colors } from './colors';
