import { TechCardStatusColors } from '@/shared';
import { MAP_STATUS_TO_HEADER, MAP_STATUS_TO_NAME } from '@/widgets/operation/model/statuses.types';
import { Badge, Text } from '@chakra-ui-kraud/react';
import styles from './form-header.module.scss';
import { FormHeaderProps } from './form-header.types';

export const OperationFormHeader = ({ techMap, title }: FormHeaderProps) => {
	const { number, symbol, status } = techMap;

	return (
		<div className={styles['header']}>
			<div className={styles['top-block']}>
				<Text m="0" color="gray.800" fontSize="18px" fontWeight="700" lineHeight="24px">
					МК № {symbol ? `${symbol}-${number}` : number}
				</Text>
			</div>
			{status && (
				<div className={styles['bottom-block']}>
					<Text color="gray.600" fontSize="14px" m="0" fontWeight="600" lineHeight="20px">
						{title ?? MAP_STATUS_TO_HEADER[status]}
					</Text>
					<Badge
						variant={'solid'}
						background={TechCardStatusColors[status]}
						borderRadius="6px"
						textTransform="initial"
						fontFamily="Raleway"
						lineHeight="16px"
						fontWeight={600}
						padding="4px 8px 4px 8px"
						style={{ width: 'fit-content' }}
					>
						{MAP_STATUS_TO_NAME[status]}
					</Badge>
				</div>
			)}
		</div>
	);
};
