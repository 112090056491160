import { FC, useCallback, useRef, useState } from 'react';

import { useLinkWithExistedTechCardMutation, useLinkWithNewTechCardMutation } from '@/shared/state/api';
import { LinkTechCardModalProps } from './link-tech-card-modal.types';

import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { Loader, SideModal } from '@/shared/components';
import { LinkTechCardForm } from '@/entities';
import { ILinkExistedTechCardData, ILinkNewTechCardData, LINK_TO_TECH_CARD_INFO } from '@/shared';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export const LinkTechCardModal: FC<LinkTechCardModalProps> = ({
	isOpen,
	title,
	onClose,
	onBlur,
	cardInfo,
	employee,
	variant,
}) => {
	const formRef = useRef<HTMLFormElement>();
	const navigate = useNavigate();
	const toast = useToast();

	const [isLoading, setIsLoading] = useState(false);

	const [linkWithNewCard, { isLoading: isLinkWithNewMkLoading }] = useLinkWithNewTechCardMutation();
	const [linkWithExistedCard, { isLoading: isLinkWithExistedMkLoading }] = useLinkWithExistedTechCardMutation();

	const {
		setError,
		formState: { errors },
		clearErrors,
	} = useForm<ILinkNewTechCardData | ILinkExistedTechCardData>({});

	const handleSubmit = useCallback(
		(data: ILinkNewTechCardData | ILinkExistedTechCardData) => {
			if (variant === 'create') {
				setIsLoading(true);
				// связь с созданием новой карты
				linkWithNewCard(data as ILinkNewTechCardData)
					.unwrap()
					.then((res) => {
						navigate(LINK_TO_TECH_CARD_INFO(res.number));
						showSuccessToast(toast, { description: 'Связь с новой картой успешно создана' });
						window.location.reload();
					})
					.catch((err) => {
						console.log(err);

						if (err.status === 475) {
							showErrorToast(toast, {
								description: 'В текущей карте отсутствует значение в "ИТОГО Расход материала, м"',
							});
							return;
						}
						showErrorToast(toast, { description: 'Не удалось создать связь с новой картой' });
					})
					.finally(() => {
						setIsLoading(false);
						onClose();
					});
			} else {
				// сязь с существующей картой
				linkWithExistedCard(data as ILinkExistedTechCardData)
					.unwrap()
					.then((res) => {
						navigate(LINK_TO_TECH_CARD_INFO(res.number));
						showSuccessToast(toast, { description: `Связь с картой №${res.number} успешно создана` });
						window.location.reload();
						onClose();
					})
					.catch((err) => {
						console.log(err);

						if (err.status === 422) {
							showErrorToast(toast, {
								description: 'Некорректное количество грамм/штук',
							});
							return;
						}

						if (err.status === 473) {
							showErrorToast(toast, {
								description: 'У выбранной МК нет текущей операции или статус выбранной МК не "В работе',
							});

							onClose();

							return;
						}
						if (err.status === 474) {
							setError('children_tech_map_id', {
								message: 'Вы не можете создать связь с этой МК, потому что детали пришли из неё',
							});
							return;
						}
						showErrorToast(toast, { description: 'Не удалось создать связь c картой' });
					});
			}
		},
		[variant],
	);

	return !isLoading ? (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			maxWidth="485px"
			header={null}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							onClose();
						}}
					>
						Отменить
					</Button>

					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current && formRef?.current?.submitForm();
						}}
						isDisabled={isLinkWithNewMkLoading || isLinkWithExistedMkLoading}
					>
						Создать
					</Button>
				</ButtonGroup>
			}
			onBlur={onBlur ?? onClose}
		>
			<LinkTechCardForm
				cardInfo={cardInfo}
				employee={employee}
				reference={formRef}
				onSubmit={handleSubmit}
				variant={variant}
				tooltipErrorContent={errors}
				resetTooltipErrors={clearErrors}
			/>
		</SideModal>
	) : (
		<Loader />
	);
};
