import { IChangePassword, IUser } from '@/shared/core';
import queryString from 'query-string';
import { api } from './api';

export const UsersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getUsers: builder.query<
			{ page: number; pageSize: number; payload: IUser[]; total: number },
			{ string_to_search?: string; page_size?: number; current_page?: number }
		>({
			query(params) {
				const searchParams = queryString.stringify(params);
				return {
					url: `/admin/user/${params ? '?'.concat(searchParams) : ''}`,
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Users'],
		}),
		createUser: builder.mutation<void, IUser>({
			query(body) {
				return {
					url: '/admin/user/',
					method: 'POST',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Users'],
		}),
		updateUser: builder.mutation<void, IUser>({
			query(body) {
				return {
					url: '/admin/user/',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Users'],
		}),
		deleteUser: builder.mutation<void, number>({
			query(id) {
				return {
					url: `/admin/user/${id}`,
					method: 'DELETE',
					credentials: 'include',
				};
			},
			invalidatesTags: ['Users'],
		}),
		changePassword: builder.mutation<void, IChangePassword>({
			query(body) {
				return {
					url: '/user/change_password',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Users'],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useLazyGetUsersQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useChangePasswordMutation,
} = UsersApi;
