import { FC } from 'react';
import dayjs from 'dayjs';

import { ModalOperationProps } from './modal-operation.types';
import { CancelOperationType } from '../model/operations.types';

import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	chakra,
	ModalFooter,
	Button,
	Text,
} from '@chakra-ui-kraud/react';
import { DatePicker, Select } from 'antd';

import styles from './modal-operation.module.scss';

export const ModalOperation: FC<ModalOperationProps> = ({ variant, data, setData, employee, onSubmit, children }) => {
	return (
		<Modal
			size="4xl"
			isOpen={data.isModalOpen}
			isCentered
			onClose={() =>
				setData((prev) => {
					return { ...prev, isModalOpen: false };
				})
			}
		>
			<ModalOverlay />
			<ModalContent maxW="450px">
				<ModalHeader>{variant === 'cancel' ? 'Отмена операции' : 'Возобновление операции'}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<chakra.div display="flex" flexDirection="column" gap="10px">
						<chakra.div mb="5px">
							{variant === 'cancel'
								? 'Выберите ответственного за отмену операции:'
								: 'Возобновление операции:'}
							<span style={{ fontWeight: '500' }}>{` ${data.operation?.name}`} </span>
						</chakra.div>
						{variant === 'cancel' && (
							<chakra.div display="flex" flexDirection="column" gap="10px">
								<Select
									tabIndex={7}
									filterOption={(input, option) =>
										(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
									}
									allowClear
									showSearch
									autoFocus={false}
									placeholder="Выберите ФИО"
									value={(data as CancelOperationType).closingEmployeeFio}
									onSelect={(e) => {
										setData((prev) => {
											return {
												...prev,
												closingEmployeeFio:
													employee?.find((el) => Number(el.value) === Number(e))?.label ?? '',
											};
										});
									}}
									options={employee}
									style={{
										width: '100%',
										borderRadius: 'var(--chakra-radii-base)',
										border: '1px solid #E2E8F0',
									}}
									dropdownStyle={{ zIndex: 10000 }}
									onClear={() => {
										setData((prev) => {
											return {
												...prev,
												closingEmployeeFio: '',
											};
										});
									}}
								/>
								<chakra.div display="flex" justifyContent="space-between" gap="10px">
									<span>Дата отмены операции:</span>
									<DatePicker
										// status={error ? 'error' : ''}
										onChange={(date) =>
											setData((prev) => {
												return { ...prev, closingDate: dayjs(date).format() };
											})
										}
										format={'DD.MM.YY'}
										defaultValue={dayjs()}
										name={'cancel_operation_date'}
										dropdownClassName={styles['calendar-dropdown']}
									/>
								</chakra.div>
							</chakra.div>
						)}
					</chakra.div>
				</ModalBody>
				<ModalFooter>
					<Button
						variant="ghost"
						colorScheme="tertiary"
						onClick={() =>
							setData((prev) => {
								return { ...prev, isModalOpen: false };
							})
						}
					>
						{variant === 'cancel' ? 'Назад' : 'Отменить возобновление'}
					</Button>
					<Button
						isDisabled={variant === 'cancel' ? !(data as CancelOperationType).closingEmployeeFio : false}
						variant={'solid'}
						mr={3}
						onClick={onSubmit}
					>
						{variant === 'cancel' ? 'Отменить операцию' : 'Возобновить'}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
