import { Input, InputGroup, InputLeftElement } from '@chakra-ui-kraud/react';
import { SearchProps } from './search.types';
import { MadIcon } from 'madsoft-icons';
import { FC } from 'react';

export const Search: FC<SearchProps> = ({ handleChange, value }) => {
	return (
		<InputGroup size="sm">
			<Input
				backgroundColor="transparent"
				onChange={handleChange}
				value={value}
				placeholder="Найти..."
				_placeholder={{ color: 'gray.400' }}
				minWidth="100px"
				maxWidth="222px"
				paddingTop="6px"
				paddingBottom="6px"
				lineHeight="20px"
			/>
			<InputLeftElement color="gray.300">
				<MadIcon module="basic" mode="default" size="default" type="outline" name="magnifying-glass" />
			</InputLeftElement>
		</InputGroup>
	);
};
