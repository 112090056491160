import { Button, chakra } from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { NotificationProps } from '@/shared/components/notification';
import styles from './notification.module.scss';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DotIcon } from '@/shared';

export const Notification: FC<NotificationProps> = ({
	variant = 'rounded',
	isRead,
	superTitle,
	datetime,
	title,
	description,
	body,
	onClick,
	onReject,
	onAccept,
	showActions,
	rejectText,
	acceptText,
}) => {
	const formattedDatetime =
		dayjs(Date.now()).diff(datetime, 'day') > 2 ? dayjs(datetime).format('DD.MM.YYYY') : dayjs(datetime).fromNow();

	return (
		<chakra.div
			className={clsx(styles['notification'], {
				[styles['notification_rounded']]: variant === 'rounded',
				[styles['notification_unread']]: !isRead,
			})}
			onClick={onClick}
		>
			{!isRead && (
				<div className={styles['notification__icon']}>
					<DotIcon />
				</div>
			)}
			{superTitle && <div className={styles['notification__super-title']}>{superTitle}</div>}
			{datetime && <div className={styles['notification__date']}>{formattedDatetime}</div>}
			<div className={styles['notification__title']}>{title}</div>
			<div className={styles['notification__body']}>{body}</div>
			{description && <div className={styles['notification__description']}>{description}</div>}
			{showActions && (
				<chakra.div className={styles['notification__footer']}>
					<Button variant="outlined" colorScheme="tertiary" onClick={onReject} size="xs">
						{rejectText}
					</Button>
					<Button size="xs" variant="solid" colorScheme="primary" onClick={onAccept}>
						{acceptText}
					</Button>
				</chakra.div>
			)}
		</chakra.div>
	);
};
