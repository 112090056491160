import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useToast,
} from '@chakra-ui-kraud/react';
import { NotificationSideBarProps } from '../model/notification-sidebar.types';
import { FC, useEffect } from 'react';
import { useGetUserNotificationsQuery, useReadNotificationMutation } from '@/shared/state/api/swagger';
import { IssueNotification } from '@/entities/issue';
import { showErrorToast, showSuccessToast } from '@/shared';
import { isEmpty } from 'lodash';

export const NotificationModal: FC<NotificationSideBarProps> = ({ isOpen, onClose }) => {
	const {
		data: notifications = [],
		isFetching,
		isError,
	} = useGetUserNotificationsQuery(undefined, { refetchOnMountOrArgChange: true });
	const toast = useToast();

	useEffect(() => {
		if (isError) {
			showErrorToast(toast, {
				description: 'Не удалось загрузить список уведомлений',
			});
		}
	}, [isError]);

	const [readNotification, { isLoading }] = useReadNotificationMutation();
	const handleReadAll = () => {
		const unreadNotifications = notifications.filter((item) => !item.is_read);

		if (isEmpty(unreadNotifications)) {
			return;
		}

		const promises = unreadNotifications.map((item) => readNotification({ notificationId: item.id }).unwrap());

		Promise.all(promises)
			.then(() => {
				showSuccessToast(toast, {
					description: 'Уведомления успешно прочитаны!',
				});
			})
			.catch(() => {
				showErrorToast(toast, {
					description: 'При прочтении уведомлений произошла ошибка!',
				});
			});
	};

	return (
		<Modal size="xl" variant="side" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent width="auto">
				<ModalHeader alignItems="center">
					Уведомления
					<Flex gap={2} alignItems="center">
						<Button
							variant="ghost"
							color="primary.400"
							textDecoration="none"
							padding="6px 12px"
							fontSize="sm"
							display="grid"
							placeItems="center"
							disabled={isLoading}
							onClick={handleReadAll}
						>
							Отметить как прочитанные
						</Button>
						<ModalCloseButton display="grid" placeItems="center" size="sm" />
					</Flex>
				</ModalHeader>
				<ModalBody>
					{notifications.map(({ data, id, ...fields }) => (
						<IssueNotification id={id} key={id} isLoading={isFetching} {...data} {...fields} />
					))}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
