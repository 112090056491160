import { generatedApi as api } from './generated-api';
export const addTagTypes = [
	'Material_catalog endpoints',
	'Operation_type_catalog endpoints',
	'Operation endpoints',
	'Product_catalog endpoints',
	'Tech_map endpoints',
	'Storage_log endpoints',
	'User endpoints',
	'Auth endpoints',
	'Num_catalog endpoints',
	'SGDOut endpoints',
	'Plan endpoints',
	'Custom material endpoints',
	'Custom product endpoints',
	'CustomNum endpoints',
	'Employee_catalog endpoints',
	'Tech_map_symbol_catalog endpoints',
	'Admin endpoints',
	'Issue endpoints',
	'notification endpoints',
	'File endpoints',
	'development',
] as const;
const injectedRtkApi = api
	.enhanceEndpoints({
		addTagTypes,
	})
	.injectEndpoints({
		endpoints: (build) => ({
			getMaterials: build.query<GetMaterialsApiResponse, GetMaterialsApiArg>({
				query: () => ({ url: `/material_catalog` }),
				providesTags: ['Material_catalog endpoints'],
			}),
			createMaterial: build.mutation<CreateMaterialApiResponse, CreateMaterialApiArg>({
				query: (queryArg) => ({ url: `/material_catalog`, method: 'POST', body: queryArg.material }),
				invalidatesTags: ['Material_catalog endpoints'],
			}),
			getMaterial: build.query<GetMaterialApiResponse, GetMaterialApiArg>({
				query: (queryArg) => ({ url: `/material_catalog/${queryArg.materialId}` }),
				providesTags: ['Material_catalog endpoints'],
			}),
			getOperationTypes: build.query<GetOperationTypesApiResponse, GetOperationTypesApiArg>({
				query: () => ({ url: `/operation_type_catalog` }),
				providesTags: ['Operation_type_catalog endpoints'],
			}),
			createOperationType: build.mutation<CreateOperationTypeApiResponse, CreateOperationTypeApiArg>({
				query: (queryArg) => ({
					url: `/operation_type_catalog`,
					method: 'POST',
					body: queryArg.operationTypeCatalogCreate,
				}),
				invalidatesTags: ['Operation_type_catalog endpoints'],
			}),
			getOperationType: build.query<GetOperationTypeApiResponse, GetOperationTypeApiArg>({
				query: (queryArg) => ({ url: `/operation_type_catalog/${queryArg.operationTypeId}` }),
				providesTags: ['Operation_type_catalog endpoints'],
			}),
			getOperations: build.query<GetOperationsApiResponse, GetOperationsApiArg>({
				query: () => ({ url: `/operation` }),
				providesTags: ['Operation endpoints'],
			}),
			updateOperations: build.mutation<UpdateOperationsApiResponse, UpdateOperationsApiArg>({
				query: (queryArg) => ({ url: `/operation`, method: 'PUT', body: queryArg.bodyUpdateOperations }),
				invalidatesTags: ['Operation endpoints'],
			}),
			createOperation: build.mutation<CreateOperationApiResponse, CreateOperationApiArg>({
				query: (queryArg) => ({ url: `/operation`, method: 'POST', body: queryArg.operationCreate }),
				invalidatesTags: ['Operation endpoints'],
			}),
			getDefaultOperations: build.query<GetDefaultOperationsApiResponse, GetDefaultOperationsApiArg>({
				query: () => ({ url: `/operation/default` }),
				providesTags: ['Operation endpoints'],
			}),
			getCurrentOperationsNames: build.query<
				GetCurrentOperationsNamesApiResponse,
				GetCurrentOperationsNamesApiArg
			>({
				query: () => ({ url: `/operation/current_list` }),
				providesTags: ['Operation endpoints'],
			}),
			getOperation: build.query<GetOperationApiResponse, GetOperationApiArg>({
				query: (queryArg) => ({ url: `/operation/${queryArg.operationId}` }),
				providesTags: ['Operation endpoints'],
			}),
			getOperationLinkHistory: build.query<GetOperationLinkHistoryApiResponse, GetOperationLinkHistoryApiArg>({
				query: (queryArg) => ({ url: `/operation/link_history/${queryArg.operationId}` }),
				providesTags: ['Operation endpoints'],
			}),
			updateOperationsOperator: build.mutation<
				UpdateOperationsOperatorApiResponse,
				UpdateOperationsOperatorApiArg
			>({
				query: (queryArg) => ({
					url: `/operation/operator`,
					method: 'PUT',
					body: queryArg.bodyUpdateOperationsOperator,
				}),
				invalidatesTags: ['Operation endpoints'],
			}),
			cancelOperation: build.mutation<CancelOperationApiResponse, CancelOperationApiArg>({
				query: (queryArg) => ({
					url: `/operation/cancel`,
					method: 'POST',
					body: queryArg.cancelOperationRequest,
				}),
				invalidatesTags: ['Operation endpoints'],
			}),
			renewalOperation: build.mutation<RenewalOperationApiResponse, RenewalOperationApiArg>({
				query: (queryArg) => ({
					url: `/operation/renewal`,
					method: 'POST',
					body: queryArg.renewalOperationRequest,
				}),
				invalidatesTags: ['Operation endpoints'],
			}),
			getProducts: build.query<GetProductsApiResponse, GetProductsApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			updateProduct: build.mutation<UpdateProductApiResponse, UpdateProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog`, method: 'PUT', body: queryArg.bodyUpdateProduct }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			createProduct: build.mutation<CreateProductApiResponse, CreateProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog`, method: 'POST', body: queryArg.bodyCreateProduct }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getProduct: build.query<GetProductApiResponse, GetProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.productId}` }),
				providesTags: ['Product_catalog endpoints'],
			}),
			deleteProduct: build.mutation<DeleteProductApiResponse, DeleteProductApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.productId}`, method: 'DELETE' }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getProductNamesForFilter: build.query<GetProductNamesForFilterApiResponse, GetProductNamesForFilterApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog/filter/name`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			getProductSymbolForFilter: build.query<
				GetProductSymbolForFilterApiResponse,
				GetProductSymbolForFilterApiArg
			>({
				query: (queryArg) => ({
					url: `/product_catalog/filter/symbol`,
					params: { string_to_search: queryArg.stringToSearch },
				}),
				providesTags: ['Product_catalog endpoints'],
			}),
			addFileToDetail: build.mutation<AddFileToDetailApiResponse, AddFileToDetailApiArg>({
				query: (queryArg) => ({
					url: `/product_catalog/${queryArg.id}/file`,
					method: 'POST',
					body: queryArg.bodyAddFileToDetail,
				}),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			deleteFileForDetail: build.mutation<DeleteFileForDetailApiResponse, DeleteFileForDetailApiArg>({
				query: (queryArg) => ({ url: `/product_catalog/${queryArg.id}/file`, method: 'DELETE' }),
				invalidatesTags: ['Product_catalog endpoints'],
			}),
			getTechMaps: build.query<GetTechMapsApiResponse, GetTechMapsApiArg>({
				query: (queryArg) => ({
					url: `/tech_map`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						product_name: queryArg.productName,
						product_symbol: queryArg.productSymbol,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						count_number_from: queryArg.countNumberFrom,
						count_number_to: queryArg.countNumberTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Tech_map endpoints'],
			}),
			updateTechMap: build.mutation<UpdateTechMapApiResponse, UpdateTechMapApiArg>({
				query: (queryArg) => ({ url: `/tech_map`, method: 'PUT', body: queryArg.techMapUpdateRequest }),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			createTechMap: build.mutation<CreateTechMapApiResponse, CreateTechMapApiArg>({
				query: (queryArg) => ({
					url: `/tech_map`,
					method: 'POST',
					body: queryArg.techMapRequestCreate,
					headers: {
						'x-disable-catalog-num-remainder-check': queryArg['x-disable-catalog-num-remainder-check'],
					},
				}),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			getTechMap: build.query<GetTechMapApiResponse, GetTechMapApiArg>({
				query: (queryArg) => ({ url: `/tech_map/${queryArg.techMapId}` }),
				providesTags: ['Tech_map endpoints'],
			}),
			getTechMapGraph: build.query<GetTechMapGraphApiResponse, GetTechMapGraphApiArg>({
				query: (queryArg) => ({ url: `/tech_map/get_graph/number/${queryArg.techMapNumber}` }),
				providesTags: ['Tech_map endpoints'],
			}),
			getTechMapByNumber: build.query<GetTechMapByNumberApiResponse, GetTechMapByNumberApiArg>({
				query: (queryArg) => ({ url: `/tech_map/number/${queryArg.techMapNumber}` }),
				providesTags: ['Tech_map endpoints'],
			}),
			changeStatus: build.mutation<ChangeStatusApiResponse, ChangeStatusApiArg>({
				query: (queryArg) => ({
					url: `/tech_map/change_status`,
					method: 'POST',
					body: queryArg.techMapChangeStatusRequest,
				}),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			getTechMapExcel: build.mutation<GetTechMapExcelApiResponse, GetTechMapExcelApiArg>({
				query: (queryArg) => ({ url: `/tech_map/get_excel/${queryArg.techMapNumber}`, method: 'GET' }),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			createLinkedTechMapWithNew: build.mutation<
				CreateLinkedTechMapWithNewApiResponse,
				CreateLinkedTechMapWithNewApiArg
			>({
				query: (queryArg) => ({
					url: `/tech_map/linked/new`,
					method: 'POST',
					body: queryArg.techMapLinkedRequestWithNewCreate,
					headers: {
						'x-disable-catalog-num-remainder-check': queryArg['x-disable-catalog-num-remainder-check'],
					},
				}),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			createLinkedTechMapWithExisted: build.mutation<
				CreateLinkedTechMapWithExistedApiResponse,
				CreateLinkedTechMapWithExistedApiArg
			>({
				query: (queryArg) => ({
					url: `/tech_map/linked/existed`,
					method: 'POST',
					body: queryArg.techMapLinkedRequestWithExistedCreate,
				}),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			getTechMapListExcel: build.mutation<GetTechMapListExcelApiResponse, GetTechMapListExcelApiArg>({
				query: (queryArg) => ({
					url: `/tech_map/get_excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						id: queryArg.id,
						number: queryArg['number'],
						symbol: queryArg['symbol'],
						product_name: queryArg.productName,
						product_symbol: queryArg.productSymbol,
						statuses: queryArg.statuses,
						wasted: queryArg.wasted,
						count_number_from: queryArg.countNumberFrom,
						count_number_to: queryArg.countNumberTo,
						created_at_from: queryArg.createdAtFrom,
						created_at_to: queryArg.createdAtTo,
						storage_at_from: queryArg.storageAtFrom,
						storage_at_to: queryArg.storageAtTo,
						current_operations_names: queryArg.currentOperationsNames,
					},
				}),
				invalidatesTags: ['Tech_map endpoints'],
			}),
			getTechMapStatusList: build.query<GetTechMapStatusListApiResponse, GetTechMapStatusListApiArg>({
				query: () => ({ url: `/tech_map/status/list/` }),
				providesTags: ['Tech_map endpoints'],
			}),
			getInProgressTechMaps: build.query<GetInProgressTechMapsApiResponse, GetInProgressTechMapsApiArg>({
				query: () => ({ url: `/tech_map/in_progress/` }),
				providesTags: ['Tech_map endpoints'],
			}),
			getStorageLogs: build.query<GetStorageLogsApiResponse, GetStorageLogsApiArg>({
				query: () => ({ url: `/storage_log` }),
				providesTags: ['Storage_log endpoints'],
			}),
			updateStorageLog: build.mutation<UpdateStorageLogApiResponse, UpdateStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log`, method: 'PUT', body: queryArg.storageLogUpdate }),
				invalidatesTags: ['Storage_log endpoints'],
			}),
			createStorageLog: build.mutation<CreateStorageLogApiResponse, CreateStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log`, method: 'POST', body: queryArg.storageLogCreate }),
				invalidatesTags: ['Storage_log endpoints'],
			}),
			getStorageLog: build.query<GetStorageLogApiResponse, GetStorageLogApiArg>({
				query: (queryArg) => ({ url: `/storage_log/${queryArg.storageLogId}` }),
				providesTags: ['Storage_log endpoints'],
			}),
			getProfile: build.query<GetProfileApiResponse, GetProfileApiArg>({
				query: () => ({ url: `/user/profile` }),
				providesTags: ['User endpoints'],
			}),
			userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
				query: (queryArg) => ({ url: `/user/profile`, method: 'PUT', body: queryArg.userUpdateRequest }),
				invalidatesTags: ['User endpoints'],
			}),
			userChangePassword: build.mutation<UserChangePasswordApiResponse, UserChangePasswordApiArg>({
				query: (queryArg) => ({
					url: `/user/change_password`,
					method: 'PUT',
					body: queryArg.userUpdatePasswordRequest,
				}),
				invalidatesTags: ['User endpoints'],
			}),
			auth: build.mutation<AuthApiResponse, AuthApiArg>({
				query: (queryArg) => ({ url: `/login`, method: 'POST', body: queryArg.bodyAuth }),
				invalidatesTags: ['Auth endpoints'],
			}),
			refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
				query: (queryArg) => ({ url: `/refresh-token`, method: 'POST', body: queryArg.refreshToken }),
				invalidatesTags: ['Auth endpoints'],
			}),
			logout: build.query<LogoutApiResponse, LogoutApiArg>({
				query: () => ({ url: `/logout` }),
				providesTags: ['Auth endpoints'],
			}),
			resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
				query: (queryArg) => ({ url: `/reset_password`, method: 'POST', params: { email: queryArg.email } }),
				invalidatesTags: ['Auth endpoints'],
			}),
			authChangePassword: build.mutation<AuthChangePasswordApiResponse, AuthChangePasswordApiArg>({
				query: (queryArg) => ({
					url: `/change_password`,
					method: 'POST',
					params: { reset_password_hash: queryArg.resetPasswordHash, new_password: queryArg.newPassword },
				}),
				invalidatesTags: ['Auth endpoints'],
			}),
			getNums: build.query<GetNumsApiResponse, GetNumsApiArg>({
				query: (queryArg) => ({
					url: `/num_catalog`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Num_catalog endpoints'],
			}),
			putNumCatalog: build.mutation<PutNumCatalogApiResponse, PutNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog`, method: 'PUT', body: queryArg.numCatalogUpdateRequest }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			postNumCatalog: build.mutation<PostNumCatalogApiResponse, PostNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog`, method: 'POST', body: queryArg.numCatalogCreateRequest }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			getNumById: build.query<GetNumByIdApiResponse, GetNumByIdApiArg>({
				query: (queryArg) => ({ url: `/num_catalog/${queryArg.numCatalogId}` }),
				providesTags: ['Num_catalog endpoints'],
			}),
			deleteNumCatalog: build.mutation<DeleteNumCatalogApiResponse, DeleteNumCatalogApiArg>({
				query: (queryArg) => ({ url: `/num_catalog/${queryArg.numCatalogId}`, method: 'DELETE' }),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			getNumCatalogListExcel: build.mutation<GetNumCatalogListExcelApiResponse, GetNumCatalogListExcelApiArg>({
				query: (queryArg) => ({
					url: `/num_catalog/excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				invalidatesTags: ['Num_catalog endpoints'],
			}),
			putSgdOut: build.mutation<PutSgdOutApiResponse, PutSgdOutApiArg>({
				query: (queryArg) => ({ url: `/sgd_out`, method: 'PUT', body: queryArg.payload }),
				invalidatesTags: ['SGDOut endpoints'],
			}),
			postSgdOut: build.mutation<PostSgdOutApiResponse, PostSgdOutApiArg>({
				query: (queryArg) => ({ url: `/sgd_out`, method: 'POST', body: queryArg.payload }),
				invalidatesTags: ['SGDOut endpoints'],
			}),
			putSgdOutOperator: build.mutation<PutSgdOutOperatorApiResponse, PutSgdOutOperatorApiArg>({
				query: (queryArg) => ({
					url: `/sgd_out/operator`,
					method: 'PUT',
					body: queryArg.bodyPutSgdOutOperator,
				}),
				invalidatesTags: ['SGDOut endpoints'],
			}),
			patchPlanOperator: build.mutation<PatchPlanOperatorApiResponse, PatchPlanOperatorApiArg>({
				query: (queryArg) => ({
					url: `/plan/operator`,
					method: 'PUT',
					body: queryArg.planUpdateOperatorRequest,
				}),
				invalidatesTags: ['Plan endpoints'],
			}),
			putCustomMaterial: build.mutation<PutCustomMaterialApiResponse, PutCustomMaterialApiArg>({
				query: (queryArg) => ({
					url: `/custom_material`,
					method: 'PUT',
					body: queryArg.customMaterialRequestUpdate,
				}),
				invalidatesTags: ['Custom material endpoints'],
			}),
			putCustomProduct: build.mutation<PutCustomProductApiResponse, PutCustomProductApiArg>({
				query: (queryArg) => ({
					url: `/custom_product`,
					method: 'PUT',
					body: queryArg.customProductRequestUpdate,
				}),
				invalidatesTags: ['Custom product endpoints'],
			}),
			updateNum: build.mutation<UpdateNumApiResponse, UpdateNumApiArg>({
				query: (queryArg) => ({ url: `/custom_num`, method: 'PUT', body: queryArg.customNumUpdateRequest }),
				invalidatesTags: ['CustomNum endpoints'],
			}),
			getEmployees: build.query<GetEmployeesApiResponse, GetEmployeesApiArg>({
				query: () => ({ url: `/employee_catalog` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getEmployeeInitials: build.query<GetEmployeeInitialsApiResponse, GetEmployeeInitialsApiArg>({
				query: () => ({ url: `/employee_catalog/initials` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getEmployee: build.query<GetEmployeeApiResponse, GetEmployeeApiArg>({
				query: (queryArg) => ({ url: `/employee_catalog/${queryArg.employeeId}` }),
				providesTags: ['Employee_catalog endpoints'],
			}),
			getTechMapSymbolCatalog: build.query<GetTechMapSymbolCatalogApiResponse, GetTechMapSymbolCatalogApiArg>({
				query: () => ({ url: `/tech_map_symbol_catalog` }),
				providesTags: ['Tech_map_symbol_catalog endpoints'],
			}),
			updateMaterial: build.mutation<UpdateMaterialApiResponse, UpdateMaterialApiArg>({
				query: (queryArg) => ({
					url: `/admin/material_catalog`,
					method: 'PUT',
					body: queryArg.materialCatalogUpdate,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			updateOperationType: build.mutation<UpdateOperationTypeApiResponse, UpdateOperationTypeApiArg>({
				query: (queryArg) => ({
					url: `/admin/operation_type_catalog`,
					method: 'PUT',
					body: queryArg.operationTypeCatalogUpdate,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			putPlan: build.mutation<PutPlanApiResponse, PutPlanApiArg>({
				query: (queryArg) => ({ url: `/admin/plan`, method: 'PUT', body: queryArg.planUpdateRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			cancelTechmap: build.mutation<CancelTechmapApiResponse, CancelTechmapApiArg>({
				query: (queryArg) => ({
					url: `/admin/tech_map/cancel`,
					method: 'POST',
					body: queryArg.techMapChangeStatusAdminRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			deleteTechmap: build.mutation<DeleteTechmapApiResponse, DeleteTechmapApiArg>({
				query: (queryArg) => ({
					url: `/admin/tech_map/`,
					method: 'DELETE',
					params: { tech_map_number: queryArg.techMapNumber },
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/`,
					params: {
						string_to_search: queryArg.stringToSearch,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						role: queryArg.role,
						is_active: queryArg.isActive,
						only_users: queryArg.onlyUsers,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Admin endpoints'],
			}),
			updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/`, method: 'PUT', body: queryArg.userUpdateAdminRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/`, method: 'POST', body: queryArg.userCreateRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
				query: (queryArg) => ({ url: `/admin/user/${queryArg.id}` }),
				providesTags: ['Admin endpoints'],
			}),
			deleteUserById: build.mutation<DeleteUserByIdApiResponse, DeleteUserByIdApiArg>({
				query: (queryArg) => ({ url: `/admin/user/${queryArg.id}`, method: 'DELETE' }),
				invalidatesTags: ['Admin endpoints'],
			}),
			createUserExecutor: build.mutation<CreateUserExecutorApiResponse, CreateUserExecutorApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/executor`,
					method: 'POST',
					body: queryArg.userExecutorCreateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			fireUser: build.mutation<FireUserApiResponse, FireUserApiArg>({
				query: (queryArg) => ({ url: `/admin/user/fire`, method: 'POST', body: queryArg.userFireRequest }),
				invalidatesTags: ['Admin endpoints'],
			}),
			restoreUser: build.mutation<RestoreUserApiResponse, RestoreUserApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/restore`,
					method: 'POST',
					body: queryArg.userRestoreRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			activateUser: build.mutation<ActivateUserApiResponse, ActivateUserApiArg>({
				query: (queryArg) => ({
					url: `/admin/user/activate`,
					method: 'POST',
					body: queryArg.userActivateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getUserRolesMapping: build.query<GetUserRolesMappingApiResponse, GetUserRolesMappingApiArg>({
				query: () => ({ url: `/admin/user/mapping/role` }),
				providesTags: ['Admin endpoints'],
			}),
			updateEmployeeCatalog: build.mutation<UpdateEmployeeCatalogApiResponse, UpdateEmployeeCatalogApiArg>({
				query: (queryArg) => ({
					url: `/admin/employee_catalog`,
					method: 'PUT',
					body: queryArg.employeeCatalogUpdateRequest,
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			createEmployeeCatalog: build.mutation<CreateEmployeeCatalogApiResponse, CreateEmployeeCatalogApiArg>({
				query: (queryArg) => ({ url: `/admin/employee_catalog`, method: 'POST', body: queryArg.employee }),
				invalidatesTags: ['Admin endpoints'],
			}),
			deleteEmployeeCatalog: build.mutation<DeleteEmployeeCatalogApiResponse, DeleteEmployeeCatalogApiArg>({
				query: (queryArg) => ({
					url: `/admin/employee_catalog/${queryArg.employeeCatalogId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Admin endpoints'],
			}),
			getIssues: build.query<GetIssuesApiResponse, GetIssuesApiArg>({
				query: (queryArg) => ({
					url: `/issue`,
					params: {
						string_to_search: queryArg.stringToSearch,
						type: queryArg['type'],
						status: queryArg.status,
						executor_user_id: queryArg.executorUserId,
						responsible_user_id: queryArg.responsibleUserId,
						product_symbol: queryArg.productSymbol,
						open_at_from: queryArg.openAtFrom,
						open_at_to: queryArg.openAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
						page_size: queryArg.pageSize,
						current_page: queryArg.currentPage,
					},
				}),
				providesTags: ['Issue endpoints'],
			}),
			getIssueStuckOperationDetails: build.query<
				GetIssueStuckOperationDetailsApiResponse,
				GetIssueStuckOperationDetailsApiArg
			>({
				query: (queryArg) => ({ url: `/issue/stuck_operation/${queryArg.issueId}/details` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueWastedLimitDetails: build.query<
				GetIssueWastedLimitDetailsApiResponse,
				GetIssueWastedLimitDetailsApiArg
			>({
				query: (queryArg) => ({ url: `/issue/wasted_limit/${queryArg.issueId}/details` }),
				providesTags: ['Issue endpoints'],
			}),
			createCommentForIssue: build.mutation<CreateCommentForIssueApiResponse, CreateCommentForIssueApiArg>({
				query: (queryArg) => ({
					url: `/issue/${queryArg.issueId}/comment`,
					method: 'POST',
					body: queryArg.issueCommentCreateRequest,
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			changeIssueStatusToProgress: build.mutation<
				ChangeIssueStatusToProgressApiResponse,
				ChangeIssueStatusToProgressApiArg
			>({
				query: (queryArg) => ({ url: `/issue/${queryArg.issueId}/progress`, method: 'POST' }),
				invalidatesTags: ['Issue endpoints'],
			}),
			changeIssueStatusToDone: build.mutation<ChangeIssueStatusToDoneApiResponse, ChangeIssueStatusToDoneApiArg>({
				query: (queryArg) => ({
					url: `/issue/${queryArg.issueId}/done`,
					method: 'POST',
					body: queryArg.issueReportUpdateStatusToClosedRequest,
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			getIssueStatusMapping: build.query<GetIssueStatusMappingApiResponse, GetIssueStatusMappingApiArg>({
				query: () => ({ url: `/issue/mapping/status` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueTypeMapping: build.query<GetIssueTypeMappingApiResponse, GetIssueTypeMappingApiArg>({
				query: () => ({ url: `/issue/mapping/type` }),
				providesTags: ['Issue endpoints'],
			}),
			getIssueListExcel: build.mutation<GetIssueListExcelApiResponse, GetIssueListExcelApiArg>({
				query: (queryArg) => ({
					url: `/issue/excel/list/`,
					method: 'GET',
					params: {
						string_to_search: queryArg.stringToSearch,
						type: queryArg['type'],
						status: queryArg.status,
						executor_user_id: queryArg.executorUserId,
						responsible_user_id: queryArg.responsibleUserId,
						product_symbol: queryArg.productSymbol,
						open_at_from: queryArg.openAtFrom,
						open_at_to: queryArg.openAtTo,
						order_by: queryArg.orderBy,
						order_dir: queryArg.orderDir,
					},
				}),
				invalidatesTags: ['Issue endpoints'],
			}),
			getUserNotifications: build.query<GetUserNotificationsApiResponse, GetUserNotificationsApiArg>({
				query: () => ({ url: `/notification` }),
				providesTags: ['notification endpoints'],
			}),
			getNotificationTypeMapping: build.query<
				GetNotificationTypeMappingApiResponse,
				GetNotificationTypeMappingApiArg
			>({
				query: () => ({ url: `/notification/mapping/type` }),
				providesTags: ['notification endpoints'],
			}),
			getCountOfUnread: build.query<GetCountOfUnreadApiResponse, GetCountOfUnreadApiArg>({
				query: () => ({ url: `/notification/unread_count` }),
				providesTags: ['notification endpoints'],
			}),
			readNotification: build.mutation<ReadNotificationApiResponse, ReadNotificationApiArg>({
				query: (queryArg) => ({
					url: `/notification/read`,
					method: 'POST',
					params: { notification_id: queryArg.notificationId },
				}),
				invalidatesTags: ['notification endpoints'],
			}),
			getFile: build.query<GetFileApiResponse, GetFileApiArg>({
				query: (queryArg) => ({ url: `/file/${queryArg.id}` }),
				providesTags: ['File endpoints'],
			}),
			postSeedDb: build.mutation<PostSeedDbApiResponse, PostSeedDbApiArg>({
				query: () => ({ url: `/development/seed_db`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultProducts: build.mutation<SeedDefaultProductsApiResponse, SeedDefaultProductsApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_products`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultNum: build.mutation<SeedDefaultNumApiResponse, SeedDefaultNumApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_num`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedDefaultEmployee: build.mutation<SeedDefaultEmployeeApiResponse, SeedDefaultEmployeeApiArg>({
				query: () => ({ url: `/development/insert_default_catalog_employee`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			seedUsers: build.mutation<SeedUsersApiResponse, SeedUsersApiArg>({
				query: () => ({ url: `/development/insert_users`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshCurrentOperation: build.mutation<RefreshCurrentOperationApiResponse, RefreshCurrentOperationApiArg>({
				query: () => ({ url: `/development/refresh/current_operation`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshCurrentDetailsCount: build.mutation<
				RefreshCurrentDetailsCountApiResponse,
				RefreshCurrentDetailsCountApiArg
			>({
				query: () => ({ url: `/development/refresh/current_details_count`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshWasted: build.mutation<RefreshWastedApiResponse, RefreshWastedApiArg>({
				query: () => ({ url: `/development/refresh/wasted`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
			refreshQr: build.mutation<RefreshQrApiResponse, RefreshQrApiArg>({
				query: () => ({ url: `/development/refresh/qr`, method: 'POST' }),
				invalidatesTags: ['development'],
			}),
		}),
		overrideExisting: false,
	});
export { injectedRtkApi as coreApi };
export type GetMaterialsApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain[];
export type GetMaterialsApiArg = void;
export type CreateMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type CreateMaterialApiArg = {
	material: MaterialCatalogCreate;
};
export type GetMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type GetMaterialApiArg = {
	materialId: number;
};
export type GetOperationTypesApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain[];
export type GetOperationTypesApiArg = void;
export type CreateOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type CreateOperationTypeApiArg = {
	operationTypeCatalogCreate: OperationTypeCatalogCreate;
};
export type GetOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type GetOperationTypeApiArg = {
	operationTypeId: number;
};
export type GetOperationsApiResponse = /** status 200 Successful Response */ OperationResponse[];
export type GetOperationsApiArg = void;
export type UpdateOperationsApiResponse = /** status 200 Successful Response */ OperationResponse[];
export type UpdateOperationsApiArg = {
	bodyUpdateOperations: BodyUpdateOperations;
};
export type CreateOperationApiResponse = /** status 200 Successful Response */ OperationResponse;
export type CreateOperationApiArg = {
	operationCreate: OperationCreate;
};
export type GetDefaultOperationsApiResponse = /** status 200 Successful Response */ DefaultOperationResponse[];
export type GetDefaultOperationsApiArg = void;
export type GetCurrentOperationsNamesApiResponse = /** status 200 Successful Response */ any;
export type GetCurrentOperationsNamesApiArg = void;
export type GetOperationApiResponse = /** status 200 Successful Response */ OperationResponse;
export type GetOperationApiArg = {
	operationId: number;
};
export type GetOperationLinkHistoryApiResponse = /** status 200 Successful Response */ LinkedOperationsResponse[];
export type GetOperationLinkHistoryApiArg = {
	operationId: number;
};
export type UpdateOperationsOperatorApiResponse = /** status 200 Successful Response */ OperationResponse[];
export type UpdateOperationsOperatorApiArg = {
	bodyUpdateOperationsOperator: BodyUpdateOperationsOperator;
};
export type CancelOperationApiResponse = /** status 200 Successful Response */ boolean;
export type CancelOperationApiArg = {
	cancelOperationRequest: CancelOperationRequest;
};
export type RenewalOperationApiResponse = /** status 200 Successful Response */ boolean;
export type RenewalOperationApiArg = {
	renewalOperationRequest: RenewalOperationRequest;
};
export type GetProductsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsProductCatalogContractsProductCatalogResponse;
export type GetProductsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	pageSize?: number;
	currentPage?: number;
	orderBy?: ProductCatalogSortingFields;
	orderDir?: SortingDirection;
};
export type UpdateProductApiResponse = /** status 202 Successful Response */ ProductCatalogDomain;
export type UpdateProductApiArg = {
	bodyUpdateProduct: BodyUpdateProduct;
};
export type CreateProductApiResponse = /** status 201 Successful Response */ any;
export type CreateProductApiArg = {
	bodyCreateProduct: BodyCreateProduct;
};
export type GetProductApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type GetProductApiArg = {
	productId: number;
};
export type DeleteProductApiResponse = /** status 204 Successful Response */ void;
export type DeleteProductApiArg = {
	productId: number;
};
export type GetProductNamesForFilterApiResponse = /** status 200 Successful Response */ ProductCatalogNameListResponse;
export type GetProductNamesForFilterApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type GetProductSymbolForFilterApiResponse =
	/** status 200 Successful Response */ ProductCatalogSymbolListResponse;
export type GetProductSymbolForFilterApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
};
export type AddFileToDetailApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type AddFileToDetailApiArg = {
	id: number;
	bodyAddFileToDetail: BodyAddFileToDetail;
};
export type DeleteFileForDetailApiResponse = /** status 200 Successful Response */ ProductCatalogResponse;
export type DeleteFileForDetailApiArg = {
	id: number;
};
export type GetTechMapsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsTechMapContractsTechMapResponse;
export type GetTechMapsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: TechMapsSortingFields;
	orderDir?: SortingDirection;
	id?: number;
	number?: number;
	symbol?: string[];
	productName?: string;
	productSymbol?: string;
	statuses?: TechMapStatusForFilter[];
	wasted?: boolean;
	countNumberFrom?: number;
	countNumberTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
	pageSize?: number;
	currentPage?: number;
};
export type UpdateTechMapApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type UpdateTechMapApiArg = {
	techMapUpdateRequest: TechMapUpdateRequest;
};
export type CreateTechMapApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type CreateTechMapApiArg = {
	'x-disable-catalog-num-remainder-check'?: string;
	techMapRequestCreate: TechMapRequestCreate;
};
export type GetTechMapApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type GetTechMapApiArg = {
	techMapId: number;
};
export type GetTechMapGraphApiResponse = /** status 200 Successful Response */ LinkedOperationsItem[];
export type GetTechMapGraphApiArg = {
	techMapNumber: number;
};
export type GetTechMapByNumberApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type GetTechMapByNumberApiArg = {
	techMapNumber: number;
};
export type ChangeStatusApiResponse = /** status 200 Successful Response */ boolean;
export type ChangeStatusApiArg = {
	techMapChangeStatusRequest: TechMapChangeStatusRequest;
};
export type GetTechMapExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetTechMapExcelApiArg = {
	techMapNumber: number;
};
export type CreateLinkedTechMapWithNewApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type CreateLinkedTechMapWithNewApiArg = {
	'x-disable-catalog-num-remainder-check'?: string;
	techMapLinkedRequestWithNewCreate: TechMapLinkedRequestWithNewCreate;
};
export type CreateLinkedTechMapWithExistedApiResponse = /** status 200 Successful Response */ TechMapResponse;
export type CreateLinkedTechMapWithExistedApiArg = {
	techMapLinkedRequestWithExistedCreate: TechMapLinkedRequestWithExistedCreate;
};
export type GetTechMapListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetTechMapListExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: TechMapsSortingFields;
	orderDir?: SortingDirection;
	id?: number;
	number?: number;
	symbol?: string[];
	productName?: string;
	productSymbol?: string;
	statuses?: TechMapStatusForFilter[];
	wasted?: boolean;
	countNumberFrom?: number;
	countNumberTo?: number;
	createdAtFrom?: string;
	createdAtTo?: string;
	storageAtFrom?: string;
	storageAtTo?: string;
	currentOperationsNames?: string[];
};
export type GetTechMapStatusListApiResponse = unknown;
export type GetTechMapStatusListApiArg = void;
export type GetInProgressTechMapsApiResponse = /** status 200 Successful Response */ TechMapTreeItemResponse[];
export type GetInProgressTechMapsApiArg = void;
export type GetStorageLogsApiResponse = /** status 200 Successful Response */ StorageLogDomain[];
export type GetStorageLogsApiArg = void;
export type UpdateStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type UpdateStorageLogApiArg = {
	storageLogUpdate: StorageLogUpdate;
};
export type CreateStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type CreateStorageLogApiArg = {
	storageLogCreate: StorageLogCreate;
};
export type GetStorageLogApiResponse = /** status 200 Successful Response */ StorageLogDomain;
export type GetStorageLogApiArg = {
	storageLogId: number;
};
export type GetProfileApiResponse = /** status 200 Successful Response */ UserBase;
export type GetProfileApiArg = void;
export type UserUpdateApiResponse = /** status 200 Successful Response */ UserResponse;
export type UserUpdateApiArg = {
	userUpdateRequest: UserUpdateRequest;
};
export type UserChangePasswordApiResponse = /** status 200 Successful Response */ UserResponse;
export type UserChangePasswordApiArg = {
	userUpdatePasswordRequest: UserUpdatePasswordRequest;
};
export type AuthApiResponse = /** status 200 Successful Response */ Token;
export type AuthApiArg = {
	bodyAuth: BodyAuth;
};
export type RefreshTokenApiResponse = /** status 200 Successful Response */ Token;
export type RefreshTokenApiArg = {
	refreshToken: RefreshToken;
};
export type LogoutApiResponse = /** status 200 Successful Response */ any;
export type LogoutApiArg = void;
export type ResetPasswordApiResponse = /** status 200 Successful Response */ boolean;
export type ResetPasswordApiArg = {
	email: string;
};
export type AuthChangePasswordApiResponse = /** status 200 Successful Response */ boolean;
export type AuthChangePasswordApiArg = {
	resetPasswordHash: string;
	newPassword: string;
};
export type GetNumsApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsNumCatalogContractsNumCatalogResponse;
export type GetNumsApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: NumCatalogSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type PutNumCatalogApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type PutNumCatalogApiArg = {
	numCatalogUpdateRequest: NumCatalogUpdateRequest;
};
export type PostNumCatalogApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type PostNumCatalogApiArg = {
	numCatalogCreateRequest: NumCatalogCreateRequest;
};
export type GetNumByIdApiResponse = /** status 200 Successful Response */ NumCatalogResponse;
export type GetNumByIdApiArg = {
	numCatalogId: number;
};
export type DeleteNumCatalogApiResponse = /** status 204 Successfully deleted num_catalog */ void;
export type DeleteNumCatalogApiArg = {
	numCatalogId: number;
};
export type GetNumCatalogListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetNumCatalogListExcelApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: NumCatalogSortingFields;
	orderDir?: SortingDirection;
};
export type PutSgdOutApiResponse = /** status 200 Successful Response */ any;
export type PutSgdOutApiArg = {
	payload: SgdOutUpdateRequest[];
};
export type PostSgdOutApiResponse = /** status 200 Successful Response */ any;
export type PostSgdOutApiArg = {
	payload: SgdOutCreateRequest[];
};
export type PutSgdOutOperatorApiResponse = /** status 202 Successful Response */ any;
export type PutSgdOutOperatorApiArg = {
	bodyPutSgdOutOperator: BodyPutSgdOutOperator;
};
export type PatchPlanOperatorApiResponse = /** status 200 Successful Response */ PlanDomain;
export type PatchPlanOperatorApiArg = {
	planUpdateOperatorRequest: PlanUpdateOperatorRequest;
};
export type PutCustomMaterialApiResponse = /** status 200 Successful Response */ CustomMaterialResponse;
export type PutCustomMaterialApiArg = {
	customMaterialRequestUpdate: CustomMaterialRequestUpdate;
};
export type PutCustomProductApiResponse = /** status 200 Successful Response */ CustomProductResponse;
export type PutCustomProductApiArg = {
	customProductRequestUpdate: CustomProductRequestUpdate;
};
export type UpdateNumApiResponse = /** status 200 Successful Response */ CustomNumDomain;
export type UpdateNumApiArg = {
	customNumUpdateRequest: CustomNumUpdateRequest;
};
export type GetEmployeesApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse[];
export type GetEmployeesApiArg = void;
export type GetEmployeeInitialsApiResponse = /** status 200 Successful Response */ EmployeeCatalogInitialsResponse[];
export type GetEmployeeInitialsApiArg = void;
export type GetEmployeeApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type GetEmployeeApiArg = {
	employeeId: number;
};
export type GetTechMapSymbolCatalogApiResponse = /** status 200 Successful Response */ TechMapSymbolCatalogDomain[];
export type GetTechMapSymbolCatalogApiArg = void;
export type UpdateMaterialApiResponse = /** status 200 Successful Response */ MaterialCatalogDomain;
export type UpdateMaterialApiArg = {
	materialCatalogUpdate: MaterialCatalogUpdate;
};
export type UpdateOperationTypeApiResponse = /** status 200 Successful Response */ OperationTypeCatalogDomain;
export type UpdateOperationTypeApiArg = {
	operationTypeCatalogUpdate: OperationTypeCatalogUpdate;
};
export type PutPlanApiResponse = /** status 200 Successful Response */ PlanDomain;
export type PutPlanApiArg = {
	planUpdateRequest: PlanUpdateRequest;
};
export type CancelTechmapApiResponse = /** status 200 Successful Response */ boolean;
export type CancelTechmapApiArg = {
	techMapChangeStatusAdminRequest: TechMapChangeStatusAdminRequest;
};
export type DeleteTechmapApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteTechmapApiArg = {
	techMapNumber: number;
};
export type GetUsersApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsUserContractsUserResponseAdmin;
export type GetUsersApiArg = {
	/** Строка для поиска */
	stringToSearch?: string;
	orderBy?: UserSortingFields;
	orderDir?: SortingDirection;
	role?: UserRoleEnum;
	isActive?: boolean;
	onlyUsers?: boolean;
	pageSize?: number;
	currentPage?: number;
};
export type UpdateUserApiResponse = /** status 200 Successful Response */ UserBase;
export type UpdateUserApiArg = {
	userUpdateAdminRequest: UserUpdateAdminRequest;
};
export type CreateUserApiResponse = /** status 200 Successful Response */ UserBase;
export type CreateUserApiArg = {
	userCreateRequest: UserCreateRequest;
};
export type GetUserByIdApiResponse = /** status 200 Successful Response */ UserResponseAdmin;
export type GetUserByIdApiArg = {
	id: number;
};
export type DeleteUserByIdApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteUserByIdApiArg = {
	id: number;
};
export type CreateUserExecutorApiResponse = /** status 200 Successful Response */ UserBase;
export type CreateUserExecutorApiArg = {
	userExecutorCreateRequest: UserExecutorCreateRequest;
};
export type FireUserApiResponse = /** status 200 Successful Response */ boolean;
export type FireUserApiArg = {
	userFireRequest: UserFireRequest;
};
export type RestoreUserApiResponse = /** status 200 Successful Response */ boolean;
export type RestoreUserApiArg = {
	userRestoreRequest: UserRestoreRequest;
};
export type ActivateUserApiResponse = /** status 200 Successful Response */ boolean;
export type ActivateUserApiArg = {
	userActivateRequest: UserActivateRequest;
};
export type GetUserRolesMappingApiResponse = /** status 200 Successful Response */ {
	[key: string]: string;
};
export type GetUserRolesMappingApiArg = void;
export type UpdateEmployeeCatalogApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type UpdateEmployeeCatalogApiArg = {
	employeeCatalogUpdateRequest: EmployeeCatalogUpdateRequest;
};
export type CreateEmployeeCatalogApiResponse = /** status 200 Successful Response */ EmployeeCatalogResponse;
export type CreateEmployeeCatalogApiArg = {
	employee: EmployeeCatalogCreateRequest;
};
export type DeleteEmployeeCatalogApiResponse = /** status 200 Successful Response */ boolean;
export type DeleteEmployeeCatalogApiArg = {
	employeeCatalogId: number;
};
export type GetIssuesApiResponse =
	/** status 200 Successful Response */ ResponsePaginationApiListAppModelsIssueIssueReportContractsIssueReportResponse;
export type GetIssuesApiArg = {
	/** Строка для поиска по номеру тех. карты */
	stringToSearch?: string;
	type?: IssueReportTypeEnum;
	status?: IssueReportStatusEnum;
	executorUserId?: number;
	responsibleUserId?: number;
	productSymbol?: string;
	openAtFrom?: string;
	openAtTo?: string;
	orderBy?: IssueReportSortingFields;
	orderDir?: SortingDirection;
	pageSize?: number;
	currentPage?: number;
};
export type GetIssueStuckOperationDetailsApiResponse =
	/** status 200 Successful Response */ IssueStuckOperationResponse;
export type GetIssueStuckOperationDetailsApiArg = {
	issueId: number;
};
export type GetIssueWastedLimitDetailsApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type GetIssueWastedLimitDetailsApiArg = {
	issueId: number;
};
export type CreateCommentForIssueApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type CreateCommentForIssueApiArg = {
	issueId: number;
	issueCommentCreateRequest: IssueCommentCreateRequest;
};
export type ChangeIssueStatusToProgressApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type ChangeIssueStatusToProgressApiArg = {
	issueId: number;
};
export type ChangeIssueStatusToDoneApiResponse = /** status 200 Successful Response */ IssueReportResponse;
export type ChangeIssueStatusToDoneApiArg = {
	issueId: number;
	issueReportUpdateStatusToClosedRequest: IssueReportUpdateStatusToClosedRequest;
};
export type GetIssueStatusMappingApiResponse = unknown;
export type GetIssueStatusMappingApiArg = void;
export type GetIssueTypeMappingApiResponse = unknown;
export type GetIssueTypeMappingApiArg = void;
export type GetIssueListExcelApiResponse = /** status 200 Successful Response */ Blob;
export type GetIssueListExcelApiArg = {
	/** Строка для поиска по номеру тех. карты */
	stringToSearch?: string;
	type?: IssueReportTypeEnum;
	status?: IssueReportStatusEnum;
	executorUserId?: number;
	responsibleUserId?: number;
	productSymbol?: string;
	openAtFrom?: string;
	openAtTo?: string;
	orderBy?: IssueReportSortingFields;
	orderDir?: SortingDirection;
};
export type GetUserNotificationsApiResponse = /** status 200 Successful Response */ NotificationResponse[];
export type GetUserNotificationsApiArg = void;
export type GetNotificationTypeMappingApiResponse = /** status 200 Successful Response */ {
	[key: string]: string;
};
export type GetNotificationTypeMappingApiArg = void;
export type GetCountOfUnreadApiResponse = /** status 200 Successful Response */ number;
export type GetCountOfUnreadApiArg = void;
export type ReadNotificationApiResponse = /** status 200 Successful Response */ boolean;
export type ReadNotificationApiArg = {
	notificationId: number;
};
export type GetFileApiResponse = /** status 200 Successful Response */ void;
export type GetFileApiArg = {
	id: string;
};
export type PostSeedDbApiResponse = /** status 200 Successful Response */ any;
export type PostSeedDbApiArg = void;
export type SeedDefaultProductsApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultProductsApiArg = void;
export type SeedDefaultNumApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultNumApiArg = void;
export type SeedDefaultEmployeeApiResponse = /** status 200 Successful Response */ any;
export type SeedDefaultEmployeeApiArg = void;
export type SeedUsersApiResponse = /** status 200 Successful Response */ any;
export type SeedUsersApiArg = void;
export type RefreshCurrentOperationApiResponse = /** status 200 Successful Response */ any;
export type RefreshCurrentOperationApiArg = void;
export type RefreshCurrentDetailsCountApiResponse = /** status 200 Successful Response */ any;
export type RefreshCurrentDetailsCountApiArg = void;
export type RefreshWastedApiResponse = /** status 200 Successful Response */ any;
export type RefreshWastedApiArg = void;
export type RefreshQrApiResponse = /** status 200 Successful Response */ any;
export type RefreshQrApiArg = void;
export type MaterialCatalogDomain = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type ValidationError = {
	loc: (string | number)[];
	msg: string;
	type: string;
};
export type HttpValidationError = {
	detail?: ValidationError[];
};
export type MaterialCatalogCreate = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type OperationTypeCatalogDomain = {
	name?: string;
	id?: number;
};
export type OperationTypeCatalogCreate = {
	name?: string;
};
export type OperationResponse = {
	/** название операции */
	name?: string;
	tech_map_id: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
	id?: number;
};
export type OperationUpdate = {
	id: number;
	step_id?: number | string;
	wasted?: number | string;
	count_in_gram?: number | string;
	count_in_number?: number | string;
	count_out_gram?: number | string;
	count_out_number?: number | string;
	date?: string | string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	/** название операции */
	name?: string;
	has_link?: boolean;
	is_canceled?: boolean;
};
export type DefaultOperationUpdateInOperation = {
	id: number;
	step_id?: number | string;
	wasted?: number | string;
	count_in_gram?: number | string;
	count_in_number?: number | string;
	count_out_gram?: number | string;
	count_out_number?: number | string;
	date?: string | string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
};
export type BodyUpdateOperations = {
	operations_payload: OperationUpdate[];
	default_operations_payload: DefaultOperationUpdateInOperation[];
};
export type OperationCreate = {
	/** название операции */
	name?: string;
	tech_map_id?: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
};
export type DefaultOperationResponse = {
	/** Название операции */
	name?: string;
	/** Порядковый номер операции */
	order_number: number;
	id?: number;
};
export type LinkedOperationsDirections = 'from' | 'to';
export type LinkedOperationsResponse = {
	id?: number;
	direction: LinkedOperationsDirections;
	/** Куда/откуда произошла связь с тех картой */
	tech_map_number?: number;
	/** ФИО ответственного */
	responsible_employee_fio?: string;
	date?: string | string;
	/** Количество переданных штук */
	count_number?: number;
	/** Количество переданных грамм */
	count_gram?: number;
};
export type BodyUpdateOperationsOperator = {
	operations_payload: OperationUpdate[];
	default_operations_payload: DefaultOperationUpdateInOperation[];
};
export type CancelOperationRequest = {
	operation_id: number;
	closing_employee_fio: string;
};
export type RenewalOperationRequest = {
	operation_id: number;
};
export type ProductOperationTypesCatalog = {
	operation_type_id?: number | string;
	name?: string;
	step_id?: number;
};
export type FileResponse = {
	id: string;
	filename: string;
	size: number;
};
export type ProductCatalogResponse = {
	/** Обозначение детали */
	symbol: string;
	/** Наименование детали */
	name: string;
	/** КС/ИИ */
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	standard_consumption_meters?: string | number;
	standard_consumption_square_meters?: string | number;
	standard_consumption_grams?: string | number;
	standard_consumption_kg?: string | number;
	material_id?: number | MaterialCatalogDomain;
	id?: number;
	material?: MaterialCatalogDomain;
	operation_types?: ProductOperationTypesCatalog[];
	file_id?: string | string;
	file?: FileResponse;
};
export type ResponsePaginationApiListAppModelsProductCatalogContractsProductCatalogResponse = {
	payload: ProductCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type ProductCatalogSortingFields =
	| 'name'
	| 'symbol'
	| 'material_symbol'
	| 'material_gost'
	| 'material_sortament'
	| 'material_gost_na_sortament'
	| 'standard_consumption_meters'
	| 'standard_consumption_kg';
export type SortingDirection = 'asc' | 'desc';
export type FileDomain = {
	/** Path to a picture */
	path: string;
	/** Name of file that was uploaded */
	filename: string;
	/** Size of file in bytes */
	size: number;
	/** Name of bucket with this file */
	bucket_name: string;
	/** Type of file from Enum */
	file_type: string;
	id?: string;
};
export type ProductCatalogDomain = {
	/** Обозначение детали */
	symbol: string;
	/** Наименование детали */
	name: string;
	/** КС/ИИ */
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number | MaterialCatalogDomain;
	id?: number;
	material?: MaterialCatalogDomain;
	operation_types?: ProductOperationTypesCatalog[];
	file_id?: string | string;
	file?: FileDomain;
};
export type ProductCatalogUpdate = {
	symbol?: string;
	name?: string;
	approval_card?: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number;
	id: number;
};
export type OperationTypeCatalogCreateProductRequest = {
	name?: string;
	id?: number;
	step_id?: number;
};
export type BodyUpdateProduct = {
	update_product_request: ProductCatalogUpdate;
	material: MaterialCatalogCreate;
	operations: OperationTypeCatalogCreateProductRequest[];
};
export type ProductCatalogCreate = {
	symbol: string;
	name: string;
	approval_card: string;
	/** Справочное обозначение детали */
	extra_symbol?: string;
	/** Справочное наименование детали */
	extra_name?: string;
	/** Справочное КС/ИИ */
	extra_approval_card?: string;
	/** НР м */
	standard_consumption_meters?: number | string;
	/** НР м^2 */
	standard_consumption_square_meters?: number | string;
	/** НР грамм */
	standard_consumption_grams?: number | string;
	/** НР кг */
	standard_consumption_kg?: number | string;
	material_id?: number;
};
export type BodyCreateProduct = {
	product: ProductCatalogCreate;
	material: MaterialCatalogCreate;
	operations: OperationTypeCatalogCreateProductRequest[];
};
export type ProductCatalogNameListResponse = {
	payload: string[];
};
export type ProductCatalogSymbolListResponse = {
	payload: string[];
};
export type BodyAddFileToDetail = {
	file: Blob;
};
export type TechMapStatus = 'progress' | 'accepted_at_sgd' | 'partially_issued' | 'released' | 'completed';
export type TechMapAdditionalStatusAdmin = 'canceled';
export type UserRoleEnum = 'user' | 'operator' | 'senior_operator' | 'admin' | 'executor' | 'accountant';
export type UserDomain = {
	id?: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	role?: UserRoleEnum;
	created_at?: string;
	updated_at?: string;
	last_login?: string;
	auth_count?: number;
	is_active: boolean;
	email?: string;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
	password_hash?: string;
	password_salt?: string;
	password?: string;
	reset_password_hash?: string;
	reset_password_hash_expired?: string;
};
export type OperationDomain = {
	/** название операции */
	name?: string;
	tech_map_id: number;
	step_id: number;
	user_id?: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
	/** Дата создания операции */
	created_at?: string;
	is_canceled?: boolean;
	has_link?: boolean;
	id?: number;
};
export type CustomMaterialDomain = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type CustomProductDomain = {
	tech_map_id?: number;
	material_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ поле для символа */
	approval_card?: string;
	/** КС/ИИ поле для экстра символа */
	extra_approval_card?: string;
	id?: number;
	material: CustomMaterialDomain;
};
export type SgdOutDomain = {
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	reciever?: string;
	/** ФИО */
	fio?: string;
	id?: number;
};
export type ReleaseDomain = {
	/** Выдано, мм Х мм */
	count?: string;
	/** Выдано, кг */
	count_kg?: string;
	/** Партия (№МК) материала */
	number?: string;
	/** ФИО выдавшего мат. */
	fio?: string;
	id?: number;
	plan_id: number;
};
export type PlanResponse = {
	/** План кол-во дет., шт. */
	count: number;
	NR_meters?: string | number;
	NR_square_meters?: string | number;
	NR_kg?: string | number;
	NR_grams?: string | number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issurance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	id?: number;
	releases?: ReleaseDomain[];
	tech_map_id?: number;
};
export type OvOrDateOfManufactureDecision = 'o_v' | 'date_of_manufacture' | 'empty';
export type StandardConsumptionTypeEnum = 'meters' | 'square_meters' | 'grams' | 'kg' | 'old';
export type CustomNumDomain = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	id?: number;
	tech_map_id?: number;
};
export type TechMapResponse = {
	/** Номер техкарты */
	number?: number;
	/** Группа, к которой относится техкарта (АА. АБ. и т.д.) */
	symbol?: string;
	/** Сумма всего брака */
	wasted?: number;
	status?: TechMapStatus | TechMapAdditionalStatusAdmin;
	/** QR для телефонов, куда зашита ссылка на техкарту */
	qr_code?: Blob;
	/** Дата создания техкарты */
	created_at?: string;
	updated_at?: string;
	id?: number;
	user_id?: number;
	user?: UserDomain;
	storage_at?: string;
	/** Количество деталей, что было передано на СГД */
	accepted_at_sgd_count?: number;
	/** Остаток деталей, что нужно выдать с СГД. Формула: accepted_at_sgd_count - (sum(Выдано из СГД на текущий момент)) */
	remaining_count?: number;
	current_details_count?: number;
	/** Дефолтные операции, что есть у каждой техкарты */
	default_operations?: OperationDomain[];
	product?: CustomProductDomain;
	/** Кастомные операции */
	operations?: OperationDomain[];
	sgd_out?: SgdOutDomain[];
	plan: PlanResponse;
	/** НУМ */
	num?: CustomNumDomain;
	current_operation_id?: number;
	/** Операция, которая прямо сейчас находится в работе */
	current_operation?: OperationDomain;
};
export type ResponsePaginationApiListAppModelsTechMapContractsTechMapResponse = {
	payload: TechMapResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type TechMapsSortingFields =
	| 'number'
	| 'product_catalog.name'
	| 'product_catalog.symbol'
	| 'accepted_at_sgd_count'
	| 'wasted'
	| 'status'
	| 'created_at'
	| 'updated_at'
	| 'storage_at';
export type TechMapStatusForFilter =
	| 'progress'
	| 'accepted_at_sgd'
	| 'partially_issued'
	| 'released'
	| 'canceled'
	| 'completed';
export type TechMapUpdateRequest = {
	/** Номер техкарты */
	number?: number;
	/** Группа, к которой относится техкарта (АА. АБ. и т.д.) */
	symbol?: string;
	/** Сумма всего брака */
	wasted?: number;
	status?: TechMapStatus | TechMapAdditionalStatusAdmin;
	/** QR для телефонов, куда зашита ссылка на техкарту */
	qr_code?: Blob;
	/** Дата создания техкарты */
	created_at?: string;
	updated_at?: string;
	id: number;
};
export type CustomMaterialCreate = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
};
export type CustomProductRequestCreate = {
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ для символа */
	approval_card?: string;
	/** КС/ИИ для экстра символа */
	extra_approval_card?: string;
	material: CustomMaterialCreate;
};
export type OperationRequestCreate = {
	/** название операции */
	name?: string;
	step_id: number;
	wasted?: number;
	count_in_gram?: number;
	count_in_number?: number;
	count_out_gram?: number;
	count_out_number?: number;
	date?: string;
	/** Кастомное поле с фио человека, для Фронта */
	fio?: string;
};
export type CustomNumCreateRequest = {
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
};
export type PlanCreateInTechMapRequest = {
	fio?: string;
	issurance_count?: number;
	list_number?: number;
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, м */
	NR_meters?: number | string;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number | string;
	/** Норма расхода на ед, кг */
	NR_kg?: number | string;
	/** Норма расхода на ед, грамм */
	NR_grams?: number | string;
};
export type TechMapRequestCreate = {
	wasted?: number;
	symbol?: string;
	product: CustomProductRequestCreate;
	operations: OperationRequestCreate[];
	num: CustomNumCreateRequest;
	plan: PlanCreateInTechMapRequest;
	/** Дата создания техкарты */
	created_at?: string;
};
export type TechMapTreeItemResponse = {
	id?: number;
	number?: number;
};
export type LinkedOperationsItem = {
	id: number;
	from_tech_map: TechMapTreeItemResponse;
	to_tech_map: TechMapTreeItemResponse;
};
export type TechMapChangeStatusRequest = {
	tech_map_id: number;
	status: TechMapStatus;
};
export type TechMapLinkedRequestWithNewCreate = {
	parent_tech_map_id: number;
	children_product_catalog_id: number;
	children_operation_type_id?: number;
	children_operation_step_id: number;
	children_default_operation_id?: number;
	plan_count: number;
	fio: string;
	responsible_employee_fio: string;
	from_parent_created_at: string;
	count_gram?: number;
	count_number: number;
};
export type TechMapLinkedRequestWithExistedCreate = {
	parent_tech_map_id: number;
	children_tech_map_id: number;
	responsible_employee_fio: string;
	from_parent_created_at: string;
	count_gram?: number;
	count_number: number;
};
export type StorageLogDomain = {
	user_id: number;
	product_id: number;
	tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
	id?: number;
	created_at?: string;
};
export type StorageLogUpdate = {
	user_id: number;
	product_id: number;
	tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
	id: number;
};
export type StorageLogCreate = {
	user_id: number;
	product_id: number;
	tech_map_id: number;
	description: string;
	wasted?: number;
	count_number?: number;
};
export type UserBase = {
	id: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	role?: UserRoleEnum;
	created_at?: string;
	updated_at?: string;
	last_login?: string;
	auth_count?: number;
	is_active: boolean;
	email?: string;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type UserResponse = {
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	is_active?: boolean;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type UserUpdateRequest = {
	id?: number;
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	email?: string;
	is_active?: boolean;
	role?: UserRoleEnum;
	date_of_dismissal?: string | string;
};
export type UserUpdatePasswordRequest = {
	old_password: string;
	new_password: string;
	new_password_repeat: string;
};
export type Token = {
	access_token: string;
	expires: string;
	refresh_token: string;
	token_type?: string;
	user_role: UserRoleEnum;
};
export type BodyAuth = {
	grant_type?: string;
	username: string;
	password: string;
	scope?: string;
	client_id?: string;
	client_secret?: string;
};
export type RefreshToken = {
	refresh_token: string;
};
export type NumCatalogResponse = {
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
	/** остаток материала */
	remainder: number;
	id: number;
};
export type ResponsePaginationApiListAppModelsNumCatalogContractsNumCatalogResponse = {
	payload: NumCatalogResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type NumCatalogSortingFields =
	| 'num'
	| 'symbol'
	| 'gost'
	| 'sortament'
	| 'gost_na_sortament'
	| 'number_of_melt'
	| 'o_v'
	| 'certificate'
	| 'batch_number'
	| 'divisions'
	| 'valid_until'
	| 'validity'
	| 'comment'
	| 'n_b'
	| 'standard_consumption_type';
export type NumCatalogUpdateRequest = {
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	standard_consumption_type?: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
	/** Обязательное поле для модели на обновление. */
	id: number;
};
export type NumCatalogCreateRequest = {
	/** НУМ из справочника НУМов */
	num: number;
	/** Материал из справчника НУМов */
	symbol: string;
	/** Стандарт из справочника НУМов */
	gost: string;
	/** Сортамент из справочника НУМов */
	sortament: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	date_of_manufacture?: string | string;
	/** Сертификат */
	certificate?: string;
	/** № партии */
	batch_number?: string;
	/** Подразделения */
	divisions?: string;
	/** Годен до */
	valid_until?: string | string;
	/** Годность */
	validity?: string;
	/** Комментарий */
	comment?: string;
	/** HB из справочника НУМов */
	n_b?: string;
	/** Тип единиц норм расхода */
	standard_consumption_type: StandardConsumptionTypeEnum;
	/** количество материала */
	quantity: number;
};
export type SgdOutUpdateRequest = {
	/** Обязательное поля для обновления сущности */
	id: number;
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	reciever?: string;
	/** ФИО */
	fio?: string;
	/** Айди техкарты к которой привязан отчет по СГД */
	tech_map_id: number;
};
export type SgdOutCreateRequest = {
	/** Дата */
	date?: string;
	/** Сертификаты */
	certificate?: string;
	/** Кол-во */
	count?: number;
	/** Получатель */
	reciever?: string;
	/** ФИО */
	fio?: string;
	/** Айди техкарты к которой привязан отчет по СГД */
	tech_map_id: number;
};
export type BodyPutSgdOutOperator = {
	update_payload: SgdOutUpdateRequest[];
	create_payload: SgdOutCreateRequest[];
};
export type PlanDomain = {
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, метр */
	NR_meters?: number;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number;
	/** Норма расхода на ед, кг */
	NR_kg?: number;
	/** Норма расхода на ед, грамм */
	NR_grams?: number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issurance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	id?: number;
	releases?: ReleaseDomain[];
	tech_map_id?: number;
};
export type ReleaseUpdateRequest = {
	/** Выдано, мм Х мм */
	count?: string;
	/** Выдано, кг */
	count_kg?: string;
	/** Партия (№МК) материала */
	number?: string;
	/** ФИО выдавшего мат. */
	fio?: string;
	/** Обязательное поле, т.к. мы должны знать что обновить */
	id?: number;
	/** Foreign key to plan */
	plan_id?: number;
};
export type PlanUpdateOperatorRequest = {
	/** Поле обязательное так как мы должны знать какую модель обновить */
	id: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое решение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	releases?: ReleaseUpdateRequest[];
};
export type CustomMaterialResponse = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id?: number;
};
export type CustomMaterialRequestUpdate = {
	id: number;
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
};
export type CustomProductResponse = {
	tech_map_id?: number;
	material_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ поле для символа */
	approval_card?: string;
	/** КС/ИИ поле для экстра символа */
	extra_approval_card?: string;
	id?: number;
};
export type CustomProductRequestUpdate = {
	id: number;
	tech_map_id?: number;
	material_id?: number;
	name: string;
	extra_name?: string;
	symbol: string;
	extra_symbol?: string;
	/** КС/ИИ поле для символа */
	approval_card?: string;
	/** КС/ИИ поле для экстра символа */
	extra_approval_card?: string;
};
export type CustomNumUpdateRequest = {
	id: number;
	/** НУМ из справочника НУМов */
	num?: number;
	/** Материал из справчника НУМов */
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	/** Номер плавки из справочника НУМов */
	number_of_melt?: string;
	/** о_в из справочника НУМов */
	o_v?: string;
	/** Решение о замене материала */
	replace_decision?: string;
	date_of_manufacture?: string | string;
	/** Поле отвечает за то, какое поле выводить пользователю в поел o_v/date_of_manufacture */
	o_v_or_date_of_manufacture?: OvOrDateOfManufactureDecision;
};
export type EmployeeCatalogResponse = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
	id?: number;
};
export type EmployeeCatalogInitialsResponse = {
	/** ID of employee */
	id: number;
	/** Processed initials of employee */
	initials: string;
};
export type TechMapSymbolCatalogDomain = {
	symbol?: string;
	id?: number;
};
export type MaterialCatalogUpdate = {
	symbol?: string;
	/** Стандарт из справочника НУМов */
	gost?: string;
	/** Сортамент из справочника НУМов */
	sortament?: string;
	/** Стандарт сортамента из справочника нумов */
	gost_na_sortament?: string;
	date_of_manufacture?: string | string;
	id: number;
};
export type OperationTypeCatalogUpdate = {
	name?: string;
	id: number;
};
export type PlanUpdateRequest = {
	/** Поле обязательное так как мы должны знать какую модель обновить */
	id: number;
	/** План кол-во дет., шт. */
	count: number;
	/** Норма расхода на ед, метр */
	NR_meters?: number;
	/** Норма расхода на ед, метр^2 */
	NR_square_meters?: number;
	/** Норма расхода на ед, кг */
	NR_kg?: number;
	/** Норма расхода на ед, грамм */
	NR_grams?: number;
	/** ФИО ПДС */
	fio?: string;
	/** Мат. к выдаче, мм2 */
	issurance_count?: number;
	/** Возврат, м */
	return_count?: string;
	/** Возврат, м^2 */
	return_count_square_meters?: string;
	/** Возврат, кг */
	return_count_kg?: string;
	/** Возврат, грамм */
	return_count_grams?: string;
	/** Партия (№МК) материала */
	return_number?: string;
	/** ФИО получившего мат. */
	return_fio?: string;
	/** Лист № */
	list_number?: number;
	/** Техническое реение */
	tech_decision?: string;
	/** ИТОГО Расход материала мм Х мм */
	summary?: string;
	releases?: ReleaseUpdateRequest[];
};
export type TechMapChangeStatusAdminRequest = {
	tech_map_id: number;
	status: TechMapAdditionalStatusAdmin;
};
export type UserResponseAdmin = {
	id: number;
	first_name: string;
	last_name: string;
	middle_name: string;
	username?: string;
	email?: string;
	role: UserRoleEnum;
	position?: string;
	date_of_dismissal?: string;
	date_of_work_start?: string;
};
export type ResponsePaginationApiListAppModelsUserContractsUserResponseAdmin = {
	payload: UserResponseAdmin[];
	pages?: number;
	page?: number;
	total?: number;
};
export type UserSortingFields = 'first_name' | 'last_name' | 'middle_name' | 'role' | 'email';
export type UserRoleForAdminCreateEnum = 'user' | 'operator' | 'senior_operator' | 'accountant';
export type UserUpdateAdminRequest = {
	id: number;
	first_name?: string;
	last_name?: string;
	middle_name?: string;
	username?: string;
	email?: string;
	role?: UserRoleForAdminCreateEnum;
	password?: string;
	date_of_work_start?: string;
	position?: string;
};
export type UserCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	username: string;
	email: string;
	password: string;
	role: UserRoleForAdminCreateEnum;
	position?: string;
	date_of_work_start?: string;
};
export type UserExecutorCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position?: string;
	date_of_work_start?: string;
};
export type UserFireRequest = {
	user_id: number;
	date_of_dismissal?: string;
};
export type UserRestoreRequest = {
	user_id: number;
};
export type UserActivateRequest = {
	user_id: number;
	password: string;
	email: string;
	role: UserRoleEnum;
};
export type EmployeeCatalogUpdateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
	id: number;
};
export type EmployeeCatalogCreateRequest = {
	first_name: string;
	last_name: string;
	middle_name: string;
	position: string;
};
export type IssueReportTypeEnum = 'stuck_operation' | 'wasted_limit';
export type IssueReportStatusEnum = 'open' | 'progress' | 'closed';
export type IssueCommentResponse = {
	text: string;
	issue_report_id: number;
	user_id: number;
	/** Дата создания комментария */
	created_at?: string;
	id: number;
	user?: UserResponse;
};
export type IssueReportResponse = {
	type: IssueReportTypeEnum;
	status: IssueReportStatusEnum;
	/** Дата создания проблемы */
	open_at: string;
	closed_at?: string;
	stuck_operation_at?: string;
	/** ТехКарта в которой возникла проблема */
	tech_map_id?: number;
	/** Операция, из-за которой появилась проблема.Выбирается пользователем при закрытии проблемы. */
	problem_operation_id?: number;
	/** Пользователь, который взял проблему в исполнение. Назначается автоматически тому юзеру, который перевел задачу в статус 'В работе' */
	executor_user_id?: number;
	/** Пользователь, который назначается исполнителем при переводе задачи из статуса 'В работе' в 'Завершено' */
	responsible_user_id?: number;
	created_at?: string;
	updated_at?: string;
	id: number;
	tech_map?: TechMapResponse;
	problem_operation?: OperationDomain;
	executor_user?: UserResponse;
	responsible_user?: UserResponse;
	comments: IssueCommentResponse[];
};
export type ResponsePaginationApiListAppModelsIssueIssueReportContractsIssueReportResponse = {
	payload: IssueReportResponse[];
	pages?: number;
	page?: number;
	total?: number;
};
export type IssueReportSortingFields =
	| 'open_at'
	| 'status'
	| 'executor_user'
	| 'responsible_user'
	| 'operation_name'
	| 'product_name'
	| 'stuck_operation_at';
export type IssueStuckOperationResponse = {
	/** Операция, которая зависла (больше скольки-то дней не меняется. Назначается автоматически celery, может не являться истинной проблемой, поэтому в главной сущности есть специальной поле для реально проблемной операции. */
	stuck_operation_id: number;
	issue_report_id: number;
	/** Дата, когда эта начались работе по операции */
	date: string;
	/** Дата создания проблемы */
	created_at?: string;
	updated_at?: string;
	id: number;
	stuck_operation: OperationDomain;
	issue_report: IssueReportResponse;
};
export type IssueCommentCreateRequest = {
	text: string;
};
export type IssueReportUpdateStatusToClosedRequest = {
	responsible_user_id: number;
	comment: string;
	problem_operation_id: number;
};
export type NotificationTypeEnum = 'stuck_operation' | 'wasted_limit';
export type NewIssueNotificationResponse = {
	tech_map_id: number;
	tech_map_number: number;
	tech_map_symbol?: string;
	stuck_operation_id: number;
	stuck_operation_name: string;
	stuck_operation_step_id: number;
	issue_report_id: number;
};
export type NotificationResponse = {
	/** Тип нотификации */
	type: NotificationTypeEnum;
	data: NewIssueNotificationResponse;
	/** Дата создания проблемы */
	created_at?: string;
	id: number;
	is_read?: boolean;
};
export const {
	useGetMaterialsQuery,
	useLazyGetMaterialsQuery,
	useCreateMaterialMutation,
	useGetMaterialQuery,
	useLazyGetMaterialQuery,
	useGetOperationTypesQuery,
	useLazyGetOperationTypesQuery,
	useCreateOperationTypeMutation,
	useGetOperationTypeQuery,
	useLazyGetOperationTypeQuery,
	useGetOperationsQuery,
	useLazyGetOperationsQuery,
	useUpdateOperationsMutation,
	useCreateOperationMutation,
	useGetDefaultOperationsQuery,
	useLazyGetDefaultOperationsQuery,
	useGetCurrentOperationsNamesQuery,
	useLazyGetCurrentOperationsNamesQuery,
	useGetOperationQuery,
	useLazyGetOperationQuery,
	useGetOperationLinkHistoryQuery,
	useLazyGetOperationLinkHistoryQuery,
	useUpdateOperationsOperatorMutation,
	useCancelOperationMutation,
	useRenewalOperationMutation,
	useGetProductsQuery,
	useLazyGetProductsQuery,
	useUpdateProductMutation,
	useCreateProductMutation,
	useGetProductQuery,
	useLazyGetProductQuery,
	useDeleteProductMutation,
	useGetProductNamesForFilterQuery,
	useLazyGetProductNamesForFilterQuery,
	useGetProductSymbolForFilterQuery,
	useLazyGetProductSymbolForFilterQuery,
	useAddFileToDetailMutation,
	useDeleteFileForDetailMutation,
	useGetTechMapsQuery,
	useLazyGetTechMapsQuery,
	useUpdateTechMapMutation,
	useCreateTechMapMutation,
	useGetTechMapQuery,
	useLazyGetTechMapQuery,
	useGetTechMapGraphQuery,
	useLazyGetTechMapGraphQuery,
	useGetTechMapByNumberQuery,
	useLazyGetTechMapByNumberQuery,
	useChangeStatusMutation,
	useGetTechMapExcelMutation,
	useCreateLinkedTechMapWithNewMutation,
	useCreateLinkedTechMapWithExistedMutation,
	useGetTechMapListExcelMutation,
	useGetTechMapStatusListQuery,
	useLazyGetTechMapStatusListQuery,
	useGetInProgressTechMapsQuery,
	useLazyGetInProgressTechMapsQuery,
	useGetStorageLogsQuery,
	useLazyGetStorageLogsQuery,
	useUpdateStorageLogMutation,
	useCreateStorageLogMutation,
	useGetStorageLogQuery,
	useLazyGetStorageLogQuery,
	useGetProfileQuery,
	useLazyGetProfileQuery,
	useUserUpdateMutation,
	useUserChangePasswordMutation,
	useAuthMutation,
	useRefreshTokenMutation,
	useLogoutQuery,
	useLazyLogoutQuery,
	useResetPasswordMutation,
	useAuthChangePasswordMutation,
	useGetNumsQuery,
	useLazyGetNumsQuery,
	usePutNumCatalogMutation,
	usePostNumCatalogMutation,
	useGetNumByIdQuery,
	useLazyGetNumByIdQuery,
	useDeleteNumCatalogMutation,
	useGetNumCatalogListExcelMutation,
	usePutSgdOutMutation,
	usePostSgdOutMutation,
	usePutSgdOutOperatorMutation,
	usePatchPlanOperatorMutation,
	usePutCustomMaterialMutation,
	usePutCustomProductMutation,
	useUpdateNumMutation,
	useGetEmployeesQuery,
	useLazyGetEmployeesQuery,
	useGetEmployeeInitialsQuery,
	useLazyGetEmployeeInitialsQuery,
	useGetEmployeeQuery,
	useLazyGetEmployeeQuery,
	useGetTechMapSymbolCatalogQuery,
	useLazyGetTechMapSymbolCatalogQuery,
	useUpdateMaterialMutation,
	useUpdateOperationTypeMutation,
	usePutPlanMutation,
	useCancelTechmapMutation,
	useDeleteTechmapMutation,
	useGetUsersQuery,
	useLazyGetUsersQuery,
	useUpdateUserMutation,
	useCreateUserMutation,
	useGetUserByIdQuery,
	useLazyGetUserByIdQuery,
	useDeleteUserByIdMutation,
	useCreateUserExecutorMutation,
	useFireUserMutation,
	useRestoreUserMutation,
	useActivateUserMutation,
	useGetUserRolesMappingQuery,
	useLazyGetUserRolesMappingQuery,
	useUpdateEmployeeCatalogMutation,
	useCreateEmployeeCatalogMutation,
	useDeleteEmployeeCatalogMutation,
	useGetIssuesQuery,
	useLazyGetIssuesQuery,
	useGetIssueStuckOperationDetailsQuery,
	useLazyGetIssueStuckOperationDetailsQuery,
	useGetIssueWastedLimitDetailsQuery,
	useLazyGetIssueWastedLimitDetailsQuery,
	useCreateCommentForIssueMutation,
	useChangeIssueStatusToProgressMutation,
	useChangeIssueStatusToDoneMutation,
	useGetIssueStatusMappingQuery,
	useLazyGetIssueStatusMappingQuery,
	useGetIssueTypeMappingQuery,
	useLazyGetIssueTypeMappingQuery,
	useGetIssueListExcelMutation,
	useGetUserNotificationsQuery,
	useLazyGetUserNotificationsQuery,
	useGetNotificationTypeMappingQuery,
	useLazyGetNotificationTypeMappingQuery,
	useGetCountOfUnreadQuery,
	useLazyGetCountOfUnreadQuery,
	useReadNotificationMutation,
	useGetFileQuery,
	useLazyGetFileQuery,
	usePostSeedDbMutation,
	useSeedDefaultProductsMutation,
	useSeedDefaultNumMutation,
	useSeedDefaultEmployeeMutation,
	useSeedUsersMutation,
	useRefreshCurrentOperationMutation,
	useRefreshCurrentDetailsCountMutation,
	useRefreshWastedMutation,
	useRefreshQrMutation,
} = injectedRtkApi;
