import { Button, useDisclosure, useToast } from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { ActionColumnProps } from '@/widgets/issue-table';
import { IssueReportStatusEnum, useChangeIssueStatusToProgressMutation } from '@/shared/state/api/swagger';
import { showErrorToast, showSuccessToast, useAppSelector } from '@/shared';
import { actionsMapping } from '@/entities/issue';
import { selectUserRole } from '@/shared/state/slices';
import { SolveIssueFormModal } from '@/features/solve-issue-form';

export const IssueActionColumn: FC<ActionColumnProps> = (props) => {
	const { status, id } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const role = useAppSelector(selectUserRole);
	const isAuthorized = role !== 'user';

	const [changeToProgress, { isLoading }] = useChangeIssueStatusToProgressMutation();
	const toast = useToast();
	const actions: Record<IssueReportStatusEnum, () => void> = {
		open: () => {
			changeToProgress({ issueId: id })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Задача переведена в статус "В работе"' });
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При переводе задачи в статус "В работе" возникла ошибка' });
				});
		},
		progress: onOpen,
		closed: () => {},
	};

	return (
		<>
			<Button
				variant="unstyled"
				color="#3D3D3D"
				fontSize="14px"
				fontFamily="Raleway !important"
				disabled={status === 'closed' || isLoading}
				isLoading={isLoading || !isAuthorized}
				onClick={actions[status]}
			>
				{actionsMapping[status]}
			</Button>
			{isOpen && <SolveIssueFormModal isOpen={isOpen} onClose={onClose} issue={{ ...props }} />}
		</>
	);
};
