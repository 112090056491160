import { LOGIN_PATH, TECH_CARDS_PATH } from '@/shared/core';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedRouteProps } from './role-guard.types';
import { useAppSelector } from '@/shared';
import { selectUserRole } from '@/shared/state/slices';

export const RoleGuard: FC<ProtectedRouteProps> = ({ role, children }) => {
	const userRole = useAppSelector(selectUserRole) ?? '';
	if (!userRole) {
		return <Navigate to={LOGIN_PATH} />;
	}

	const isAllowed = Array.isArray(role) ? role.includes(userRole) : userRole === role;

	if (!isAllowed) {
		return <Navigate to={TECH_CARDS_PATH} />;
	}

	return <>{children}</>;
};
