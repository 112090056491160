import type { FC } from 'react';

import { Spinner } from '@chakra-ui-kraud/react';

import styles from './main-content-loader.module.scss';
import clsx from 'clsx';
import { MainContentLoaderProps } from './loader.types';

export const MainContentLoader: FC<MainContentLoaderProps> = ({ position = 'absolute' }) => {
	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			className={clsx(styles['loader'], styles[`loader-position-${position}`])}
		>
			<Spinner color="#20283980" />
		</div>
	);
};
