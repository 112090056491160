import { IAuthState, ILoginResponse, IProfileResponse } from '@/shared/core';
import { resetAction } from '@/shared/state/actions';
import { authApi } from '@/shared/state/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from '@/shared/state/store';

const initialState: IAuthState = {
	refreshToken: null,
	accessToken: null,
	userProfile: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAccessToken: (state, { payload }: PayloadAction<string | null>) => {
			state.accessToken = payload;
		},
		setRefreshToken: (state, { payload }: PayloadAction<string | null>) => {
			state.refreshToken = payload;
		},
		clearTokens: (state) => {
			state.accessToken = null;
			state.refreshToken = null;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAction, () => {
			return initialState;
		});
		builder.addMatcher(
			authApi.endpoints.login.matchFulfilled,
			(state, { payload }: PayloadAction<ILoginResponse>) => {
				state.accessToken = payload.access_token;
				state.refreshToken = payload.refresh_token;
			},
		);
		builder.addMatcher(
			authApi.endpoints.getUserProfile.matchFulfilled,
			(state, { payload }: PayloadAction<IProfileResponse>) => {
				state.userProfile = { ...payload };
			},
		);
		builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
			return initialState;
		});
	},
});

export const {
	reducer: authReducer,
	actions: { setAccessToken, setRefreshToken },
} = authSlice;

export const selectCurrentUserId = (state: RootStateType) => state.auth.userProfile?.id;

export const selectUserRole = (state: RootStateType) => state.auth.userProfile?.role;
