export const MAIN_PATH = '/';
export const LOGIN_PATH = '/login';
export const TECH_CARDS_PATH = '/tech-cards';
export const TECH_CARD_INFO = '/tech-card/:number';
export const TECH_CARD_OPERATION_PATH = '/tech-card/:techMapId/operation';
export const TECH_CARD_NEW = '/tech-card/new';
export const TECH_CARD_EDIT = '/tech-card/:number/edit';
export const NUM_CATALOG = '/num-catalog';
export const USERS = '/admin/user';
export const USER_CREATE = '/admin/user/new';
export const USER_EDIT = '/admin/user/edit';
export const PRODUCT_CATALOG = '/product-catalog';
export const EMPLOYEES = '/employees';
export const ISSUES_PATH = '/issues';

export const LINK_TO_TECH_CARD_INFO = (cardNumber: number) => TECH_CARD_INFO.replace(':number', cardNumber.toString());

export const LINK_TO_TECH_CARD_EDIT = (cardNumber: number) => TECH_CARD_EDIT.replace(':number', cardNumber.toString());
