import { IssueNotificationProps } from '../model/issue-notification.types';
import { ISSUES_PATH, LINK_TO_TECH_CARD_INFO, Notification, showErrorToast } from '@/shared';
import { useGetNotificationTypeMappingQuery, useReadNotificationMutation } from '@/shared/state/api/swagger';
import { Link, useNavigate } from 'react-router-dom';
import { chakra, useToast } from '@chakra-ui-kraud/react';
import { NotificationSkeleton } from '@/shared/components/notification';

export const IssueNotification = ({
	type,
	is_read,
	created_at,
	isLoading,
	issue_report_id,
	tech_map_number,
	tech_map_symbol,
	stuck_operation_step_id,
	stuck_operation_name,
	id,
}: IssueNotificationProps) => {
	const { data: localeMap = {}, isFetching: isLocaleFetching } = useGetNotificationTypeMappingQuery();

	const toast = useToast();
	const [read] = useReadNotificationMutation();
	const navigate = useNavigate();

	const onClick = () => {
		const handleClick = () => {
			const params = new URLSearchParams();
			params.set('issue_id', `${issue_report_id}`);
			params.set('issue_type', `${type}`);

			navigate(`${ISSUES_PATH}?${params.toString()}`, {});
		};

		if (is_read) {
			handleClick();
			return;
		}

		read({ notificationId: id })
			.unwrap()
			.then(handleClick)
			.catch(() => {
				showErrorToast(toast, {
					description: 'Не удалось прочить уведомление!',
				});
			});
	};

	if (isLoading || isLocaleFetching) {
		return <NotificationSkeleton />;
	}

	return (
		<Notification
			isRead={is_read ?? false}
			title={localeMap[type]}
			datetime={created_at}
			onClick={onClick}
			body={
				<p>
					Проблема возникла в МК{' '}
					<chakra.span color="primary.400" fontSize="14px">
						<Link to={LINK_TO_TECH_CARD_INFO(tech_map_number ?? 0)} target="_blank">
							{tech_map_symbol ? tech_map_symbol + '-' + tech_map_number : tech_map_number}
						</Link>
					</chakra.span>{' '}
					в ходе<br></br> операции {stuck_operation_step_id} — {stuck_operation_name}.
				</p>
			}
		/>
	);
};
