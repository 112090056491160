import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const side = definePartsStyle({
	dialogContainer: {
		justifyContent: 'flex-end',
	},
});

const xl = definePartsStyle({
	closeButton: {
		display: 'block',
		position: 'unset',
	},
	dialog: {
		height: 'calc(100vh - 32px)',
		margin: '16px',
	},
	body: {
		padding: '8px 24px',
		overflowY: 'auto',
	},
	header: {
		fontSize: '18px',
		lineHeight: '32px',
		fontWeight: 'semibold',
		padding: '32px 24px 16px 24px',
		display: 'flex',
		justifyContent: 'space-between',
	},
});

export const modalTheme = defineMultiStyleConfig({
	variants: { side },
	sizes: { xl },
});
