import type { FC } from 'react';
import { Link } from 'react-router-dom';

import type { LayoutProps } from './layout.type';

import { Logo, TECH_CARDS_PATH } from '@/shared';
import { mainBlockId } from '@/shared/constants';
import { Header } from './header';
import { Sidebar } from './sidebar';

import styles from './layout.module.scss';
import clsx from 'clsx';

export const Layout: FC<LayoutProps> = ({ children, withSidebar = true, ...headerProps }) => {
	return (
		<div className={styles['layout']}>
			<div className={clsx(styles['layout-logo'])}>
				<Link to={TECH_CARDS_PATH}>
					<Logo />
				</Link>
			</div>
			<Header {...headerProps} className={styles['block']} />
			{withSidebar && <Sidebar />}
			<main
				className={clsx(
					styles['layout-main'],
					styles['block-main'],
					!withSidebar && styles['layout-main_full'],
				)}
				id={mainBlockId}
			>
				{children}
			</main>
		</div>
	);
};
