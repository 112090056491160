import { FC } from 'react';
import { chakra } from '@chakra-ui-kraud/react';
import { Comment } from '@/shared/components';
import { CommentsProps } from '../model/comments.types';
import { Stack } from '@chakra-ui-kraud/react';
import dayjs from 'dayjs';
import { abbreviateUser } from '@/shared';
import styles from './issue-comments.module.scss';

export const IssueComments: FC<CommentsProps> = ({ comments = [], ...props }) => {
	return (
		<Stack {...props} className={styles['comments']}>
			{comments.map(({ id, text, created_at, user }) => (
				<Comment
					key={id}
					author={
						<chakra.p color="gray.500">
							{user &&
								abbreviateUser(user.first_name ?? '', user.last_name ?? '', user.middle_name ?? '')}
						</chakra.p>
					}
					text={<chakra.p>{text}</chakra.p>}
					datetime={<chakra.p color="gray.500">{dayjs(created_at).format('DD.MM.YYYY')}</chakra.p>}
				/>
			))}
		</Stack>
	);
};
