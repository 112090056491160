import { Flex } from '@chakra-ui-kraud/react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { CustomInput } from '@/shared';
import { FC, useState } from 'react';
import { MadIcon } from 'madsoft-icons';
import { IUser } from '@/shared/core';
import { UserResponseAdmin } from '@/shared/state/api/swagger';

export interface UserFormPasswordPropsController {
	selectedUser?: UserResponseAdmin;
	errors?: FieldErrors<IUser>;
}

export const UserFormPassword: FC<UserFormPasswordPropsController> = ({ errors }): JSX.Element => {
	const { control } = useFormContext();
	const [showPassword, setShowPassword] = useState<string>('password');
	const [showConfirmPassword, setShowConfirmPassword] = useState('password');

	return (
		<Flex direction="column" alignItems="start" gap={3} pb={3} w="100%">
			<Controller
				control={control}
				name="password"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						autoComplete="new-password"
						type={showPassword}
						{...field}
						isInvalid={!!error}
						rightIcon={
							<MadIcon
								module="basic"
								type="outline"
								size="default"
								mode="default"
								name={showPassword === 'password' ? 'eye' : 'eye-slash'}
								onClick={() => setShowPassword((prev) => (prev === 'password' ? 'text' : 'password'))}
							/>
						}
						label="Пароль"
						showTooltip={!!errors?.password?.message}
						tooltipContent={errors?.password?.message}
					/>
				)}
			/>
			<Controller
				control={control}
				name="confirm_password"
				render={({ field, fieldState: { error } }) => (
					<CustomInput
						size="md"
						type={showConfirmPassword}
						{...field}
						isInvalid={!!error}
						rightIcon={
							<MadIcon
								module="basic"
								type="outline"
								size="default"
								mode="default"
								name={showConfirmPassword === 'password' ? 'eye' : 'eye-slash'}
								onClick={() =>
									setShowConfirmPassword((prev) => (prev === 'password' ? 'text' : 'password'))
								}
							/>
						}
						label="Повторите пароль"
						showTooltip={!!errors?.confirm_password?.message}
						tooltipContent={errors?.confirm_password?.message}
					/>
				)}
			/>
		</Flex>
	);
};
