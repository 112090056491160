import { FC, useEffect, useRef, useState } from 'react';

import { CalendarSinglePickerProps } from './calendar-single-picker.types';

import {
	CalendarContainer,
	CalendarDatepicker,
	CalendarDatepickerTrigger,
	CalendarDaysGrid,
	CalendarHeader,
	chakra,
	IconButton,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { parse, format } from 'date-fns';
import { CustomInput } from '../custom-input';

import styles from './calendar-single-picker.module.scss';

export const CalendarSinglePicker: FC<CalendarSinglePickerProps> = ({
	defaultDate,
	label,
	onSelectDate,
	clearable = true,
	onResetDate,
	maxDate,
	...calendarProps
}) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [date, setDate] = useState<Date[]>(defaultDate ? [parse(defaultDate, 'yyyy-MM-dd', new Date())] : []);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (date[0]) {
			onSelectDate(format(date[0], 'yyyy-MM-dd'));
		}
	}, [date, onSelectDate]);

	useEffect(() => {
		if (containerRef.current && isOpen) {
			const onClick = (e: MouseEvent) => {
				for (const element of e.composedPath()) {
					if (element === containerRef.current) {
						return;
					}
				}

				setIsOpen(false);
			};

			window.addEventListener('mousedown', onClick);

			return () => {
				window.removeEventListener('mousedown', onClick);
			};
		}
	}, [isOpen, containerRef]);

	return (
		<chakra.div ref={containerRef}>
			<CalendarDatepicker
				maxDate={maxDate}
				isOpen={isOpen}
				closeOnSelect
				selectedDates={date}
				onSelect={(date) => {
					setDate([date]);
					setIsOpen(false);
				}}
				range={false}
				maxSelectedCount={1}
				{...calendarProps}
			>
				<CalendarDatepickerTrigger>
					<CustomInput
						cursor={'pointer'}
						labelonTop
						label={label}
						isReadOnly
						value={date[0] ? format(date[0], 'dd.MM.yyyy') : ''}
						placeholder={label}
						onClick={() => {
							setIsOpen(true);
						}}
						rightIcon={
							<chakra.div display="flex" mr={defaultDate ? '30px' : ''}>
								{defaultDate && clearable ? (
									<IconButton
										aria-label="test"
										size="sm"
										variant="ghost"
										colorScheme="gray"
										icon={
											<MadIcon
												name="x-circle"
												size="default"
												mode="default"
												type="outline"
												module="basic"
											/>
										}
										onClick={() => {
											setIsOpen(false);
											setDate([]);

											onResetDate && onResetDate();

											return;
										}}
									/>
								) : (
									<></>
								)}
								<IconButton
									aria-label=""
									size="sm"
									variant="ghost"
									colorScheme="gray"
									icon={
										<MadIcon
											name="calendar"
											size="default"
											mode="default"
											type="outline"
											module="basic"
										/>
									}
									onClick={() => setIsOpen(!isOpen)}
								/>
							</chakra.div>
						}
					/>
				</CalendarDatepickerTrigger>
				<chakra.div className={styles['calendar_container']}>
					<CalendarContainer>
						<CalendarHeader />
						<CalendarDaysGrid />
					</CalendarContainer>
				</chakra.div>
			</CalendarDatepicker>
		</chakra.div>
	);
};
