import styles from '@/shared/components/custom-select/custom-select.module.scss';
import { ProductCatalogSymbolListResponse, useLazyGetProductSymbolForFilterQuery } from '@/shared/state/api/swagger';
import { Button, Flex, Text } from '@chakra-ui-kraud/react';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import clsx from 'clsx';
import _debounce from 'lodash/debounce';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FiltersProps } from '../model/filters-types';

export const ProductSymbol = ({ control }: FiltersProps) => {
	const [labelingOption, setLabelingOption] = useState<DefaultOptionType[] | []>([]);
	const [labelingVisibility, setLabelingVisibility] = useState(false);
	const [triggerSymbol, { data: symbolServerData, isSuccess: isSuccessSymbol }] =
		useLazyGetProductSymbolForFilterQuery();

	const getOptionByLabelingServerData = (serverData: ProductCatalogSymbolListResponse['payload']) => {
		return serverData.map((item: string, index: number) => {
			return {
				value: index,
				label: item,
			};
		});
	};

	useEffect(() => {
		if (isSuccessSymbol)
			symbolServerData?.payload?.length &&
				setLabelingOption([...getOptionByLabelingServerData(symbolServerData?.payload)]);
	}, [symbolServerData?.payload]);

	const handleLabelingInput = (e: KeyboardEvent<HTMLDivElement>) => {
		const target = e.target as HTMLInputElement;
		target.value && triggerSymbol({ stringToSearch: target.value.trim() });
	};

	const debouncedLabeling = useCallback(_debounce(handleLabelingInput, 700), []);

	return (
		<Controller
			name="productSymbol"
			control={control}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<Flex flexDirection="column">
						<Flex flexDirection="row" justifyContent="space-between">
							<Text size="md" lineHeight="6" fontWeight="600" marginBottom="8px">
								Обозначение
							</Text>
							{(!!labelingOption?.length || value) && (
								<Button
									size="xs"
									variant="ghost"
									onClick={() => {
										setLabelingOption([]);
										onChange('');
									}}
								>
									Очистить
								</Button>
							)}
						</Flex>
						<Select
							onFocus={() => setLabelingVisibility(true)}
							onBlur={() => {
								if (!value) setLabelingOption([]);
								setLabelingVisibility(false);
							}}
							showSearch
							allowClear={false}
							options={labelingOption}
							value={value}
							onChange={(index) => {
								onChange(labelingOption[Number(index)]?.label);
							}}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onKeyDown={(e) => {
								if (!value) setLabelingOption([]);
								debouncedLabeling(e);
							}}
							optionFilterProp={'children'}
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							className={clsx(
								styles['select'],
								styles['select-large'],
								labelingVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					</Flex>
				);
			}}
		/>
	);
};
