import { api } from './api';
import { IMaterial } from '@/shared/core';

export const MaterialsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMaterials: builder.query<IMaterial[], void>({
			query() {
				return {
					url: '/material_catalog',
					method: 'GET',
					credentials: 'include',
				};
			},
			providesTags: ['Material'],
		}),
		updateCustomMaterial: builder.query<IMaterial & { id: number }, IMaterial>({
			query(body) {
				return {
					url: '/custom_material',
					method: 'PUT',
					body,
					credentials: 'include',
				};
			},
			providesTags: ['Material'],
		}),
	}),
});

export const {
	useGetMaterialsQuery,
	useLazyGetMaterialsQuery,
	useUpdateCustomMaterialQuery,
	useLazyUpdateCustomMaterialQuery,
} = MaterialsApi;
