import { FC } from 'react';
import { ErrorProps } from './error.type';

import gatewayTimeOut from '@/shared/assets/icons/gateway-time-out.png';
import notFound from '@/shared/assets/icons/not-found.png';
import { Button, Text } from '@chakra-ui-kraud/react';

import styles from './error.module.scss';

export const Error: FC<ErrorProps> = ({ description, onConfirm, status, buttonText }) => {
	const checkError = (status: number) => {
		switch (status) {
			case 404:
				return notFound;
			case 504:
				return gatewayTimeOut;
			default:
				return undefined;
		}
	};
	return (
		<div className={styles['error']}>
			<div className={styles['error__content']}>
				{!!checkError(status) && <img src={checkError(status)} alt="error" />}
				<Text fontSize="md" lineHeight="6" fontWeight="400" color="gray.800" textAlign="center" mb="24px">
					{description}
				</Text>
				<Button size="md" variant="solid" colorScheme="primary" width="100%" onClick={onConfirm}>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};
