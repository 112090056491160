import { ListItem, Text, UnorderedList } from '@chakra-ui-kraud/react';

import styles from './user-form.module.scss';

export const UserFormRequirements = ({ password }: { password?: string }): JSX.Element => {
	return (
		<div>
			<Text fontSize={12} size="xs" lineHeight="4" fontWeight="semibold" color="gray.500" marginBottom="10px">
				Пароль должен быть:
			</Text>
			{/* <Flex gap={2}> */}
			<UnorderedList className={styles['list']} gap={'8px'}>
				<ListItem
					className={
						password
							? password.length > 5
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={password ? (password.length > 5 ? 'success.400' : 'danger.400') : 'gray.500'}>
						Длиной не менее 6 символов.
					</Text>
				</ListItem>
				<ListItem
					className={
						password
							? /(?=.*[A-Z])(?=.*[a-z])/.test(password)
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text
						color={
							password
								? /(?=.*[A-Z])(?=.*[a-z])/.test(password)
									? 'success.400'
									: 'danger.400'
								: 'gray.500'
						}
					>
						Содержать символы в разных регистрах (A-z).
					</Text>
				</ListItem>
				<ListItem
					className={
						password
							? /(?=.*[0-9])/.test(password)
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={password ? (/(?=.*[0-9])/.test(password) ? 'success.400' : 'danger.400') : 'gray.500'}>
						Включать цифры (0-9).
					</Text>
				</ListItem>
				<ListItem
					className={
						password
							? /(?=.*[!@#$%^&*,.])/.test(password)
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text
						color={
							password ? (/(?=.*[!@#$%^&*,.])/.test(password) ? 'success.400' : 'danger.400') : 'gray.500'
						}
					>
						Включать знаки препинания и/или специальные символы (!,@;%$^).
					</Text>
				</ListItem>
			</UnorderedList>
			{/* </Flex> */}
		</div>
	);
};
