import { showErrorToast, showSuccessToast, TECH_CARDS_PATH, useShowTechCardButtons, useTechCard } from '@/shared';
import { useGetExcelMutation } from '@/shared/state/api';
import {
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC, useCallback, useState } from 'react';
import { ActionTechCardMenuProps } from './action-tech-card-menu.types';
import { DeleteModal } from '@/shared';
import { useDeleteTechmapMutation } from '@/shared/state/api/swagger';
import { useNavigate } from 'react-router-dom';

export const ActionsTechCardMenu: FC<ActionTechCardMenuProps> = ({
	setLinkVariant,
	setIsLinkSchemaModalOpen,
	setIsResetMode,
}) => {
	const { cardInfo, userRole } = useTechCard();
	const [getExcel] = useGetExcelMutation();
	const [deleteTechmap] = useDeleteTechmapMutation();
	const toast = useToast();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	const {
		// отображение кнопки "Аннулировать"
		hideCancelButton,
		// отображение кнопки "История связей"
		hideShowTechCardGraphLink,
		// отображение кнопки "Создать связь"
		hideTechCardCreateLinkButton,
	} = useShowTechCardButtons({ cardInfo, userRole });

	const getFile = useCallback((id: number | undefined) => {
		if (!id) return;
		getExcel({ id })
			.unwrap()
			.then((res) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `${id}.xlsx`;
				link.click();
				link.remove();
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, []);

	const handleDeleteTechCard = useCallback((techMapNumber: number) => {
		deleteTechmap({ techMapNumber })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `МК № ${techMapNumber} удалена` });
				navigate(TECH_CARDS_PATH);
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	}, []);

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					size="md"
					variant="ghost"
					colorScheme="tertiary"
					icon={
						<MadIcon module="basic" mode="default" size="default" type="outline" name="ellipsis-vertical" />
					}
				/>
				<MenuList p="10px">
					{!hideTechCardCreateLinkButton && (
						<>
							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrows-right-left"
									/>
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setLinkVariant({ isOpen: true, variant: 'edit' })}
								justifyContent="flex-start"
							>
								Связать с существующей МК
							</MenuItem>
							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon module="basic" mode="default" size="default" type="outline" name="plus" />
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setLinkVariant({ isOpen: true, variant: 'create' })}
								justifyContent="flex-start"
							>
								Создать МК и связь с ней
							</MenuItem>
						</>
					)}
					{!hideShowTechCardGraphLink && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="link" />
							}
							size="sm"
							variant="ghost"
							colorScheme="tertiary"
							onClick={() => setIsLinkSchemaModalOpen(true)}
							justifyContent="flex-start"
						>
							История связей
						</MenuItem>
					)}
					<MenuDivider />
					<MenuItem
						as={Button}
						leftIcon={
							<MadIcon
								module="basic"
								mode="default"
								size="default"
								type="outline"
								name="arrow-down-tray"
							/>
						}
						size="sm"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => getFile(cardInfo?.number)}
						justifyContent="flex-start"
					>
						Скачать в Excel
					</MenuItem>

					{!hideCancelButton && (
						<>
							<MenuDivider />

							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="x-circle"
									/>
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setIsResetMode(true)}
								justifyContent="flex-start"
							>
								Аннулировать
							</MenuItem>
						</>
					)}

					{userRole === 'admin' && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="trashcan" />
							}
							size="sm"
							variant="ghost"
							colorScheme="danger"
							onClick={() => setDeleteModalOpen(true)}
							justifyContent="flex-start"
							color="red"
						>
							Удалить карту
						</MenuItem>
					)}
				</MenuList>
			</Menu>
			<Portal>
				<DeleteModal
					title="Удаление МК"
					text="Вы уверены, что хотите удалить МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() => handleDeleteTechCard(Number(cardInfo?.number))}
				/>
			</Portal>
		</>
	);
};
