import { useLocation, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { IssueReportTypeEnum } from '@/shared/state/api/swagger';
import { useDisclosure } from '@chakra-ui-kraud/react';

export const useIssueModalHook = () => {
	const [params, setParams] = useSearchParams();
	const { isOpen, onOpen: handleOpen, onClose: handleClose } = useDisclosure({ isOpen: params.has('issue_id') });

	const onOpen = useCallback((id: number, type: IssueReportTypeEnum) => {
		setParams({ issue_id: `${id}`, issue_type: type });
		handleOpen();
	}, []);

	const location = useLocation();
	const onClose = useCallback(() => {
		const queryParams = new URLSearchParams(location.search);

		queryParams.delete('issue_id');
		queryParams.delete('issue_type');

		setParams(queryParams);
		handleClose();
	}, []);

	return {
		onOpen,
		onClose,
		isOpen,
	};
};
