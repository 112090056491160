import { FC } from 'react';
import { TablesFooterProps } from './tables-footer.types';
import { Pagination } from 'antd';
import { Divider } from '@chakra-ui-kraud/react';
import { IQueryParams, ITechCardListRequest } from '@/shared/core';

import styles from './tables-footer.module.scss';

export const TablesFooter: FC<TablesFooterProps> = ({ pageSize, total, page, setQueryParams, onPageChange }) => {
	const onChange = (page: number, pageSize: number) => {
		setQueryParams?.((prev: ITechCardListRequest | IQueryParams) => ({
			...prev,
			current_page: page,
			page_size: pageSize,
		}));
		onPageChange?.(page, pageSize);
	};

	return (
		<div className={styles.footer}>
			<div className={styles.divider}>
				<Divider />
			</div>
			<div className={styles.pagination}>
				<Pagination
					pageSize={pageSize}
					style={{ fontFamily: 'Raleway', fontWeight: '500' }}
					total={total}
					defaultPageSize={32}
					current={page}
					pageSizeOptions={['10', '32', '50', '100']}
					showSizeChanger
					locale={{ items_per_page: '/ стр' }}
					showTotal={(total) => <>Всего {total}</>}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};
