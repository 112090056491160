import { PreloadedState, configureStore } from '@reduxjs/toolkit';
import loggerMiddleware from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from '../api';
import { rootReducer } from '../reducers';
import { generatedApi } from '@/shared/state/api/swagger';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
};

const devMiddlewares = [loggerMiddleware, api.middleware, generatedApi.middleware];

const productionMiddlewares = [api.middleware, generatedApi.middleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootStateType>) => {
	return configureStore({
		reducer: persistedReducer,
		preloadedState: preloadedState,
		devTools: process.env.NODE_ENV === 'development',
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(process.env.MODE === 'development' ? devMiddlewares : productionMiddlewares),
	});
};

export type StoreType = ReturnType<typeof setupStore>;
export type RootStateType = ReturnType<typeof rootReducer>;
export const store = setupStore();
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
