import { LabeledBlockProps } from './labeled-block.types';
import { chakra } from '@chakra-ui-kraud/react';
import { FC } from 'react';

export const LabeledBlock: FC<LabeledBlockProps> = ({ label, content, ...props }) => {
	return (
		<chakra.div {...props}>
			{label}
			{content}
		</chakra.div>
	);
};
