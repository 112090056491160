import { CalendarRangePicker } from '@/shared';
import { Controller } from 'react-hook-form';
import { FiltersProps } from '../model/filters-types';

export const OpenAtFrom = ({ control }: FiltersProps) => {
	return (
		<Controller
			control={control}
			name="openAtFrom"
			render={({ field: { onChange: onChangeFrom, value: valueFrom } }) => (
				<Controller
					control={control}
					name="openAtTo"
					render={({ field: { onChange: onChangeTo, value: valueTo } }) => (
						<CalendarRangePicker
							onClear={() => {
								onChangeFrom(undefined);
								onChangeTo(undefined);
							}}
							textLabel="Дата обнаружения проблемы"
							setDates={(firstDate, secondDate) => {
								onChangeFrom(firstDate);
								onChangeTo(secondDate);
							}}
							defaultValue={{
								from: valueFrom,
								to: valueTo,
							}}
						/>
					)}
				/>
			)}
		/>
	);
};
