import { ILoginRequest, ILoginResponse, IProfileResponse } from '@/shared/core';
import { api } from './api';

export const authApi = api.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<ILoginResponse, ILoginRequest>({
			query(body) {
				const formData = new FormData();
				formData.append('username', body.username);
				formData.append('password', body.password);

				return {
					url: '/login',
					method: 'POST',
					body: formData,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Auth'],
		}),
		getUserProfile: builder.query<IProfileResponse, void>({
			query(body) {
				return {
					url: '/user/profile',
					method: 'GET',
					body,
					credentials: 'include',
				};
			},
			providesTags: ['Auth'],
		}),
		logout: builder.mutation<void, void>({
			query(body) {
				return {
					url: '/logout',
					method: 'GET',
					body,
					credentials: 'include',
				};
			},
			invalidatesTags: ['Auth'],
		}),
	}),
});

export const { useLoginMutation, useLazyGetUserProfileQuery, useLogoutMutation } = authApi;
