import ReactDOM from 'react-dom/client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { App } from './app';

import './index.scss';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		captureConsoleIntegration({
			levels: ['info', 'warn', 'error', 'assert'],
		}),
		httpClientIntegration({
			failedRequestStatusCodes: [400, 599],
		}),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: true,
		}),
	],
	tracesSampleRate: 1.0,
	release: process.env.REACT_APP_SENTRY_RELEASE,
	tunnel: '/sentry-tunnel',
	sendDefaultPii: true,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
