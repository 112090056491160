import { useMemo } from 'react';
import { IOperation, ITechCard, TechCardStatuses, UserRoles } from '../core';
import { UserDomain } from '@/shared/state/api/swagger';

interface IHideTechCardButtons {
	cardInfo: ITechCard | undefined;
	userRole: UserDomain['role'];
}

export const useShowTechCardButtons = (data: IHideTechCardButtons) => {
	const { cardInfo, userRole } = data;

	// условия отображения кнопки "Аннулировать"
	const hideCancelButton = useMemo(() => {
		// если роль пользователя "Пользователь" или "Оператор" - скрываем кнопку в любой карте
		if (userRole && ['operator', 'user'].includes(userRole)) {
			return true;
		}

		// если роль пользователя - "Админ" или "Старший оператор", и статус текущей карты "аннулирована" - скрываем кнопку, в остальных случаях можно аннулировать карту
		if (cardInfo?.status && userRole && !['operator', 'user'].includes(userRole)) {
			if (cardInfo.status === TechCardStatuses.canceled) {
				return true;
			}
		}

		return false;
	}, [userRole, cardInfo?.status]);

	// условия отображения кнопки "Передать на склад"
	const hideReleasButton = useMemo(() => {
		if (cardInfo?.status) {
			// если статус карты - "Аннулирована", или "Отгружена", или "Принято на СГД", или "Частично выдана", или "Завершена"
			return (
				cardInfo?.status === TechCardStatuses.canceled ||
				cardInfo?.status === TechCardStatuses.released ||
				cardInfo?.status === TechCardStatuses.accepted_at_sgd ||
				cardInfo?.status === TechCardStatuses.partially_issued ||
				cardInfo?.status === TechCardStatuses.completed
			);
		}
		return false;
	}, [cardInfo?.status]);

	// условия отображения кнопки "Редактировать"
	const hideEditMkButton = useMemo(() => {
		// если статус карты "отгружено" и роль пользователя НЕ админ - скрываем кнопку
		// в случае админа - показываем кнопку (только если карта не аннулирована)
		if (
			cardInfo?.status &&
			[TechCardStatuses.released, TechCardStatuses.completed].includes(cardInfo?.status) &&
			userRole !== UserRoles.admin
		) {
			return true;
		}

		return false;
	}, [cardInfo?.status, userRole]);

	// условия для отображения кнопки "История связей"
	const hideShowTechCardGraphLink = useMemo(() => {
		// ищем операции, у которых есть связь с другими мк
		const linkedOperations = cardInfo?.operations
			?.concat(cardInfo.default_operations as IOperation[])
			.filter((operation) => operation.has_link);

		if (linkedOperations?.length) {
			return false;
		}

		return true;
	}, [cardInfo]);

	// условия для отображения кнопки "Создать связь"
	const hideTechCardCreateLinkButton = useMemo(() => {
		// если карта в статусе "Отгружено", "Завершена", "Аннулирована", скрываем кнопку "Создать связь"
		if (
			[TechCardStatuses.canceled, TechCardStatuses.completed, TechCardStatuses.released].includes(
				cardInfo?.status as TechCardStatuses,
			)
		) {
			return true;
		}

		return false;
	}, [cardInfo]);

	return {
		hideCancelButton,
		hideReleasButton,
		hideEditMkButton,
		hideShowTechCardGraphLink,
		hideTechCardCreateLinkButton,
	};
};
