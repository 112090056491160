import { ButtonGroup, Tooltip, IconButton, useDisclosure } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { UserActionsProps } from '../model/actions.types';
import { FC } from 'react';
import { DeleteUserModal } from '@/features/delete-user-form';
import { UserModal } from '@/widgets';
import { RestoreUserModal } from '@/features/restore-user-modal';
import { EmployeeModal } from '@/widgets/employee-modal';

export const UserActionColumns: FC<UserActionsProps> = ({ user }) => {
	const isExecutor = user.role === 'executor';

	const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure();
	const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure();
	const { isOpen: isRestoreOpen, onClose: onRestoreClose, onOpen: onRestoreOpen } = useDisclosure();

	return (
		<>
			<ButtonGroup width="100%">
				<Tooltip
					label={`Редактировать ${isExecutor ? 'Сотрудника' : 'Пользователя'}`}
					hasArrow
					placement="bottom"
				>
					<IconButton
						aria-label=""
						icon={
							<MadIcon module="basic" mode="default" size="default" type="outline" name="pencil-square" />
						}
						size="xs"
						visibility={user.date_of_dismissal !== null ? 'hidden' : 'visible'}
						variant="ghost"
						colorScheme="tertiary"
						onClick={onEditOpen}
					/>
				</Tooltip>
				<Tooltip label={`Уволить ${isExecutor ? 'Сотрудника' : 'Пользователя'}`} hasArrow placement="bottom">
					<IconButton
						aria-label=""
						icon={<MadIcon module="basic" mode="default" size="default" type="outline" name="trashcan" />}
						size="xs"
						variant="ghost"
						colorScheme="danger"
						hidden={user.date_of_dismissal !== null}
						onClick={onDeleteOpen}
					/>
				</Tooltip>
				<Tooltip
					label={`Восстановить ${isExecutor ? 'Сотрудника' : 'Пользователя'}`}
					hasArrow
					placement="bottom"
				>
					<IconButton
						aria-label=""
						icon={<MadIcon module="basic" mode="default" size="default" type="outline" name="history" />}
						size="xs"
						variant="ghost"
						hidden={!user.date_of_dismissal}
						onClick={onRestoreOpen}
					/>
				</Tooltip>
			</ButtonGroup>
			<DeleteUserModal isOpen={isDeleteOpen} onClose={onDeleteClose} user={user} />
			{isExecutor ? (
				<EmployeeModal isOpen={isEditOpen} onClose={onEditClose} variant="edit" selectedEmployee={user} />
			) : (
				<UserModal
					isOpen={isEditOpen}
					onClose={onEditClose}
					variant="edit"
					selectedUser={user}
					title="Редактирование пользователя"
				/>
			)}
			<RestoreUserModal isOpen={isRestoreOpen} onClose={onRestoreClose} user={user} />
		</>
	);
};
